import { useQuery } from "react-query";
import { FetchMedia } from "../../../../services/chats";
import { Col, Image, Row } from "antd";
import mediaStyles from "./styles.module.scss";
import { useContext } from "react";
import { RoomContext } from "../../../../App";

const Media = ({ roomId }: any) => {
  const [Room, SetRoom] = useContext<any>(RoomContext);
  const { data, status, isLoading } = useQuery(["Media", Room], async () =>
    FetchMedia(Room)
  ,
    {
      cacheTime: 0,
      staleTime: 10000,
      enabled: Room !== 0,
    });
  return (
    <div className={mediaStyles.media}>
      <div className={mediaStyles.count}>
        {(data?.json && data?.json?.data?.length) ?? 0} Media
      </div>
      <Image.PreviewGroup>
        <Row gutter={[15, 15]}>
          {data?.json?.data?.map((image: any) => (
            <Col span={8}>
              <Image
                style={{
                  aspectRatio: "1/1",
                  objectFit: "cover",
                  background: "#eee",
                  width: "100% !important",
                }}
                src={image.url}
              />
            </Col>
          ))}
        </Row>
      </Image.PreviewGroup>
    </div>
  );
};

export default Media;
