import { Switch, Tabs, TabsProps } from "antd";
import { useState } from "react";
import userInfoStyle from "../styles.module.scss";
import Media from "../media";
import Files from "../files";
import Audio from "../audio";

const UserContentInfo = ({ user, roomId }: any) => {
  const [muted, setMuted] = useState(!user?.is_muted);
  const [Loading, setLoading] = useState(false);
  const items: TabsProps["items"] = [
    {
      key: "2",
      label: "Media",
      children: <Media roomId={roomId} />,
    },
    {
      key: "3",
      label: "Files",
      children: <Files roomId={roomId} />,
    },
    {
      key: "4",
      label: "Audio",
      children: <Audio roomId={roomId} />,
    },
  ];

  const submitMuteUser = (e: any) => {
    setLoading(true);
    var form_data = new FormData();
    /* TODO - update push notification for user
     * NOTE - can't update another user info */
  };

  return (
    <div className={userInfoStyle.infoCon}>
      <div className={userInfoStyle.general}>
        <div className={userInfoStyle.icon}>
          <img src={user?.icon?.url} alt="" />
        </div>
        <div className={userInfoStyle.name}>{user?.name}</div>
      </div>
      <div className={userInfoStyle.notif}>
        <div className={userInfoStyle.notif_lable}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.5 3H11.5C7.02166 3 4.78249 3 3.39124 4.39124C2 5.78249 2 8.02166 2 12.5C2 16.9783 2 19.2175 3.39124 20.6088C4.78249 22 7.02166 22 11.5 22C15.9783 22 18.2175 22 19.6088 20.6088C21 19.2175 21 16.9783 21 12.5V11.5"
              stroke="#0D0F11"
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M22 5.5C22 7.433 20.433 9 18.5 9C16.567 9 15 7.433 15 5.5C15 3.567 16.567 2 18.5 2C20.433 2 22 3.567 22 5.5Z"
              stroke="url(#paint0_linear_2561_7138)"
              stroke-width="1.5"
            />
            <path
              d="M7 11H11"
              stroke="#0D0F11"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7 16H15"
              stroke="#0D0F11"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2561_7138"
                x1="22.315"
                y1="1.51"
                x2="14.545"
                y2="9.84"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#BF4034" />
                <stop offset="1" stop-color="#9B1724" />
              </linearGradient>
            </defs>
          </svg>
          <div>Push notifications</div>
        </div>
        <Switch
          checked={muted}
          loading={Loading}
          //   onChange={(e) => submitMuteUser(e)}
        />
      </div>
      <div className={userInfoStyle.tabs}>
        <Tabs
          defaultActiveKey={"2"}
          className={userInfoStyle.infoTabs}
          items={items}
        />
      </div>
    </div>
  );
};

export default UserContentInfo;
