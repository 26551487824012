import { LoadingOutlined } from '@ant-design/icons'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import UserActions from '../../actions/UserActions';
import { useAccessToken, useDeviceId, useFcmToken } from '../../redux/ApiStorage';
import { Spin } from 'antd';

const Splash = (props) => {

    const [fcmToken, setFCMToken] = useFcmToken()
    const [deviceId, setDeviceId] = useDeviceId()
    const [accessToken, setAccessToken] = useAccessToken()

    const checkToken = async () => {
        let result = await UserActions.registerDevice(fcmToken, deviceId)
        if (result?.json) {
            return true
        } else {
            return false
        }
    }

    const navigate = useNavigate()

    const goToLogin = () => {
        setFCMToken('')
        setDeviceId('')
        setAccessToken('')
        navigate("/login");
    }

    useEffect(() => {
        setTimeout(async () => {
            if (localStorage.getItem("token") && accessToken && deviceId && fcmToken) {
                if (checkToken()) {
                    navigate("/spaces");
                } else {
                    goToLogin()
                }
            } else {
                goToLogin()
            }
        }, 2000);
    }, []);



    return (
        <div>
            <Spin fullscreen indicator={<div>
                <img src="/logo.png" alt="" />
                <LoadingOutlined style={{ fontSize: 24 }} spin />
            </div>} />
        </div>
    )
}

export default Splash