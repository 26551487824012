import React, { useState } from "react";
import { useEffect } from "react";
import perStyles from "./styles.module.scss";

const PermissionsList = ({ setRoles, Permissions }) => {
  const [Selected, setSelected] = useState(Permissions ? Permissions : []);
  useEffect(() => {
    setRoles(Selected);
  }, [Selected]);

  return (
    <>
      <div className={perStyles.rolesButtons}>
        <button
          onClick={() => setSelected([1])}
          className={`${perStyles.rolesButton} ${
            Selected?.includes(1) ? perStyles.active : ""
          }`}
        >
          <img src="/images/read-only.svg" alt="" />
          Read-Only
        </button>
        <button
          onClick={() => setSelected([3])}
          className={`${perStyles.rolesButton} ${
            Selected.includes(3) ? perStyles.active : ""
          }`}
        >
          <img src="/images/hidden.svg" alt="" />
          Hidden
        </button>
        <button
          onClick={() => {
            if (!Selected.includes(2)) setSelected([2]);
          }}
          className={`${perStyles.rolesButton} ${
            Selected.includes(2) ? perStyles.active : ""
          }`}
        >
          <img src="/images/read-write.svg" alt="" />
          Read-Write
        </button>
        {Selected.includes(2) && (
          <>
            <button
              onClick={() =>
                !Selected.includes(4)
                  ? setSelected([...Selected, 4])
                  : setSelected(
                      Selected.filter(function (item) {
                        return item !== 4;
                      })
                    )
              }
              className={`${perStyles.rolesButton} ${
                Selected.includes(4) ? perStyles.active : ""
              }`}
            >
              <img src="/images/moderate-own.svg" alt="" />
              Moderate own
            </button>
            <button
              onClick={() =>
                !Selected.includes(5)
                  ? setSelected([...Selected, 5])
                  : setSelected(
                      Selected.filter(function (item) {
                        return item !== 5;
                      })
                    )
              }
              className={`${perStyles.rolesButton} ${
                Selected.includes(5) ? perStyles.active : ""
              }`}
            >
              <img src="/images/moderate-other.svg" alt="" />
              Moderate other's
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default PermissionsList;
