import Users from "../../components/users/Profile";

const Profile: React.FC = () => {
  return (
    <>
      <Users />
    </>
  );
};

export default Profile;
