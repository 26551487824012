import axios from "axios";
import ApiStorage from "../redux/ApiStorage";
import { REACT_APP_API_BASE_URL } from "../ConfigVar";



let BASE_URL = REACT_APP_API_BASE_URL;

export const getBaseURL = () => {
  return `${BASE_URL}`;
};

const adapter = axios.create({
  baseURL: getBaseURL(),
  headers: {
    "Content-Type": "application/json",
    osType: "1",
    osVersion: "web",
    deviceModel: "web",
    deviceManufacturer: "web",
    Accept: "application/json",
  },
  timeout: 120000,
});

const adapterUpload = axios.create({
  baseURL: getBaseURL(),
  headers: {
    "Content-Type": "multipart/form-data",
    osType: "1",
    osVersion: "web",
    deviceModel: "web",
    deviceManufacturer: "web",
    Accept: "application/json",
  },
  timeout: 120000,
});





export async function UploadFromUrlPOSTAsync(url, Data) {

  try {
    let token = await ApiStorage.getAccessToken();
    if (token) {
      adapterUpload.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete adapterUpload.defaults.headers.common["Authorization"];
    }

    let result = await adapterUpload.post(url, Data);
    if (result.status === 401 || result.status === -401) {
      // window.location.reload();
      // window.localStorage.clear();
    }
    return result.data;

  } catch (error) {
    console.log("error = ", error);
    // alert("Error From backEnd "+error.response?.data?.message)

    return { success: false, message: error.response?.data?.message };
  }
}


export async function fetchFromUrlPOSTAsync(url, parameters = []) {

  try {
    let dataPost = {
    };
    parameters.forEach((par) => {
      dataPost[par.key] = par.value;
    });

    let token = await ApiStorage.getAccessToken();
    if (token) {
      adapter.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete adapter.defaults.headers.common["Authorization"];
    }
    let result = await adapter.post(url, dataPost);
    if (result.status === 401 || result.status === -401) {
      // window.location.reload();
      // window.localStorage.clear();
    }
    return result.data;

  } catch (error) {
    console.log("error = ", error);
    if(error?.response?.data?.code  == "-401"){
      // window.localStorage.clear();
    }
    return { success: false, message: error.response?.data?.message };
  }
}


export async function fetchFromUrlPUTAsync(url, parameters = []) {

  try {
    let dataPost = {
    };
    parameters.forEach((par) => {
      dataPost[par.key] = par.value;
    });
    let token = await ApiStorage.getAccessToken();

    if (token) {
      adapter.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete adapter.defaults.headers.common["Authorization"];
    }
    let result = await adapter.put(url, dataPost);
    if (result.status === 401 || result.status === -401) {
      // window.location.reload();
      // window.localStorage.clear();
    }
    return result.data;

  } catch (error) {
    console.log("error = ", error);
    if(error?.response?.data?.code  == "-401"){
      // window.localStorage.clear();
    }
    return { success: false, message: error.response?.data?.message};
  }
}

export async function fetchFromUrlGETAsync(url, parameters = []) {

  try {
    let dataPost = {
    };

    parameters.forEach((par) => {
      dataPost[par.key] = par.value;
    });
    let token = await ApiStorage.getAccessToken();
    if (token) {
      adapter.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    } else {
      delete adapter.defaults.headers.common["Authorization"];
    }
    let result = await adapter.get(url, {
      params: dataPost,
    });
    if (result.status === 401 || result.status === -401) {
      // window.location.reload();
    }

    return result.data;
  } catch (error) {
    console.log("error = ", error);
    if(error?.response?.data?.code  == "-401"){
    }
    return { success: false, message: error.response?.data?.message };
  }
}


export async function fetchFromUrlDELETEAsync(url, parameters = []) {
  try {
    let dataPost = {
    };

    parameters.forEach((par) => {
      dataPost[par.key] = par.value;
    });
    let token = await ApiStorage.getAccessToken();
    if (token) {
      adapter.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete adapter.defaults.headers.common["Authorization"];
    }
    let result = await adapter.delete(url, {
      params: dataPost,
    });
    return result.data;
  } catch (error) {
    console.log("error = ", error);
    // alert("Error From backEnd "+error.response?.data?.message)

    return { success: false, message: error.response?.data?.message };
  }
}


export async function FetchFromUrlPUTAsyncUrlencoded(url, parameters = []) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let token = await ApiStorage.getAccessToken();
    if (token) {
      myHeaders.append("Authorization", `Bearer ${token}`);
    }

    const urlencoded = new URLSearchParams();
    parameters.forEach((par) => {
      urlencoded.append(par.key, par.value);
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };

    const response = await fetch(`${BASE_URL}${url}`, requestOptions);
    const result = await response.json();

    if (response.status === 401 || response.status === -401) {
    }

    return result;
  } catch (error) {
    console.log("error = ", error);
    if (error?.response?.data?.code === "-401") {
    }
    return { success: false, message: error.response?.data?.message || error.message };
  }
}



export default adapter;
