
import ApiStorage from '../redux/ApiStorage';
import { fetchFromUrlPOSTAsync, FetchFromUrlPUTAsyncUrlencoded } from '../utils/UrlHelper';

const URL_LOGIN = "auth/login";
const URL_REGISTER_TOKEN = "auth/device-tokens";


const fs = {
    login: async (username, password ) => {
        const urlParams = [
            { key: "username", value: username },
            { key: "password", value: password },
        ];
        const result = await fetchFromUrlPOSTAsync(URL_LOGIN, urlParams);
        if (result?.data?.json?.token) {

            localStorage.setItem("token", result?.data.json.token);

            localStorage.setItem("user", JSON.stringify(result?.data?.json));
    
            await ApiStorage.setAccessToken(result?.data.json.token)

        }
        return result;
    },
    registerDevice: async (FCMToken, deviceId ) => {
        const urlParams = [
            { key: "device_tokens[0][type]", value: "2" },
            { key: "device_tokens[0][token]", value: FCMToken },
            { key: "device", value: deviceId },

        ];
        const result = await FetchFromUrlPUTAsyncUrlencoded(URL_REGISTER_TOKEN, urlParams);
      
        return result;
    },

};

//this is local function on Api 
const loc = {
   

};



const UserActions = Object.assign(fs, loc);

export default UserActions;