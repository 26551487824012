  import { initializeApp } from "firebase/app";
  import { getDatabase } from "firebase/database";
  import { getMessaging, getToken } from "firebase/messaging";
import ApiStorage from "./redux/ApiStorage";
import { REACT_APP_FIREBASE_API_KEY, REACT_APP_FIREBASE_APP_ID, REACT_APP_FIREBASE_AUTH_DOMAIN, REACT_APP_FIREBASE_DATABASE_URL, REACT_APP_FIREBASE_MEASUREMENT_ID, REACT_APP_FIREBASE_MESSAGING_SENDER_ID, REACT_APP_FIREBASE_PROJECT_ID, REACT_APP_FIREBASE_STORAGE_BUCKET, REACT_APP_FIREBASE_VAPID_KEY } from "./ConfigVar";

  const firebaseConfig = {
    apiKey: REACT_APP_FIREBASE_API_KEY,
    authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
    projectId: REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: REACT_APP_FIREBASE_APP_ID,
    measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID
  };

  const app = initializeApp(firebaseConfig);

  const database = getDatabase(app);
  const messaging = getMessaging(app);

  export async function getFCMTokenAndDeviceId() {

    try {
      let token = await ApiStorage.getFCMToken()
      if(token){
        console.log("currentFCMToken",token);
        return token;
      }else{
        const currentToken = await getToken(messaging, { vapidKey: REACT_APP_FIREBASE_VAPID_KEY });
        if (currentToken) {
          console.log("currentFCMToken",currentToken);
          ApiStorage.setFCMToken(currentToken);
          return currentToken;
        } else {
          console.log('No registration token available. Request permission to generate one.');
          return null;
        }
      }


     
    } catch (err) {
      console.log('An error occurred while retrieving token. ', err);
      return null;
    }
  }

  export { app, database };