import React, { useEffect, useState } from "react";
import usersStyles from "../../styles/pages/profile.module.scss";

import { Avatar, Button, Dropdown, MenuProps } from "antd";

const UserCompList = ({ data, groups, setUser, user, setUserEdit }: any) => {
  const [UserLoc, setUserLoc] = useState<any>({});
  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUserLoc(t);
  }, [window.localStorage.getItem("user")]);
  const items = (newUser: any) =>
    UserLoc?.role !== "owner"
      ? [
          {
            key: "2",
            label: (
              <div
                onClick={() => {
                  setUser({});
                  setTimeout(() => {
                    setUser(newUser);
                  }, 300);
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingBlock: 3,
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginInlineEnd: 10 }}
                >
                  <path
                    d="M21 17.5C21 19.433 19.433 21 17.5 21C15.567 21 14 19.433 14 17.5C14 15.567 15.567 14 17.5 14C19.433 14 21 15.567 21 17.5Z"
                    stroke="#48484A"
                    stroke-width="1.5"
                  />
                  <path
                    d="M17.5 14H6.5C4.567 14 3 15.567 3 17.5C3 19.433 4.567 21 6.5 21H17.5C19.433 21 21 19.433 21 17.5C21 15.567 19.433 14 17.5 14Z"
                    stroke="#48484A"
                    stroke-width="1.5"
                  />
                  <path
                    d="M3 6.5C3 8.433 4.567 10 6.5 10C8.433 10 10 8.433 10 6.5C10 4.567 8.433 3 6.5 3C4.567 3 3 4.567 3 6.5Z"
                    stroke="#48484A"
                    stroke-width="1.5"
                  />
                  <path
                    d="M6.5 3H17.5C19.433 3 21 4.567 21 6.5C21 8.433 19.433 10 17.5 10H6.5C4.567 10 3 8.433 3 6.5C3 4.567 4.567 3 6.5 3Z"
                    stroke="#48484A"
                    stroke-width="1.5"
                  />
                </svg>
                Edit user permissions
              </div>
            ),
          },
        ]
      : [
          {
            key: "1",
            label: (
              <div
                onClick={() => {
                  setUserEdit(newUser);
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingBlock: 3,
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginInlineEnd: 10 }}
                >
                  <path
                    d="M5.18007 15.2964C3.92249 16.0335 0.625212 17.5386 2.63348 19.422C3.6145 20.342 4.7071 21 6.08077 21H13.9192C15.2929 21 16.3855 20.342 17.3665 19.422C19.3748 17.5386 16.0775 16.0335 14.8199 15.2964C11.8709 13.5679 8.12906 13.5679 5.18007 15.2964Z"
                    stroke="#48484A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14 7C14 9.20914 12.2091 11 10 11C7.79086 11 6 9.20914 6 7C6 4.79086 7.79086 3 10 3C12.2091 3 14 4.79086 14 7Z"
                    stroke="#48484A"
                    stroke-width="1.5"
                  />
                  <path
                    d="M19.6221 4.56564C19.8457 4.32342 19.9574 4.20232 20.0762 4.13168C20.3628 3.96123 20.7157 3.95593 21.0071 4.1177C21.1279 4.18474 21.2431 4.30244 21.4735 4.53783C21.7039 4.77322 21.8192 4.89092 21.8848 5.01428C22.0431 5.31194 22.038 5.67244 21.8711 5.96521C21.8019 6.08655 21.6834 6.20073 21.4463 6.4291L18.6252 9.14629C18.1759 9.57906 17.9512 9.79545 17.6704 9.90512C17.3896 10.0148 17.081 10.0067 16.4636 9.99057L16.3796 9.98838C16.1917 9.98346 16.0977 9.98101 16.0431 9.91901C15.9885 9.85702 15.9959 9.7613 16.0108 9.56985L16.0189 9.4659C16.0609 8.92706 16.0819 8.65765 16.1871 8.41547C16.2923 8.17328 16.4738 7.97664 16.8368 7.58335L19.6221 4.56564Z"
                    stroke="#48484A"
                    stroke-width="1.5"
                    stroke-linejoin="round"
                  />
                </svg>
                Edit user profile
              </div>
            ),
          },
          {
            key: "2",
            label: (
              <div
                onClick={() => {
                  setUser({});
                  setTimeout(() => {
                    setUser(newUser);
                  }, 300);
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingBlock: 3,
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginInlineEnd: 10 }}
                >
                  <path
                    d="M21 17.5C21 19.433 19.433 21 17.5 21C15.567 21 14 19.433 14 17.5C14 15.567 15.567 14 17.5 14C19.433 14 21 15.567 21 17.5Z"
                    stroke="#48484A"
                    stroke-width="1.5"
                  />
                  <path
                    d="M17.5 14H6.5C4.567 14 3 15.567 3 17.5C3 19.433 4.567 21 6.5 21H17.5C19.433 21 21 19.433 21 17.5C21 15.567 19.433 14 17.5 14Z"
                    stroke="#48484A"
                    stroke-width="1.5"
                  />
                  <path
                    d="M3 6.5C3 8.433 4.567 10 6.5 10C8.433 10 10 8.433 10 6.5C10 4.567 8.433 3 6.5 3C4.567 3 3 4.567 3 6.5Z"
                    stroke="#48484A"
                    stroke-width="1.5"
                  />
                  <path
                    d="M6.5 3H17.5C19.433 3 21 4.567 21 6.5C21 8.433 19.433 10 17.5 10H6.5C4.567 10 3 8.433 3 6.5C3 4.567 4.567 3 6.5 3Z"
                    stroke="#48484A"
                    stroke-width="1.5"
                  />
                </svg>
                Edit user permissions
              </div>
            ),
          },
        ];

  return (
    <div className={usersStyles.chatRooms}>
      {data &&
        data
          ?.filter((user: any) => user?.contact?.id !== UserLoc?.id)
          ?.map((user: any, index: number) => (
            <div
              className={user === user.contact?.id ? usersStyles.active : ""}
            >
              {user?.contact?.profile_image?.url ? (
                <img src={user?.contact?.profile_image?.url} alt="" />
              ) : (
                <Avatar size={44}>
                  {user?.contact?.username?.slice(0, 2)}
                </Avatar>
              )}

              <div className={usersStyles.infoCon}>
                <div className={usersStyles.titleCon}>
                  <h4>{user?.contact?.full_name}</h4>
                </div>
              </div>
              <Dropdown menu={{ items: items(user) }} placement="bottomLeft">
                <Button className={usersStyles.edit__user__btn}>
                  Edit
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.6785 2.985L11.8465 1.81686C12.4917 1.17172 13.5377 1.17172 14.1829 1.81686C14.828 2.46201 14.828 3.50799 14.1829 4.15313L13.0147 5.32126M10.6785 2.985L3.8169 9.84659C2.94582 10.7177 2.51026 11.1532 2.21368 11.6839C1.9171 12.2147 1.61871 13.4679 1.33337 14.6663C2.53178 14.381 3.78503 14.0826 4.31578 13.786C4.84653 13.4894 5.28207 13.0539 6.15317 12.1828L13.0147 5.32126M10.6785 2.985L13.0147 5.32126"
                      stroke="#1C1C1E"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Button>
              </Dropdown>
            </div>
          ))}
    </div>
  );
};

export default UserCompList;
