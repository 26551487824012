import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Upload,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { AddFile } from "../../../services/contacts";
import { EditSpace, PostSpace } from "../../../services/spaces";
import { showOldLog } from "../../../ConfigVar";

const { Option } = Select;

const AddSpace = ({ Groups, onFinish, space, edit, selectedGroups }: any) => {
  const [imageUrl, setImageUrl] = useState();
  const [ImageData, setImageData] = useState<any>();
  const [Loading, setLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);

  useEffect(() => {
    if (space) {
      setImageUrl(space.icon.url);
    }
  }, [space]);
  // useEffect(() => {
  //   if (Groups) {
  //     console.log(
  //       "Groups",
  //       Groups.filter(
  //         (x: any) => x.space_id !== null && x.space_id === space.id
  //       ).map((newSpace: any) => newSpace.id)
  //     );
  //   }
  // }, [Groups]);

  if (showOldLog) {
    console.log("spacespace", space);
  }

  const UploadButton = () => (
    <>
      <span className="uploadtext">upload the space image</span>
      <Button
        className="uploadbtn"
        icon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.09502 10C3.03241 10.457 3 10.9245 3 11.4C3 16.7019 7.02944 21 12 21C16.9706 21 21 16.7019 21 11.4C21 10.9245 20.9676 10.457 20.905 10"
              stroke="url(#paint0_linear_2735_8592)"
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M12.0001 3V13M12.0001 3C11.2999 3 9.99159 4.9943 9.50006 5.5M12.0001 3C12.7003 3 14.0086 4.9943 14.5001 5.5"
              stroke="url(#paint1_linear_2735_8592)"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2735_8592"
                x1="21.81"
                y1="9.23"
                x2="11.2783"
                y2="27.7058"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#BF4034" />
                <stop offset="1" stop-color="#9B1724" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_2735_8592"
                x1="14.7251"
                y1="2.3"
                x2="5.45876"
                y2="7.26707"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#BF4034" />
                <stop offset="1" stop-color="#9B1724" />
              </linearGradient>
            </defs>
          </svg>
        }
        type="text"
      >
        Upload picture
      </Button>
    </>
  );

  const getBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const handleChange = (info: any) => {
    setImgLoading(true);
    var form_data = new FormData();
    form_data.append("file", info.fileList[0].originFileObj);
    form_data.append("type", "1");
    AddFile(form_data)
      .then((res) => {
        setImageData(res?.json?.hash);
        getBase64(info.fileList[0].originFileObj, (url: any) => {
          setImageUrl(url);
        });
        setImgLoading(false);
        return;
      })
      .catch(() => {
        setImgLoading(false);
      });
  };
  const submitAddContact = (e: any) => {
    var form_data = new FormData();
    form_data.append("name", e.name);
    if (ImageData) form_data.append("icon", ImageData);
    e.groupsList.map((groupsId: any) => {
      if (showOldLog) {
        console.log("groupsId",groupsId)

      }
      if(typeof groupsId === "object"){
        form_data.append("group_ids[]", groupsId?.value);
      }else{
        form_data.append("group_ids[]", groupsId);

      }
    });
    // form_data.append("group_ids[]", e.groupsList);
    setLoading(true);
    if (!space) {
      PostSpace(form_data)
        .then((res: any) => {
          if (showOldLog) {
            console.log(res?.json.id);
          }
          notification.open({
            type: "success",
            message: "Space " + e.name + " added successfully",
          });
          onFinish();
          Modal.destroyAll();
        })
        .catch(() => {
          notification.open({
            type: "error",
            message: "There is a problem with the server",
          });
          setLoading(false);
        });
    } else {
      form_data.append("id", space.id);
      EditSpace(form_data)
        .then((res: any) => {
          if (showOldLog) {
            console.log(res?.json.id);
          }
          notification.open({
            type: "success",
            message: "Space " + e.name + " Edit successfully",
          });
          onFinish();
          Modal.destroyAll();
        })
        .catch(() => {
          notification.open({
            type: "error",
            message: "There is a problem with the server",
          });
          setLoading(false);
        });
    }
  };

  return (
    <div className="spaceCreateForm">
      <Form
        name="spaceCreateForm"
        layout="vertical"
        initialValues={{
          name: space?.name ?? "",
        }}
        autoComplete="off"
        onFinish={(e) => submitAddContact(e)}
      >
        <Row justify={"center"} gutter={[15, 15]}>
          <Col span={24}>
            <Form.Item name="image">
              <Upload
                name="avatar"
                listType="picture-card"
                maxCount={1}
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={() => false}
                accept="image/*"
                onChange={handleChange}
              >
                <div className="uploadbtncon">
                  {imgLoading ? (
                    <Spin
                      className="placeHolderImg"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "1px lightgray solid",
                      }}
                    ></Spin>
                  ) : imageUrl ? (
                    <img className="placeHolderImg" src={imageUrl} alt="" />
                  ) : (
                    <img
                      className="placeHolderImg"
                      src="/images/placeholderImg.png"
                      alt=""
                    />
                  )}
                  <UploadButton />
                </div>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Space Name"
              name="name"
              rules={[
                { required: true, message: "Please input your Space name!" },
              ]}
              initialValue={space?.name}
            >
              <Input placeholder="Space Name" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="List of groups"
              name="groupsList"
              rules={[
                {
                  required: true,
                  message: "Please input your List of groups!",
                },
              ]}
              initialValue={
                selectedGroups
                  ? selectedGroups.map((newSpace: any) => {
                      return {
                        value: newSpace?.id?.toString(),
                        label: newSpace?.name,
                      };
                    })
                  : []
              }
            >
              <Select
                mode="multiple"
                showSearch
                style={{ width: "100%" }}
                placeholder="Choose from the list..."
                optionFilterProp="children"
                filterOption={(input, option) =>
                  ((option?.children ?? "") as string)
                    ?.toLowerCase()
                    ?.includes(input.toLowerCase())
                }
                // bordered={false}
                // defaultValue={Groups.filter(
                //   (x: any) => x.space_id !== null && x.space_id === space.id
                // ).map((newSpace: any) => newSpace.name)}
              >
                {Groups?.map((user: any) => (
                  <Option key={user?.id.toString()}>{user?.name}</Option>
                ))}
              </Select>
              {/* <Checkbox.Group>
                  <div className="chatUsers">
                    {Groups.map((user: any) => (
                      <div className={"userCard"}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div className="cardImgCon">
                            <div className="imgPlaceHolder">
                              <img src={user?.icon?.url} alt="" />
                            </div>
                          </div>
                          <div className="cardText">
                            <div className="cardTitle">{user?.name}</div>
                          </div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Checkbox value={user?.id} />
                        </div>
                      </div>
                    ))}
                  </div>
                </Checkbox.Group> */}
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item>
              <div style={{ textAlign: "end" }}>
                <Button
                  loading={Loading}
                  className="createbtn"
                  type="primary"
                  htmlType="submit"
                >
                  {edit ? "Submit" : "Create new space"}
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddSpace;
