import React from 'react'
import { Result, Button, Space, Typography } from 'antd'
import { MobileOutlined, AppleOutlined, AndroidOutlined } from '@ant-design/icons'
import authStyles from "../../styles/pages/auth.module.scss";

const { Title, Paragraph } = Typography

const MobileAppPage = () => {
  return (

    <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Result
        icon={<MobileOutlined style={{ fontSize: 72, color: '#1890ff' }} />}
        title="Download Our Mobile App"
        subTitle="For the best mobile experience, please download our dedicated mobile application"
        extra={
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
            <Space direction="vertical" size="large">
              <Button type="primary" icon={<AppleOutlined />} size="large">
                Download for iOS
              </Button>
              <Button type="primary" icon={<AndroidOutlined />} size="large">
                Download for Android
              </Button>
            </Space>
            <Typography style={{ marginTop: '20px' }}>
              <Title level={5}>Why use our mobile app?</Title>
              <Paragraph>
                • Better performance and faster loading times<br />
                • Push notifications for messages and calls<br />
                • Enhanced mobile-specific features<br />
                • Optimized battery usage<br />
                • Secure local storage
              </Paragraph>
            </Typography>
          </div>
        }
      />
    </div>
  )
}

export default MobileAppPage