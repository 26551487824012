import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";

import spacesStyles from "../../styles/pages/spaces.module.scss";
import SpacesCon from "../../components/spaces/spacesCon";
import Messages from "../../components/spaces/messages";
import { useQuery } from "react-query";
import { FetchGroups } from "../../services/groups";
import UsersCon from "../../components/room/UsersCon";
import { LastContext, MessageContext, NotificationContext } from "../../App";
import { FetchSpaces } from "../../services/spaces";
import { MakeSeen } from "../../services/chats";
import moment from "moment";
import { showOldLog } from "../../ConfigVar";

const Spaces = () => {
  const [SearchText, setSearch] = useState<any>("");
  const [space, setSpace] = useState<any>(0);
  const [GroupList, setGroupList] = useState<any>([]);
  const [RoomId, setRoomId] = useState<any>(0);
  const [SubRoomId, setSubRoomId] = useState(0);
  const [GroupsLoading, setGroupsLoading] = useState(true);
  const [user, setUser] = useState<any>({});
  const [activeKey, setActiveKey] = useState<any>("1");
  const [IsExternal, setIsExternal] = useState<any>(false);
  const [data, setData] = useState<any>();

  const [currentPage, setCurrentPage] = useState(1);

 
  const [NotificationRoom, SetNotificationRoom] =
    useContext<any>(NotificationContext);
  const [Last, setLast] = useContext<any>(LastContext);
  const { data:resData , status, isLoading } = useQuery(
    ["groupsData", space, SearchText, currentPage], // Add currentPage to dependencies
    async () => FetchGroups(currentPage, space, SearchText, 5),
    {
      cacheTime: 0,
      staleTime: 10000,
      onSuccess: (res) => {
        // Additional success handling if needed
        console.log("API call completed successfully");
        console.log("res",res);
        setData(res)
        setGroupList([...GroupList,...res?.json]);
        if(res?.meta?.current_page < res?.meta?.last_page){
          setCurrentPage(res?.meta?.current_page + 1);
        }
      },
    },
   
  );
  
  // const { data, status, isLoading } = useQuery(
  //   ["groupsData", space, SearchText],
  //   async () => FetchGroups(1, space, SearchText,15),
  //   {
  //     cacheTime: 0,
  //     staleTime: 10000,
  //   }
  // );

  const refetch = () => {};
  useEffect(() => {
    if (Object.keys(Last).length > 0 && Last.group) {
      let newGroupList = GroupList;
      let seletedIndex = newGroupList.findIndex(
        (item: any) =>
          item?.id === Last?.roomId || item?.staff_chat?.id === Last?.roomId
      );
      if (activeKey === "1") {
        newGroupList[seletedIndex] = {
          ...newGroupList[seletedIndex],
          last_message: {
            ...newGroupList[seletedIndex]?.last_message,
            content: "You: " + Last.messsage,
            created_at: new Date().toISOString(),
          },
        };
      } else {
        newGroupList[seletedIndex] = {
          ...newGroupList[seletedIndex],
          last_message: {
            ...newGroupList[seletedIndex]?.last_message,
            content: "You: " + Last.messsage,
            created_at: new Date().toISOString(),
          },
        };
      }

      newGroupList.unshift(newGroupList.splice(seletedIndex, 1)[0]);
      if (showOldLog) {
        console.log("newGroupList", newGroupList);
      }
      setGroupList(newGroupList);
    }
  }, [Last]);
  // useEffect(() => {
  //   setGroupList(data?.json);
  // }, [data]);
  useEffect(() => {
    if (RoomId !== 0) {
      if (showOldLog) {
        console.log("RoomIdRoomId", RoomId);
      }
      let newGroupList = GroupList;
      let seletedIndex = newGroupList.findIndex(
        (item: any) => item?.id === RoomId || item?.staff_chat?.id === RoomId
      );
      let totalNotification = parseInt(
        localStorage.getItem("groupsNotification") as string
      );
      if (activeKey === "1") {
        if (
          newGroupList[seletedIndex]?.staff_chat?.unread_messages_count &&
          newGroupList[seletedIndex]?.staff_chat?.unread_messages_count > 0
        )
          totalNotification =
            totalNotification -
            newGroupList[seletedIndex]?.staff_chat?.unread_messages_count;
        newGroupList[seletedIndex] = {
          ...newGroupList[seletedIndex],
          staff_chat: {
            ...newGroupList[seletedIndex]?.staff_chat,
            unread_messages_count: 0,
          },
        };
      } else {
        if (
          newGroupList[seletedIndex]?.unread_messages_count &&
          newGroupList[seletedIndex]?.unread_messages_count > 0
        )
          totalNotification =
            totalNotification -
            newGroupList[seletedIndex]?.unread_messages_count;
        newGroupList[seletedIndex] = {
          ...newGroupList[seletedIndex],
          unread_messages_count: 0,
        };
      }
      if (totalNotification)
        localStorage.setItem(
          "groupsNotification",
          totalNotification.toString()
        );
      setGroupList([...newGroupList]);
    }
  }, [RoomId]);

  if (showOldLog) {
    console.log("newnewdata", data);
  }
  const [newMessage, SetNewMessage] = useContext<any>(MessageContext);

  const groups = useQuery(
    ["groups", 0, ""],
    async () => FetchGroups(1, 0, "",15),
    {
      cacheTime: 0,
      staleTime: 10000,
    }
  );
  useEffect(() => {
    if (NotificationRoom?.chat_id) {
      let ClientRoom = data?.json.find(
        (x: any) => x?.id === NotificationRoom?.chat_id
      );
      let StaffRoom = data?.json.find(
        (x: any) => x?.staff_chat?.id === NotificationRoom?.chat_id
      );
      if (ClientRoom) {
        setActiveKey("2");
        setUser(ClientRoom);
        setRoomId(ClientRoom.id);
        if (ClientRoom?.staff_chat !== undefined)
          setSubRoomId(ClientRoom?.staff_chat?.id);
        else setSubRoomId(0);
      }
      if (StaffRoom) {
        setActiveKey("1");
        setUser(StaffRoom);
        setRoomId(StaffRoom.id);
        if (StaffRoom?.staff_chat !== undefined)
          setSubRoomId(StaffRoom?.staff_chat?.id);
        else setSubRoomId(0);
      }
    }
  }, [NotificationRoom?.chat_id]);
  useEffect(() => {
    if (showOldLog) {
      console.log("RoomRoomchangedRoomId", RoomId);
    }
  }, [RoomId]);
  const Spaces = useQuery(["Spaces"], async () => FetchSpaces(), {
    cacheTime: 0,
    staleTime: 10000,
  });
  useEffect(() => {
    if (SearchText !== "") setGroupsLoading(true);
  }, [SearchText]);
  useEffect(() => {
    if (newMessage) {
      if (showOldLog) {
        console.log("newMessage1", newMessage);
      }
      if (newMessage?.refetch === true) {
        if (showOldLog) {
          console.log("newMessage2", newMessage);
        }
        refetch();
        Spaces.refetch();
      } else {
        if (showOldLog) {
          console.log("newMessage3", newMessage);
        }
        Spaces.refetch();
        if (newMessage?.chat_type === 3 && GroupList) {
          if (showOldLog) {
            console.log("newMessage?.data?.chat_type", newMessage);
          }
          let newGroupList = GroupList;
          let seletedIndex = newGroupList.findIndex(
            (item: any) => item?.staff_chat?.id === newMessage?.chat_id
          );
          newGroupList[seletedIndex] = {
            ...newGroupList[seletedIndex],
            staff_chat: {
              ...newGroupList[seletedIndex]?.staff_chat,
              last_message: {
                ...newGroupList[seletedIndex]?.staff_chat?.last_message,
                content: newMessage?.preview,
                created_at: newMessage?.created_at,
              },

              unread_messages_count:
                newGroupList[seletedIndex]?.staff_chat?.unread_messages_count + 1,
            },
          };
          newGroupList.unshift(newGroupList.splice(seletedIndex, 1)[0]);
          setGroupList([...newGroupList]);
        }
        if (newMessage?.chat_type === 2 && GroupList) {
          let newGroupList = GroupList;
          let seletedIndex = newGroupList?.findIndex(
            (item: any) => item?.id === newMessage?.chat_id
          );
          newGroupList[seletedIndex] = {
            ...newGroupList[seletedIndex],
            last_message: {
              ...newGroupList[seletedIndex]?.last_message,
              content: newMessage?.preview,
              created_at: newMessage?.created_at,
            },
            unread_messages_count:
              newGroupList[seletedIndex].unread_messages_count + 1,
          };
          newGroupList.unshift(newGroupList.splice(seletedIndex, 1)[0]);
          setGroupList([...newGroupList]);
        }
      }
    }
  }, [newMessage]);
  useEffect(() => {
    setGroupsLoading(true);
  }, [space]);
  useEffect(() => {
    localStorage.setItem("roomId", "0");
    let oldRoomId = "0";
    document.addEventListener("visibilitychange", function () {
      if (document.hidden) {
        oldRoomId = localStorage.getItem("roomId") as string;
        localStorage.setItem("roomId", "");
      } else {
        if (oldRoomId && oldRoomId !== null && oldRoomId !== "0") {
          localStorage.setItem("roomId", oldRoomId);
          MakeSeen(parseInt(oldRoomId));
        }
      }
    });
  }, []);
  useEffect(() => {
    if (showOldLog) {
      console.log(
        "members_count",
        user?.staff_chat?.members_count,
        user?.members_count
      );
      console.log(
        "members_count",
        user?.staff_chat?.members_count !== user?.members_count
      );
      console.log("members_count", user);    }
   
    setIsExternal(
      user?.members?.some(function (el: any) {
        return el.role === "client";
      })
    );
    // refetch();
  }, [user]);

  return (
    <Row style={{ height: "100%", overflow: "hidden" }}>
      <Col md={4} xxl={3}>
        <SpacesCon
          data={Spaces.data}
          setSpace={(space: any) => setSpace(space)}
          refetch={() => Spaces.refetch()}
        />
      </Col>
      <Col md={7} xxl={5}>
        <UsersCon
          // key={JSON.stringify(GroupList)}
          isSpacesPage={true}
          key={"SpacesGroupList"}
          group
          data={GroupList}
          onFinish={(res: any) => {
            setRoomId(res?.id);
            setUser(res);
            if (res?.staff_chat !== undefined) {
              setSubRoomId(res?.staff_chat?.id);
            } else {
              setSubRoomId(0);
            }
            // refetch();
            // groups.refetch();
          }}
          RoomId={RoomId}
          setRoomId={(Id: any) => {
            setRoomId(Id);
          }}
          setUser={(data: any) => {
            setUser(data);
          }}
          setSubRoomId={(Id: any) => setSubRoomId(Id)}
          setLoading={(status: boolean) => setGroupsLoading(status)}

          Loading={GroupsLoading}
          setSearch={(data: any) => {
            setSearch(data);
          }}
          paginationData={data?.meta} 
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          enablePagination={false}

        />
      </Col>
      <Col md={13} xxl={16}>
        <Messages
          setActiveKey={(data: any) => setActiveKey(data)}
          activeKey={activeKey}
          key={RoomId}
          group={true}
          RoomId={RoomId}
          SubRoomId={SubRoomId}
          onFinish={() => {
            // refetch();
          }}
          user={user}
          setRoomId={(Id: any) => setRoomId(Id)}
          setUser={(data: any) => {
            setUser(data);
          }}
          setSubRoomId={(Id: any) => setSubRoomId(Id)}
          IsExternal={IsExternal}
          refetchGroups={() => {
            refetch();
            groups.refetch();
          }}
        />
      </Col>
    </Row>
  );
};

export default Spaces;
