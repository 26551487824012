import FingerprintJS from '@fingerprintjs/fingerprintjs';

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load();

export async function getDeviceFingerprint() {
  const fp = await fpPromise;
  const result = await fp.get();
  const visitorId = result.visitorId;
  console.log("Device Fingerprint:", visitorId);
  return visitorId;
}

