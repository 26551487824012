// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import { isJSON } from '../utils'


const initialAccessToken = () => {
    const item = window.localStorage.getItem('token')
    return item ? item : ""
}


const initialFCMToken = () => {
    const item = window.localStorage.getItem('fcmToken')
    return item ? JSON.parse(item) : ""
}


const initialDeviceID= () => {
    const item = window.localStorage.getItem('deviceId')
    return item ? JSON.parse(item) : ""
}


export const AuthReducerSlice = createSlice({
    name: 'AuthReducer',
    initialState: {
        accessToken: initialAccessToken(),
        fcmToken: initialFCMToken(),
        deviceId : initialDeviceID()
    },
    reducers: {
        handleAccessToken: (state, action) => {
            state.accessToken = action.payload
            if(isJSON(action.payload)){
                window.localStorage.setItem('token', JSON.stringify(action.payload))
            }else{
                window.localStorage.setItem('token', action.payload)
            }

        },
        handleFCMToken: (state, action) => {
            state.fcmToken = action.payload
            window.localStorage.setItem('fcmToken', JSON.stringify(action.payload))

        },
        handleDeviceId: (state, action) => {
            state.deviceId = action.payload
            window.localStorage.setItem('deviceId', JSON.stringify(action.payload))
        }
    }
})

export const {
    handleAccessToken,
    handleFCMToken,
    handleDeviceId
} = AuthReducerSlice.actions

export default AuthReducerSlice.reducer
