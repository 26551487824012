import { ApiErrorNotification } from "./../utils/ui/notificationService";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
} from "./types";

import AuthService from "../services/auth.service";
import { isError } from "../utils/api/models/api-response";



export const login = (username: string, password: string) => {
  return AuthService.login(username, password).then(
      (data: any) => {

        return data;
      },
      (error: any) => {
        // const message = error.response.data.errors[0];
        throw error.response.data;
      }
    );
};

export const logout = () => {
  return AuthService.logout();
};
