import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  Upload,
  notification,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import { AddFile } from "../../../services/contacts";
import { postGroup } from "../../../services/groups";
import { CloseOutlined } from "@ant-design/icons";
import ManageUsers from "./manageUsers";
import { showOldLog } from "../../../ConfigVar";

const AddGroup = ({ Contacts, onFinish }: any) => {
  const [imageUrl, setImageUrl] = useState();
  const [ImageData, setImageData] = useState();
  const [Loading, setLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [Perms, setPerms] = useState<any>([]);
  const [open, setOpen] = useState<boolean>(false);

  const UploadButton = () => (
    <>
      <span className="uploadtext">upload the space image</span>
      <Button
        className="uploadbtn"
        icon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.09502 10C3.03241 10.457 3 10.9245 3 11.4C3 16.7019 7.02944 21 12 21C16.9706 21 21 16.7019 21 11.4C21 10.9245 20.9676 10.457 20.905 10"
              stroke="url(#paint0_linear_2735_8592)"
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M12.0001 3V13M12.0001 3C11.2999 3 9.99159 4.9943 9.50006 5.5M12.0001 3C12.7003 3 14.0086 4.9943 14.5001 5.5"
              stroke="url(#paint1_linear_2735_8592)"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2735_8592"
                x1="21.81"
                y1="9.23"
                x2="11.2783"
                y2="27.7058"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#BF4034" />
                <stop offset="1" stop-color="#9B1724" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_2735_8592"
                x1="14.7251"
                y1="2.3"
                x2="5.45876"
                y2="7.26707"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#BF4034" />
                <stop offset="1" stop-color="#9B1724" />
              </linearGradient>
            </defs>
          </svg>
        }
        type="text"
      >
        Upload picture
      </Button>
    </>
  );

  const getBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const handleChange = (info: any) => {
    setImgLoading(true);
    var form_data = new FormData();
    form_data.append("file", info.fileList[0].originFileObj);
    form_data.append("type", "1");
    AddFile(form_data)
      .then((res: any) => {
        setImageData(res?.json?.hash);
        getBase64(info.fileList[0].originFileObj, (url: any) => {
          setImageUrl(url);
        });
        setImgLoading(false);
        return;
      })
      .catch(() => {
        setImgLoading(false);
      });
  };
  const submitAddGroup = (e: any) => {
    if (showOldLog) {
      console.log(e);
    }
    var form_data = new FormData();
    if (ImageData) form_data.append("icon", ImageData);
    if (e.space && e.space !== 0 && e.space !== "0")
      form_data.append("space_id", e.space);
    form_data.append("name", e.name);
    form_data.append("description", e.description);

    setLoading(true);

    Perms.map((per: any, index: any) => {
      form_data.append(`user_ids[${index}][id]`, per.id);
      per.perm.map((perId: any, newIndex: any) => {
        form_data.append(
          `user_ids[${index}][permissions][${newIndex}][perm]`,
          perId
        );
        form_data.append(
          `user_ids[${index}][permissions][${newIndex}][allow]`,
          "1"
        );
      });
    });
    postGroup(form_data)
      .then((res: any) => {
        if (showOldLog) {
          console.log(res?.json.id);
        }
        notification.open({
          type: "success",
          message: "Group " + e.name + " added successfully",
        });
        onFinish(res?.json);
        Modal.destroyAll();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const CreateManageOpenModal = () => {
    setOpen(true);
    // const createModal = Modal.info({
    //   className: "addspaceModal",
    //   title: "Manage users",
    //   width: "400px",
    //   footer: [],
    //   icon: <></>,
    //   centered: true,
    //   content: <></>,
    //   closeIcon: (
    //     <>
    //       <CloseOutlined color="#fff" />
    //     </>
    //   ),
    //   maskClosable: true,
    //   closable: true,
    //   autoFocusButton: null,
    // });
  };

  return (
    <div className="spaceCreateForm">
      <Form
        name="userCreateForm"
        layout="vertical"
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={(e) => submitAddGroup(e)}
      >
        <Row justify={"center"} gutter={[15, 15]}>
          <Col span={24}>
            <Form.Item name="image">
              <Upload
                name="avatar"
                listType="picture-card"
                maxCount={1}
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={() => false}
                accept="image/*"
                onChange={handleChange}
              >
                <div className="uploadbtncon">
                  {imgLoading ? (
                    <Spin
                      className="placeHolderImg"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "1px lightgray solid",
                      }}
                    ></Spin>
                  ) : imageUrl ? (
                    <img className="placeHolderImg" src={imageUrl} alt="" />
                  ) : (
                    <img
                      className="placeHolderImg"
                      src="/images/placeholderImg.png"
                      alt=""
                    />
                  )}
                  <UploadButton />
                </div>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Group Name"
              name="name"
              rules={[
                { required: true, message: "Please input your Group name!" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Group description"
              name="description"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input the Group description!",
              //   },
              // ]}
            >
              <TextArea autoSize={{ minRows: 2, maxRows: 10 }} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Lists of users">
              <Input
                placeholder="Click to manage group's users"
                readOnly
                value={Perms?.map((v: any) => v?.name)?.join(", ")}
                onClick={() => {
                  CreateManageOpenModal();
                }}
                prefix={
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.50005 6.5C9.50005 6.5 15.5 10.9189 15.5 12.5C15.5 14.0812 9.5 18.5 9.5 18.5"
                      stroke="#48484A"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
              />
            </Form.Item>
            {/* <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <button
                onClick={() => CreateManageOpenModal()}
                type="button"
                className="manageBtn"
              >
                Manage Participants
              </button>
            </div> */}
            {/* <div className="chatUsers">
              {Contacts?.data?.json.map((user: any) => (
                <>
                  {Perms.filter((e: any) => e.id === user?.contact?.id).length >
                    0 && (
                    <div className={"userCard"}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="cardImgCon">
                          <div className="imgPlaceHolder">
                            <img
                              src={user?.contact?.profile_image?.url}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="cardText">
                          <div className="cardTitle">
                            {user?.contact?.full_name}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div> */}
          </Col>
          <Col span={24}>
            <Form.Item>
              <div style={{ textAlign: "end" }}>
                <Button
                  loading={Loading}
                  className="createbtn"
                  type="primary"
                  htmlType="submit"
                >
                  Create Group
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal
        open={open}
        className="addspaceModal"
        title="Manage users"
        width={"400px"}
        footer={[]}
        centered
        closeIcon={
          <>
            <CloseOutlined color="#fff" />
          </>
        }
        maskClosable
        closable
        onCancel={() => setOpen(false)}
      >
        <ManageUsers
          Perms={Perms}
          setPerms={(newParms: any) => {
            setPerms([...newParms]);
          }}
          Contacts={Contacts}
          setOpen={setOpen}
        />
      </Modal>
    </div>
  );
};

export default AddGroup;
