import React, { useState } from 'react'
import ForwardUsers from '../../forwardUsers';
import { useQuery, useQueryClient } from 'react-query';
import { FetchUsersMessages } from '../../../../services/chats';
import { FetchSpaces } from '../../../../services/spaces';
import { FetchGroups } from '../../../../services/groups';
import { FloatButton, Modal } from 'antd';
import { CloseCircleOutlined, CloseOutlined, ForwardOutlined } from '@ant-design/icons';

const ForwardMessageModal = (props: any) => {

    const {
        messagesArray,
        onCancel


    } = props

    const queryClient = useQueryClient();

    const [ForwardSearch, setForwardSearch] = useState<any>("");

    const { data, status, isLoading } = useQuery(
        ["UsersChats", ForwardSearch],
        async () => FetchUsersMessages(1, ForwardSearch),
        {
            cacheTime: 0,
            staleTime: 10000,
        }
    );
    const spaces = useQuery("Spaces", async () => FetchSpaces(), {
        cacheTime: 0,
        staleTime: 10000,
    });
    const groupsData = useQuery(
        ["groupsData", 0, ""],
        async () => FetchGroups(1, 0, ""),
        {
            cacheTime: 0,
            staleTime: 10000,
        }
    );


    const onClickForwardButton = () => {
        Modal.info({
            className: "addspaceModal",
            centered: true,
            title: "Forward message to",
            autoFocusButton: null,
            icon: (
                <img
                    style={{
                        transform: "rotateY(180deg)",
                        display: "inline-block",
                    }}
                    src="/images/Arrow_Undo_Up_Left.svg"
                    alt=""
                />
            ),
            content: (
                <div>
                    <ForwardUsers
                        userData={data?.json}
                        spaceData={spaces?.data?.json}
                        groupsData={groupsData?.data?.json}
                        messagesArray={messagesArray}
                        setSearch={(data: any) => {
                            setForwardSearch(data);
                        }}
                        onSubmit={() => {
                            queryClient.invalidateQueries("Messages");
                            queryClient.invalidateQueries("groups");
                            queryClient.invalidateQueries("UsersChats");
                            onCancel && onCancel()
                        }}
                    />

                </div>
            ),
            closeIcon: (
                <>
                    <CloseOutlined color="#fff" />
                </>
            ),
            maskClosable: true,
            closable: true,
            footer: [],
        });
    }

    return (
        <>
       <FloatButton.Group shape="circle" style={{ right: 44 , bottom: 78 }}>
                <FloatButton 
                    icon={<ForwardOutlined />} 
                    onClick={onClickForwardButton}
                    tooltip="Forward"
                    type='primary'
                />
                <FloatButton 
                    icon={<CloseCircleOutlined />}
                    onClick={() => onCancel && onCancel()}
                    tooltip="Cancel"
                />
            </FloatButton.Group>

        </>
    )
}

export default ForwardMessageModal