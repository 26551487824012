import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";

import spacesStyles from "../../styles/pages/spaces.module.scss";
import SpacesCon from "../../components/spaces/spacesCon";
import Groups from "../../components/room/UsersCon";
import Messages from "../../components/spaces/messages";
import { useQuery } from "react-query";
import { FetchGroups } from "../../services/groups";
import { FetchUsersMessages } from "../../services/chats";
import UsersCon from "../../components/room/UsersCon";
import { MessageContext } from "../../App";
import { FetchContacts } from "../../services/contacts";
import { showOldLog } from "../../ConfigVar";

const ContactsPage = () => {
  const [RoomId, setRoomId] = useState(0);
  const [SubRoomId, setSubRoomId] = useState(0);
  const [SearchText, setSearch] = useState<any>("");
  const [GroupsLoading, setGroupsLoading] = useState(true);
  const [user, setUser] = useState<any>({});
  const { data, status, isLoading, refetch } = useQuery(
    ["ContactsList"],
    async () => FetchContacts(0),
    {
      cacheTime: 0,
      staleTime: 10000,
    }
  );
  const [UserLoc, setUserLoc] = useState<any>({});
  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUserLoc(t);
    if (showOldLog) {
      console.log(t);
    }
  }, [window.localStorage.getItem("user")]);
  useEffect(() => {
    if (SearchText !== "") setGroupsLoading(true);
  }, [SearchText]);
  const [newMessage, SetNewMessage] = useContext<any>(MessageContext);
  useEffect(() => {
    if (newMessage && Object.keys(newMessage).length > 0) {
      refetch();
    }
  }, [newMessage]);
  if (showOldLog) {
    console.log("ContactsList", data);
  }
  return (
    <Row style={{ height: "100%" }}>
      <Col md={7}>
        <UsersCon
          key={"ContactPage"}
          data={data?.json.filter((userr: any) => userr?.contact?.id !== UserLoc?.id)}
          RoomId={RoomId}
          setRoomId={(Id: any) => {
            setRoomId(Id);
          }}
          setUser={(data: any) => {
            setUser(data);
          }}
          setSubRoomId={(Id: any) => setSubRoomId(Id)}
          setLoading={(status: boolean) => setGroupsLoading(status)}
          Loading={GroupsLoading}
          setSearch={(data: any) => {
            setSearch(data);
          }}
          contact
        />
      </Col>
      <Col md={17}>
        <Messages
          group={false}
          RoomId={RoomId}
          SubRoomId={SubRoomId}
          user={user}
          setRoomId={(Id: any) => setRoomId(Id)}
          setUser={(data: any) => {
            setUser(data);
          }}
          setSubRoomId={(Id: any) => setSubRoomId(Id)}
          contact
        />
      </Col>
    </Row>
  );
};

export default ContactsPage;
