import React, { useState } from "react";
import {
  AddContactPermission,
  FetchContacts,
} from "../../../services/contacts";
import { useQuery } from "react-query";
import { Button, Checkbox, Modal, notification, Radio, Tabs } from "antd";
import Permissions from "./permissions";
import { showOldLog } from "../../../ConfigVar";

const PermissionsList = ({ currentUser, otherUser, refetch }: any) => {
  const [Loading, setLoading] = useState(false);
  const [CurrentPerm, setCurrentPerm] = useState([]);
  const [OtherPerm, setOtherPerm] = useState([]);
  if (showOldLog) {
    console.log("mahmod---currentUser", currentUser);
    console.log("mahmod---otherUser", otherUser);
  }

  const AddContectHandle = () => {
    setLoading(true);
    // const formData = new FormData();
    // formData.append("username", "Chris");
    // formData.append("username", "Chris");
    // formData.append("username", "Chris");
    AddContactPermission({
      from_user_ids: [currentUser?.contact?.id],
      to_user_ids: [otherUser.contact?.id],
      permissions: OtherPerm,
    }).then(() => {
      AddContactPermission({
        from_user_ids: [otherUser.contact?.id],
        to_user_ids: [currentUser?.contact?.id],
        permissions: CurrentPerm,
      }).then(() => {
        setLoading(false);
        refetch();
        notification.open({
          message: "Permissions updated successfully",
        });
        Modal.destroyAll();
      });
    });
  };
  return (
    <>
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            key: "1",
            label: "New User (" + currentUser?.contact.full_name + ")",
            children: (
              <Permissions
                setPermission={(perm: any) => setCurrentPerm(perm)}
                user={currentUser?.contact}
                prmissionList={currentUser?.props ? currentUser?.props : []}
              />
            ),
          },
          {
            key: "2",
            label: "Current User (" + otherUser?.contact?.full_name + ")",
            children: (
              <Permissions
                setPermission={(perm: any) => setOtherPerm(perm)}
                user={otherUser?.contact}
                prmissionList={otherUser?.props ? otherUser?.props : []}
              />
            ),
          },
        ]}
      />
      <Button
        onClick={() => AddContectHandle()}
        className="ConfirmButton"
        loading={Loading}
        type="primary"
      >
        Confirm
      </Button>
    </>
  );
};

export default PermissionsList;
