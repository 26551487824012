import React, { useEffect, useRef } from 'react'
import Pusher from "pusher-js";
import { useAccessToken } from '../redux/ApiStorage';
import { REACT_APP_API_BASE_URL, showOldLog } from '../ConfigVar';
import { notification } from "antd";
import { useNavigate } from 'react-router-dom';

const pako = require("pako");
const atob = require("atob");

const PusherConnection = (props) => {

    const {
        SetNewMessage,
        UserLocID,
        setIncomingCall,
        setIncomingCallInfo,
        setDataCall,
        setFinsishCallInfo,
        allMessagesId,
        SetNotificationRoom,
        setAllMessages,

    } = props


    const [accessToken] = useAccessToken()
    const navigate = useNavigate();
    const pusherRef = useRef(null);
    const previousChannelRef = useRef(null);

    useEffect(() => {

        if (UserLocID && accessToken) {

            // Unsubscribe from previous channel if exists
            if (previousChannelRef.current) {
                pusherRef.current?.unsubscribe(previousChannelRef.current);
            }

            // Initialize Pusher if not already initialized
            if (!pusherRef.current) {

                pusherRef.current = new Pusher("fa9d203ca4be7af3fbb7", {
                    cluster: "ap1",
                    channelAuthorization: {
                        endpoint: `${REACT_APP_API_BASE_URL}broadcasting/auth`,
                        transport: "ajax",
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    },
                });

            }

            // Store new channel name
            const channelName = `private-channel-user-${UserLocID}`;
            previousChannelRef.current = channelName;

            // Subscribe to new channel
            const channel = pusherRef.current.subscribe(channelName);
            // Bind events
            channel.bind("incoming_call", function (data) {
                var b64Data = data.payload;
                var strData = atob(b64Data);
                var charData = strData.split("").map(function (x) {
                    return x.charCodeAt(0);
                });
                var binData = new Uint8Array(charData);
                var data2 = pako.inflate(binData);
                var strData = String.fromCharCode.apply(
                    null,
                    new Uint16Array(data2)
                );

                var newObject = JSON.parse(strData);



                setIncomingCall(true);
                setIncomingCallInfo(newObject);
                setDataCall(newObject);
            });

            channel.bind("finish_call", function (data) {
                var b64Data = data.payload;
                var strData = atob(b64Data);
                var charData = strData.split("").map(function (x) {
                    return x.charCodeAt(0);
                });
                var binData = new Uint8Array(charData);
                var data2 = pako.inflate(binData);
                var strData = String.fromCharCode.apply(
                    null,
                    new Uint16Array(data2)
                );

                var newObject = JSON.parse(strData);


                setIncomingCall(false); // Set incoming call state to true when there's an incoming call event
                setFinsishCallInfo(newObject);
                setDataCall(newObject);
            });

            channel.bind("missed_call", function (data) {
                var b64Data = data.payload;
                var strData = atob(b64Data);
                var charData = strData.split("").map(function (x) {
                    return x.charCodeAt(0);
                });
                var binData = new Uint8Array(charData);
                var data2 = pako.inflate(binData);
                var strData = String.fromCharCode.apply(
                    null,
                    new Uint16Array(data2)
                );

                var newObject = JSON.parse(strData);

                console.log('newObject',newObject);
                
            });



            channel.bind("sync", function (data) {
                var b64Data = data.payload;
                var strData = atob(b64Data);
                var charData = strData.split("").map(function (x) {
                    return x.charCodeAt(0);
                });
                var binData = new Uint8Array(charData);
                var data = pako.inflate(binData);
                var strData = String.fromCharCode.apply(
                    null,
                    new Uint16Array(data)
                );
                var newObject = JSON.parse(strData);
                const storedMessageIds = JSON.parse(localStorage.getItem('messageIds') || '[]');

                console.log("newObject",newObject);
                

                if (showOldLog) {
                    console.log("checkNotification", newObject?.instanceof === 302);
                }
                if (newObject?.instanceof === 302) {

                    console.log(
                        "checkNotifications",
                        newObject,
                    );
                    if (
                        newObject?.data?.chat_id.toString() ===
                        localStorage.getItem("roomId")
                    ) {
                        var wasTriggered = channel.trigger("client-sync", {
                            hash: [newObject?.hash.toString()],
                            seen: true,
                        });
                    } else {
                        var wasTriggered = channel.trigger("client-sync", {
                            hash: [newObject?.hash.toString()],
                        });
                    }
                    if (UserLocID !== newObject?.data?.sender_id &&
                        !storedMessageIds.find(e => (newObject?.data?.id == e?.id && newObject?.data?.created_at == newObject?.data?.created_at))
                    ) {
                        if (
                            newObject?.action === 111 &&
                            newObject?.data?.chat_id ==
                            parseInt(localStorage.getItem("roomId"))
                        ) {
                            localStorage.setItem('messageIds', JSON.stringify([...storedMessageIds, { id: newObject?.data?.id, created_at: newObject?.data?.created_at }]));
                            SetNewMessage(newObject?.data);
                        } else if (newObject?.action === 111) {
                            SetNewMessage({ ...newObject?.data, outFetch: true });
                        }
                    }

                    // queryClient.invalidateQueries("groupsData");
                    // queryClient.invalidateQueries("UsersChats");

                    if (
                        newObject?.data?.text &&
                        newObject?.data?.is_mine === 0 &&
                        newObject?.data?.chat_id !==
                        parseInt(localStorage.getItem("roomId"))
                    ) {
                        console.log(
                            "checkNotification",
                            allMessagesId?.includes(newObject?.data?.id),
                            allMessagesId,
                            newObject?.data?.id
                        );
                        if (
                            allMessagesId.length !== 0 ||
                            !allMessagesId?.includes(newObject?.data?.id)
                        ) {
                            // notification.config({ maxCount: 2 });
                            // notification.destroy();
                            let newIds = allMessagesId;
                            newIds.push(newObject?.data?.id);
                            setAllMessages([...newIds]);
                            notification.open({
                                onClick: () => {
                                    if (showOldLog) {
                                        console.log("RoomRoomchangedappp", newObject?.data);
                                    }
                                    if (newObject?.data?.chat_type === 1) navigate("/messages");
                                    else navigate("/spaces");
                                    SetNotificationRoom(newObject?.data);
                                },
                                className: "messageNotification",
                                message:
                                    newObject?.data?.chat_type === 1
                                        ? newObject?.data?.user?.full_name
                                        : newObject?.data?.chat_name +
                                        " - " +
                                        newObject?.data?.user?.full_name,
                                description: newObject?.data?.text,
                                icon: (
                                    <img
                                        style={{
                                            width: 50,
                                            height: 50,
                                            objectFit: "cover",
                                            borderRadius: "50%",
                                            marginInlineEnd: 10,
                                        }}
                                        src={newObject?.data?.user?.profile_image.url}
                                    />
                                ),
                            });
                        }
                    }
                }
                if (newObject?.instanceof === 304) {
                    console.log("instanceof 304");
                    SetNewMessage({ refetch: true });
                }
                if (newObject?.instanceof === 305) {
                    console.log("instanceof 305");
                    SetNewMessage({ refetch: true });
                }

                if (newObject?.instanceof === 302 && newObject?.action === 444) {
                    SetNewMessage({
                        deletedMessageId: newObject?.data?.id,
                        action: 'delete',
                        refetch: true,
                    });
                }
                if (UserLocID !== newObject?.data?.sender_id) {
                    if (newObject?.instanceof === 302 && newObject?.action === 222) {
                        if (newObject?.data?.chat_id !==
                            parseInt(localStorage.getItem("roomId"))) {
                            SetNewMessage({
                                updatedMessageId: newObject?.data?.id,
                                action: 'update',
                                refetch: true,
                            });
                        } else {
                            SetNewMessage({
                                updatedMessageId: newObject?.data?.id,
                                action: 'update',
                                refetch: false,
                                ...newObject?.data
                            });
                        }

                    }
                }

            });
            channel.bind("client-sync", function (data) {
                var b64Data = data.payload;
                var strData = atob(b64Data);
                var charData = strData.split("").map(function (x) {
                    return x.charCodeAt(0);
                });
                var binData = new Uint8Array(charData);
                var data = pako.inflate(binData);
                var strData = String.fromCharCode.apply(
                    null,
                    new Uint16Array(data)
                );
                var newObject = JSON.parse(strData);

            });
            // Cleanup function
            return () => {
                if (channelName) {
                    pusherRef.current?.unsubscribe(channelName);
                }
            };
        }
    }, [UserLocID, accessToken]);

    Pusher.log = (msg) => {
        console.log("data log", msg);
    };





    return (
        <></>
    )
}

export default PusherConnection