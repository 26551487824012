import React, { useContext, useEffect, useRef, useState } from "react";
import spacesStyles from "../../../styles/pages/spaces.module.scss";
import { useInfiniteQuery, useQueryClient } from "react-query";
import {
  AddChat,
  EditMessages,
  FetchMessages,
  MakeSeen,
  SendMessages,
} from "../../../services/chats";
import ChatMessage from "./chatMessage";
import { Skeleton, Image, Dropdown, Spin, message, Checkbox } from "antd";
import { LastContext, MessageContext, NotificationContext, RoomContext } from "../../../App";
import { MenuProps } from "antd/lib";
import { LoadingOutlined } from "@ant-design/icons";
import { VoiceVisualizer, useVoiceVisualizer } from "react-voice-visualizer";
import TextareaAutosize from "react-textarea-autosize";
import MediaPreview from "./mediaPreview";
import { showOldLog } from "../../../ConfigVar";
import ForwardMessageModal from "./chatMessage/ForwardMessageModal";
// import { onValue, ref } from "firebase/database";

// import db from "../../../firebase.js";

const RoomChat = ({ roomId, group, user, setRoomId, contact, newMessageRefetch }: any) => {

  // useEffect(() => {
  //   console.log("datadata121")
  //   const query = ref(db, "projects");
  //   console.log("datadata122",query)
  //   return onValue(query, (snapshot) => {
  //     const data = snapshot.val();
  //     console.log("datadata123",data)
  //     if (snapshot.exists()) {
  //      console.log("datadata14",data)
  //     }
  //   });
  // }, []);
  const [newMessage, SetNewMessage] = useContext<any>(MessageContext);
  const [Room, SetRoom] = useContext<any>(RoomContext);
  const [Last, setLast] = useContext<any>(LastContext);
  const [Messsage, setMesssage] = useState("");
  const [Loading, setLoading] = useState<any>(true);
  const [User, setUser] = useState<any>({});
  const [RoomHeight, setRoomHeight] = useState<any>(0);
  const [ContactRoom, setContactRoom] = useState<any>(contact ? 0 : -1);
  const [ContactRoomDetails, setContactRoomDetails] = useState<any>({});
  const [StartRecord, setStartRecord] = useState<any>(false);
  const [FilesData, setFilesData] = useState<any>({});


  const queryClient = useQueryClient();

  useEffect(() => {
    setMesssage("");
  }, [roomId]);



  useEffect(() => {
    if (showOldLog) {
      console.log("refetch user inside", newMessage);
    }

    if (newMessage?.refetch &&  newMessage?.action == "delete") {
      refetch()
    }

    if (newMessage?.refetch &&  newMessage?.action == "update") {
      refetch()
    }


  }, [newMessage]);
  const [MessagesSetting, setMessagesSetting] = useState<any>({
    page: 0,
    loadingMore: false,
    data: undefined,
  });
  useEffect(() => {
    if (showOldLog) {
      console.log("RoomRoomchangedRoomIdinside", roomId);
    }
    localStorage.setItem("roomId", roomId);
    if (!contact)
      MakeSeen(roomId).then(() => {
        SetRoom(roomId);
      });
  }, [roomId]);
  const divForAutoScroll = useRef<any>();
  const { data, fetchNextPage, status, isLoading, refetch }: any = useInfiniteQuery(
    ["Messages", roomId, group, ContactRoom],
    async ({ pageParam = 1 }) =>
      FetchMessages(contact ? ContactRoom : roomId, pageParam, group, ""),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) => MessagesSetting.page + 1 ?? false,
      cacheTime: 0,
      staleTime: 10000,
      enabled: roomId !== undefined && roomId !== 0 && roomId !== null && !isNaN(roomId) && ContactRoom !== 0,
    }
  );
  useEffect(() => {
    if (showOldLog) {
      console.log("MessagesSetting", MessagesSetting)
    }
  }, [MessagesSetting]);
  useEffect(() => {
    if (contact)
      AddChat({ user_id: roomId }).then((res) => {
        setContactRoom(res?.json?.id);
        setContactRoomDetails(res?.json);
      });
  }, [roomId]);
  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUser(t);
  }, []);
  useEffect(() => {
    if (MessagesSetting.page === 0 && divForAutoScroll.current)
      divForAutoScroll.current.scrollIntoView();
  }, [MessagesSetting.data]);
  useEffect(() => {
    setTimeout(() => {
      if (MessagesSetting.page === 0 && divForAutoScroll.current)
        divForAutoScroll.current.scrollIntoView();
    }, 500);
  }, [roomId]);
  useEffect(() => {
    if (showOldLog) {
      console.log("scrolled", MessagesSetting.page)
    }
    if (MessagesSetting.data)
      if (MessagesSetting.page === 1) {
        if (showOldLog) {
          console.log("scrolled")
        }
        // @ts-ignore
        document.getElementById(MessagesSetting.data[0]?.id?.toString()) !== null && document.getElementById(MessagesSetting.data[0]?.id?.toString()).scrollIntoView();
      } else {
        if (showOldLog) {
          console.log("scrolled", MessagesSetting.data[MessagesSetting.data.length - 1])
        }
        // @ts-ignore
        document.getElementById(MessagesSetting.data[MessagesSetting.data.length - 21]?.id?.toString()) !== null && document.getElementById(MessagesSetting.data[MessagesSetting.data.length - 21]?.id?.toString()).scrollIntoView();
      }
  }, [MessagesSetting.data]);
  useEffect(() => {
    if (status === "success") {
      let allProducts: any = [];

      data?.pages &&
        data?.pages[0] !== undefined &&
        data?.pages?.map((page: any) => {
          if (page !== null) allProducts = [...allProducts, ...page.json];
        });
      let newArr = MessagesSetting;
      newArr.data = allProducts;
      // newArr.page = newArr+1;

      if (data?.pages[data?.pages.length - 1]?.json.length > 0) {
        newArr.loadingMore = false;
      } else {
        newArr.loadingMore = true;
      }
      setMessagesSetting({ ...newArr });
      setLoading(false);
      if (showOldLog) {
        console.log("newArr.page", newArr.page)
      }

    }
  }, [data?.pages]);
  useEffect(() => {
    setLoading(true);
  }, [group, roomId]);
  const AlwaysScrollToBottom = () => {
    return <div ref={divForAutoScroll} />;
  };
  const [reply, setReply] = useState({
    id: 0,
    message: "",
    sender: "",
    is_corrected: false,
  });
  const [Edit, setEdit] = useState({
    id: 0,
    message: "",
  });

  const sendMessage = async () => {
    divForAutoScroll.current.scrollIntoView({ behavior: "smooth" });
    let newMessages = MessagesSetting.data;
    if (!contact)
      setLast({
        group: group ? true : false,
        roomId: roomId,
        user: User,
        messsage: Messsage,
      });
    newMessages.unshift({
      text: Messsage,
      is_mine: 1,
      is_seen: "pending",
      replied:
        reply.id !== 0
          ? {
            content: reply.sender + " :" + reply.message,
          }
          : undefined,
      is_corrected: reply.is_corrected,
    });
    setMessagesSetting({ ...MessagesSetting, data: newMessages });
    var formData = new FormData();
    formData.append("text", Messsage);
    formData.append("chat_id", contact ? ContactRoom : roomId);
    formData.append("user_id", User?.id);
    formData.append("type", "1");

    if (reply.id !== 0) {
      formData.append("reply_message_id", reply.id.toString());
      if (reply.is_corrected)
        formData.append("is_corrected", `${+reply.is_corrected}`);
    }
    setReply({
      id: 0,
      message: "",
      sender: "",
      is_corrected: false,
    });
    SendMessages(formData).then((res) => {
      newMessageRefetch();
      refetch();
      let newMessages = MessagesSetting.data;
      newMessages[newMessages.findIndex((x: any) => x.text == Messsage)] = {
        ...newMessages[newMessages.findIndex((x: any) => x.text == Messsage)],
        is_seen:
          newMessages[newMessages.findIndex((x: any) => x.text == Messsage)] ===
            1
            ? 1
            : 0,
      };
      setMessagesSetting({ ...MessagesSetting, data: newMessages });
      divForAutoScroll.current.scrollIntoView({ behavior: "smooth" });
    });
    setMesssage("");
  };
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <>
          <label htmlFor="uploadImage">
            <img src="/images/image.svg" alt="" />
          </label>
          <input
            onChange={(e) => sendFile(e.target.files, "4")}
            id="uploadImage"
            type="file"
            accept="image/jpeg, image/png, image/jpg"
            multiple
            style={{ display: "none" }}
          ></input>
        </>
      ),
    },
    {
      key: "2",
      label: (
        <>
          <label htmlFor="uploadVedio">
            <img
              style={{ transform: "scale(1.2)" }}
              src="/images/vedio.svg"
              alt=""
            />
          </label>
          <input
            onChange={(e) => sendFile(e.target.files, "5")}
            id="uploadVedio"
            type="file"
            accept="video/mp4,video/x-m4v,video/*"
            multiple
            style={{ display: "none" }}
          ></input>
        </>
      ),
    },
    {
      key: "3",
      label: (
        <>
          <label htmlFor="uploadAudio">
            <img src="/images/audio.svg" alt="" />
          </label>
          <input
            onChange={(e) => sendFile(e.target.files, "3")}
            id="uploadAudio"
            type="file"
            accept="audio/mp3,audio/*;capture=microphone"
            multiple
            style={{ display: "none" }}
          ></input>
        </>
      ),
    },
    {
      key: "4",
      label: (
        <>
          <label htmlFor="uploadFile">
            <img src="/images/file.svg" alt="" />
          </label>
          <input
            onChange={(e) => sendFile(e.target.files, "6")}
            id="uploadFile"
            type="file"
            multiple
            style={{ display: "none" }}
          ></input>
        </>
      ),
    },
  ];

  const sendFile = (files: any, type: any) => {
    if (type === "4") {
      setFilesData({ type: "image", files: files });
    } else if (type === "5") {
      setFilesData({ type: "video", files: files });
    } else {
      for (var x = 0; x < files.length; x++) {
        let newMessages = MessagesSetting.data;
        if (type === "3") {
          newMessages.unshift({
            type: 3,
            audio: [{ url: URL.createObjectURL(new Blob([files[x] as any])) }],
            is_mine: 1,
            is_seen: "pending",
          });
        }
        if (showOldLog) {
          console.log(files[x]);
        }
        if (type === "6") {
          newMessages.unshift({
            type: 6,
            doc: [
              {
                file_name: files[x].name,
                size: files[x].size,
                url: URL.createObjectURL(new Blob([files[x] as any])),
                type: files[x].type,
              },
            ],
            is_mine: 1,
            is_seen: "pending",
          });
        }

        setMessagesSetting({ ...MessagesSetting, data: newMessages });
        var formData = new FormData();
        var file;
        if (type === "2") {
          file = new Blob([files[x]], { type: "audio/m4a" });
        }
        formData.append(
          `content[${type === "2" ? "voice" : ""}${type === "3" ? "audio" : ""
          }${type === "4" ? "image" : ""}${type === "5" ? "video" : ""}${type === "6" ? "doc" : ""
          }]`,
          type === "2" ? file : files[x]
        );
        if (!contact)
          setLast({
            group: group ? true : false,
            roomId: roomId,
            user: User,
            messsage: Messsage,
          });
        formData.append("chat_id", contact ? ContactRoom : roomId);
        formData.append("type", type);
        if (reply.id !== 0)
          formData.append("reply_message_id", reply.id.toString());

        setReply({
          id: 0,
          message: "",
          sender: "",
          is_corrected: false,
        });
        SendMessages(formData).then(() => {
          // queryClient.invalidateQueries("Messages");
          // queryClient.invalidateQueries("UsersChats");
          refetch();
          divForAutoScroll.current.scrollIntoView({ behavior: "smooth" });
        });
      }
    }
  };
  const editMessage = () => {
    if (showOldLog) {
      console.log("edit");
    }
    divForAutoScroll.current.scrollIntoView({ behavior: "smooth" });
    var formData = new FormData();
    formData.append("text", Messsage);
    formData.append("id", Edit.id.toString());
    setEdit({
      id: 0,
      message: "",
    });
    let newMessages = MessagesSetting.data;

    let seletedIndex = MessagesSetting.data.findIndex(
      (item: any) => item?.id === Edit.id
    );
    newMessages[seletedIndex] = {
      ...newMessages[seletedIndex], text: Messsage, old_messages: [{
        causer: User, subject: newMessages[seletedIndex],
        causer_type: "App\\Models\\User"
      }]
    };
    setMessagesSetting({ ...MessagesSetting, data: newMessages });
    EditMessages(formData).then(() => {
      queryClient.invalidateQueries("Messages");
      queryClient.invalidateQueries("d");

      divForAutoScroll.current.scrollIntoView({ behavior: "smooth" });
    });

    setMesssage("");
  };
  useEffect(() => {
    if ((newMessage?.refetch === undefined || newMessage?.refetch === false) && (newMessage?.outFetch === undefined || newMessage?.outFetch === false)) {
      divForAutoScroll?.current?.scrollIntoView({ behavior: "smooth" });
      let newMessages = MessagesSetting.data;
      newMessages?.unshift({
        ...newMessage,
      });
      setMessagesSetting({ ...MessagesSetting, data: newMessages });

      setTimeout(() => {
        divForAutoScroll?.current?.scrollIntoView({ behavior: "smooth" });
      }, 300);
    }
  }, [newMessage]);
  const recorderControls = useVoiceVisualizer(
    {
      audioOptions: {
        mimeType: 'audio/m4a',
        audioBitsPerSecond: 128000,
        sampleRate: 44100
      }
    }
  );
  const {
    // ... (Extracted controls and states, if necessary)
    recordedBlob,
    error,
    audioRef,
    startRecording,
    clearCanvas,
    stopRecording,
  } = recorderControls;

  // Get the recorded audio blob
  useEffect(() => {
    if (!recordedBlob) return;
    addAudioElement(recordedBlob);
    setStartRecord(false);
    clearCanvas();
    if (showOldLog) {
      console.log(recordedBlob);
    }
  }, [recordedBlob, error]);

  // Get the error when it occurs
  useEffect(() => {
    if (!error) return;

    console.error(error);
  }, [error]);
  const addAudioElement = (blob: any) => {
    if (showOldLog) {
      console.log("blobblob", blob);
    }
    let newMessages = MessagesSetting.data;
    newMessages.unshift({
      type: 2,
      voice: [{ url: URL.createObjectURL(new Blob([blob as any])) }],
      is_mine: 1,
      is_seen: "pending",
    });
    setMessagesSetting({ ...MessagesSetting, data: newMessages });
    if (showOldLog) {
      console.log(blob);
    }
    const m4aBlob = new Blob([blob], {
      type: 'audio/x-m4a'
    });
    const url = URL.createObjectURL(blob);
    var formData = new FormData();
    // formData.append(`content[voice]`, blob);
    formData.append(`content[voice]`, m4aBlob, 'audio.m4a'); // Add filename with .m4a extension

    formData.append("chat_id", roomId);
    formData.append("type", "2");
    if (reply.id !== 0)
      formData.append("reply_message_id", reply.id.toString());
    setReply({
      id: 0,
      message: "",
      sender: "",
      is_corrected: false,
    });
    if (!contact)
      setLast({
        group: group ? true : false,
        roomId: roomId,
        user: User,
        messsage: "Voice message",
      });
    SendMessages(formData).then(() => {
      // queryClient.invalidateQueries("Messages");
      // queryClient.invalidateQueries("UsersChats");
      refetch();
      divForAutoScroll.current.scrollIntoView({ behavior: "smooth" });
    });

  }; useEffect(() => {
    if (
      // @ts-ignore
      document?.getElementById("navCon").offsetHeight !== null &&
      // @ts-ignore
      document?.getElementById("chatHeader").offsetHeight !== null
    )
      setRoomHeight(
        // @ts-ignore
        document?.getElementById("navCon").offsetHeight +
        // @ts-ignore
        document?.getElementById("chatHeader").offsetHeight
      );
  }, [
    document?.getElementById("navCon"),
    document?.getElementById("chatHeader"),
  ]);
  useEffect(() => {
    if (!Loading) {
      //@ts-ignore
      document.getElementById("roomChat") !== null && document.getElementById("roomChat").addEventListener("scroll", (e) => handleScroll(e));
    }
  });
  const handleScroll = (e: any) => {
    //@ts-ignore
    if (showOldLog) {
      console.log("scroll", document.getElementById("roomChat") !== null && document.getElementById("roomChat")?.scrollTop);
    }
    let newArr = MessagesSetting;
    //@ts-ignore
    if (document.getElementById("roomChat") !== null && document.getElementById("roomChat")?.scrollTop < 10) {
      if (!MessagesSetting.loadingMore) {
        if (newArr.page.toString === MessagesSetting.page.toString) {
          //@ts-ignore
          //  document.getElementById("roomChat")!==null&&document.getElementById("roomChat").scrollTo(0, 100);
          newArr.loadingMore = true;
          newArr.page = MessagesSetting.page + 1;
          setMessagesSetting({ ...newArr });
          fetchNextPage();
          // setPagerLoading(true);
        }
      }
    }
  };

  const [enableCheckMessage, setEnableCheckMessage] = useState<boolean>(false);
  const [selectedMessageIds, setSelectedMessageIds] = useState<number[]>([]);
  const toggleMessageSelection = (messageId: number) => {
    if (selectedMessageIds.includes(messageId)) {
      setSelectedMessageIds(selectedMessageIds.filter(id => id !== messageId));
    } else {
      setSelectedMessageIds([...selectedMessageIds, messageId]);
    }
  };

  useEffect(() => {
    if (selectedMessageIds?.length == 0) {
      setEnableCheckMessage(false)
    }
  }, [selectedMessageIds])

  return (
    <div
      style={{
        height: "calc( 100vh - " + RoomHeight + "px)",
      }}
      className={spacesStyles.roomBox + " chatRooms"}
    >
      {enableCheckMessage && <ForwardMessageModal
        messagesArray={selectedMessageIds}
        onCancel={() => {
          setEnableCheckMessage(false)
          setSelectedMessageIds([])
        }}
      />}
      {Object.keys(FilesData).length > 0 && (
        <MediaPreview
          divForAutoScroll={divForAutoScroll}
          contact={contact}
          ContactRoom={ContactRoom}
          roomId={roomId}
          reply={reply}
          MessagesSetting={MessagesSetting}
          setFilesData={(data: any) => setFilesData(data)}
          setReply={(data: any) => setReply(data)}
          setMessagesSetting={(data: any) => setMessagesSetting(data)}
          FilesData={FilesData}
        />
      )}
      <img src="/images/chatBg.png" className={spacesStyles.chatBg} alt="" />
      {Loading ? (
        <Skeleton
          className={spacesStyles.messagesSkeleton}
          paragraph={{ rows: 5 }}
          active
        />
      ) : (
        <div id="roomChat" className={spacesStyles.roomChat}>
          <Image.PreviewGroup>
            {MessagesSetting.data &&
              [...MessagesSetting.data]
                .reverse()
                .map((msg: any, index: number) => (
                  <ChatMessage
                    onDelete={(id: any) => {
                      let newMessages = MessagesSetting.data;

                      let seletedIndex = MessagesSetting.data.findIndex(
                        (item: any) => item?.id === id
                      );
                      newMessages.splice(seletedIndex, 1);
                      setMessagesSetting({ ...MessagesSetting, data: newMessages });
                    }}
                    enableCheckMessage={enableCheckMessage}
                    setEnableCheckMessage={setEnableCheckMessage}
                    setSelectedMessageIds={setSelectedMessageIds}
                    toggleMessageSelection={toggleMessageSelection}
                    selectedMessageIds={selectedMessageIds}
                    user={user}
                    group={group}
                    roomId={roomId}
                    key={index}
                    message={msg}
                    setRoomId={(Id: any) => setRoomId(Id)}
                    setReply={(rep: any) => setReply(rep)}
                    setEdit={(edit: any) => {
                      setEdit(edit);
                      setMesssage(edit.message);
                    }}
                  />


                ))}
          </Image.PreviewGroup>
          <AlwaysScrollToBottom />
        </div>
      )}

      {(group &&
        user?.members
          ?.find((o: any) => o.id === User.id)
          ?.props?.find((o: any) => o.perm === 1 || o.perm === 3)?.allow ===
        1) ||
        (!group &&
          (user?.members
            ?.find((o: any) => o.id === User.id)
            ?.props?.find((o: any) => o.perm === 1)?.allow === 0 ||
            user?.members
              ?.find((o: any) => o.id === User.id)
              ?.props?.find((o: any) => o.perm === 1) === undefined)) ? (
        <>
          {user.id && (
            <div className={spacesStyles.sendMessageNotAlowed}>
              You don't have the Permission to talk in this conversation.
            </div>
          )}
        </>
      ) : (
        <div
          style={{ pointerEvents: Loading ? "none" : "initial" }}
          className={spacesStyles.sendMessageBox}
        >
          {!StartRecord && (
            <>
              <Dropdown
                overlayClassName="filesDropDown"
                menu={{ items }}
                placement="top"
              >
                <button className={spacesStyles.attachmentBtn}>
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.8031 17.3034L16.7425 18.3641C13.8135 21.293 9.06476 21.293 6.13586 18.3641C3.20692 15.4351 3.20695 10.6864 6.13585 7.75746L10.025 3.8683C11.9776 1.91568 15.1435 1.91568 17.0961 3.8683C19.0487 5.8209 19.0487 8.98675 17.0961 10.9394L13.207 14.8285C12.2307 15.8048 10.6478 15.8048 9.67146 14.8285C8.69516 13.8522 8.69516 12.2692 9.67146 11.2929L12.4999 8.46449"
                      stroke="#0D0F11"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </Dropdown>
              <div
                style={{
                  borderRadius:
                    reply.id === 0 && Edit.id === 0 ? "42px" : "16px",
                }}
                className={spacesStyles.inputCon}
              >
                {reply.id !== 0 && (
                  <div className={spacesStyles.replayBox}>
                    <button
                      onClick={() =>
                        setReply({
                          id: 0,
                          message: "",
                          sender: "",
                          is_corrected: false,
                        })
                      }
                    >
                      <img src="/images/cancel.svg" alt="" />
                    </button>
                    <h4>{reply.sender}</h4>
                    <p>{reply.message}</p>
                  </div>
                )}
                {Edit.id !== 0 && (
                  <div className={spacesStyles.replayBox}>
                    <button
                      onClick={() => {
                        setEdit({
                          id: 0,
                          message: "",
                        });
                        setMesssage("");
                      }}
                    >
                      <img src="/images/cancel.svg" alt="" />
                    </button>
                    <p>Edit Message</p>
                  </div>
                )}
                <TextareaAutosize
                  onChange={(e) => setMesssage(e.target.value)}
                  maxRows={6}
                  placeholder="Message..."
                  value={Messsage}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && Messsage?.trim() !== "") {
                      e.preventDefault();
                      Edit.id === 0 ? sendMessage() : editMessage();
                    }
                  }}
                />
                {/* <input
                    onChange={(e) => setMesssage(e.target.value)}
                    placeholder="Message..."
                    type="text"
                    value={Messsage}
                    onKeyPress={(e) => {
                      if (e.key === "Enter" && Messsage?.trim() !== "") {
                        Edit.id === 0 ? sendMessage() : editMessage();
                      }
                    }}
                  /> */}
              </div>
            </>
          )}
          {Messsage?.trim() !== "" ? (
            <button
              onClick={() => (Edit.id === 0 ? sendMessage() : editMessage())}
              className={spacesStyles.sendBtn}
            >
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.4854 15.4083L14.7268 19.0936C15.9277 20.4589 16.5282 21.1416 17.1567 20.9754C17.7852 20.8092 18.0008 19.9108 18.4318 18.1138L20.8229 8.1459C21.4868 5.37832 21.8187 3.99454 21.0808 3.312C20.343 2.62947 19.064 3.13725 16.5061 4.15282L4.63876 8.86449C2.59293 9.67674 1.57001 10.0829 1.50507 10.7808C1.49842 10.8522 1.49831 10.9241 1.50474 10.9955C1.56754 11.6937 2.58921 12.1033 4.63255 12.9223C5.55838 13.2934 6.0213 13.479 6.3532 13.8344C6.39052 13.8743 6.4264 13.9157 6.46078 13.9584C6.76658 14.3384 6.89709 14.8371 7.15808 15.8344L7.64653 17.701C7.9005 18.6715 8.02749 19.1568 8.36008 19.223C8.69267 19.2891 8.98225 18.8867 9.5614 18.0819L11.4854 15.4083ZM11.4854 15.4083L11.1676 15.0771C10.8059 14.7001 10.6251 14.5117 10.6251 14.2775C10.6251 14.0433 10.8059 13.8548 11.1676 13.4778L14.7406 9.75409"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          ) : (
            <>
              {StartRecord ? (
                <>
                  <div className={spacesStyles.recordCon}>
                    <button
                      onClick={() => {
                        setStartRecord(false);
                        clearCanvas();
                      }}
                    >
                      <img src="/images/delete-02.png" alt="" />
                    </button>
                    <VoiceVisualizer
                      ref={audioRef}
                      controls={recorderControls}
                      height={40}
                      width={150}
                      secondaryBarColor={"#C7C7CC"}
                      mainBarColor={"#BF4034"}
                    />
                  </div>
                  <button
                    // disabled={recordedBlob === null}
                    onClick={() => {
                      stopRecording();

                      // if (recordedBlob === null) {
                      //   stopRecording();
                      //   setTimeout(() => {
                      //     addAudioElement(recordedBlob);
                      //     setStartRecord(false);
                      //     clearCanvas();
                      //   }, 1000);
                      // } else {
                      //   addAudioElement(recordedBlob);
                      //   setStartRecord(false);
                      //   clearCanvas();
                      // }
                    }}
                    className={spacesStyles.sendBtn}
                  >
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.4854 15.4083L14.7268 19.0936C15.9277 20.4589 16.5282 21.1416 17.1567 20.9754C17.7852 20.8092 18.0008 19.9108 18.4318 18.1138L20.8229 8.1459C21.4868 5.37832 21.8187 3.99454 21.0808 3.312C20.343 2.62947 19.064 3.13725 16.5061 4.15282L4.63876 8.86449C2.59293 9.67674 1.57001 10.0829 1.50507 10.7808C1.49842 10.8522 1.49831 10.9241 1.50474 10.9955C1.56754 11.6937 2.58921 12.1033 4.63255 12.9223C5.55838 13.2934 6.0213 13.479 6.3532 13.8344C6.39052 13.8743 6.4264 13.9157 6.46078 13.9584C6.76658 14.3384 6.89709 14.8371 7.15808 15.8344L7.64653 17.701C7.9005 18.6715 8.02749 19.1568 8.36008 19.223C8.69267 19.2891 8.98225 18.8867 9.5614 18.0819L11.4854 15.4083ZM11.4854 15.4083L11.1676 15.0771C10.8059 14.7001 10.6251 14.5117 10.6251 14.2775C10.6251 14.0433 10.8059 13.8548 11.1676 13.4778L14.7406 9.75409"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </>
              ) : (
                <button
                  onClick={() => {
                    setStartRecord(true);
                    startRecording();
                  }}
                  className={spacesStyles.recordBtn}
                >
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 7V11C17.5 13.7614 15.2614 16 12.5 16C9.73858 16 7.5 13.7614 7.5 11V7C7.5 4.23858 9.73858 2 12.5 2C15.2614 2 17.5 4.23858 17.5 7Z"
                      stroke="#1C1C1E"
                      stroke-width="1.5"
                    />
                    <path
                      d="M17.5 7H14.5M17.5 11H14.5"
                      stroke="#1C1C1E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M20.5 11C20.5 15.4183 16.9183 19 12.5 19M12.5 19C8.08172 19 4.5 15.4183 4.5 11M12.5 19V22M12.5 22H15.5M12.5 22H9.5"
                      stroke="#1C1C1E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </button>
              )}
            </>
          )}
        </div>
      )}
      {contact &&
        !(
          ContactRoomDetails?.members
            ?.find((o: any) => o.id === user?.contact?.id)
            ?.props?.find((o: any) => o.perm === 1)?.allow === 0 ||
          ContactRoomDetails?.members
            ?.find((o: any) => o.id === user?.contact?.id)
            ?.props?.find((o: any) => o.perm === 1) === undefined
        ) && (
          <div
            style={{ pointerEvents: Loading ? "none" : "initial" }}
            className={spacesStyles.sendMessageBox}
          >
            {!StartRecord && (
              <>
                <Dropdown
                  overlayClassName="filesDropDown"
                  menu={{ items }}
                  placement="top"
                >
                  <button className={spacesStyles.attachmentBtn}>
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.8031 17.3034L16.7425 18.3641C13.8135 21.293 9.06476 21.293 6.13586 18.3641C3.20692 15.4351 3.20695 10.6864 6.13585 7.75746L10.025 3.8683C11.9776 1.91568 15.1435 1.91568 17.0961 3.8683C19.0487 5.8209 19.0487 8.98675 17.0961 10.9394L13.207 14.8285C12.2307 15.8048 10.6478 15.8048 9.67146 14.8285C8.69516 13.8522 8.69516 12.2692 9.67146 11.2929L12.4999 8.46449"
                        stroke="#0D0F11"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </Dropdown>
                <div
                  style={{
                    borderRadius:
                      reply.id === 0 && Edit.id === 0 ? "42px" : "16px",
                  }}
                  className={spacesStyles.inputCon}
                >
                  {reply.id !== 0 && (
                    <div className={spacesStyles.replayBox}>
                      <button
                        onClick={() =>
                          setReply({
                            id: 0,
                            message: "",
                            sender: "",
                            is_corrected: false,
                          })
                        }
                      >
                        <img src="/images/cancel.svg" alt="" />
                      </button>
                      <h4>{reply.sender}</h4>
                      <p>{reply.message}</p>
                    </div>
                  )}
                  {Edit.id !== 0 && (
                    <div className={spacesStyles.replayBox}>
                      <button
                        onClick={() => {
                          setEdit({
                            id: 0,
                            message: "",
                          });
                          setMesssage("");
                        }}
                      >
                        <img src="/images/cancel.svg" alt="" />
                      </button>
                      <p>Edit Message</p>
                    </div>
                  )}
                  <TextareaAutosize
                    onChange={(e) => setMesssage(e.target.value)}
                    maxRows={6}
                    placeholder="Message..."
                    value={Messsage}
                    onKeyPress={(e) => {
                      if (e.key === "Enter" && Messsage?.trim() !== "") {
                        e.preventDefault();
                        Edit.id === 0 ? sendMessage() : editMessage();
                      }
                    }}
                  />
                  {/* <input
                      onChange={(e) => setMesssage(e.target.value)}
                      placeholder="Message..."
                      type="text"
                      value={Messsage}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          Edit.id === 0 ? sendMessage() : editMessage();
                        }
                      }}
                    /> */}
                </div>
              </>
            )}
            {Messsage !== "" ? (
              <button
                onClick={() => (Edit.id === 0 ? sendMessage() : editMessage())}
                className={spacesStyles.sendBtn}
              >
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.4854 15.4083L14.7268 19.0936C15.9277 20.4589 16.5282 21.1416 17.1567 20.9754C17.7852 20.8092 18.0008 19.9108 18.4318 18.1138L20.8229 8.1459C21.4868 5.37832 21.8187 3.99454 21.0808 3.312C20.343 2.62947 19.064 3.13725 16.5061 4.15282L4.63876 8.86449C2.59293 9.67674 1.57001 10.0829 1.50507 10.7808C1.49842 10.8522 1.49831 10.9241 1.50474 10.9955C1.56754 11.6937 2.58921 12.1033 4.63255 12.9223C5.55838 13.2934 6.0213 13.479 6.3532 13.8344C6.39052 13.8743 6.4264 13.9157 6.46078 13.9584C6.76658 14.3384 6.89709 14.8371 7.15808 15.8344L7.64653 17.701C7.9005 18.6715 8.02749 19.1568 8.36008 19.223C8.69267 19.2891 8.98225 18.8867 9.5614 18.0819L11.4854 15.4083ZM11.4854 15.4083L11.1676 15.0771C10.8059 14.7001 10.6251 14.5117 10.6251 14.2775C10.6251 14.0433 10.8059 13.8548 11.1676 13.4778L14.7406 9.75409"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            ) : (
              <>
                {StartRecord ? (
                  <>
                    <div className={spacesStyles.recordCon}>
                      <button
                        onClick={() => {
                          setStartRecord(false);
                          clearCanvas();
                        }}
                      >
                        <img src="/images/delete-02.png" alt="" />
                      </button>
                      <VoiceVisualizer
                        ref={audioRef}
                        controls={recorderControls}
                        height={40}
                        width={150}
                        secondaryBarColor={"#C7C7CC"}
                        mainBarColor={"#BF4034"}
                      />
                    </div>
                    <button
                      // disabled={recordedBlob === null}
                      onClick={() => {
                        if (recordedBlob === null) {
                          stopRecording();
                          setTimeout(() => {
                            addAudioElement(recordedBlob);
                            setStartRecord(false);
                            clearCanvas();
                          }, 2000);
                        } else {
                          addAudioElement(recordedBlob);
                          setStartRecord(false);
                          clearCanvas();
                        }
                      }}
                      className={spacesStyles.sendBtn}
                    >
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.4854 15.4083L14.7268 19.0936C15.9277 20.4589 16.5282 21.1416 17.1567 20.9754C17.7852 20.8092 18.0008 19.9108 18.4318 18.1138L20.8229 8.1459C21.4868 5.37832 21.8187 3.99454 21.0808 3.312C20.343 2.62947 19.064 3.13725 16.5061 4.15282L4.63876 8.86449C2.59293 9.67674 1.57001 10.0829 1.50507 10.7808C1.49842 10.8522 1.49831 10.9241 1.50474 10.9955C1.56754 11.6937 2.58921 12.1033 4.63255 12.9223C5.55838 13.2934 6.0213 13.479 6.3532 13.8344C6.39052 13.8743 6.4264 13.9157 6.46078 13.9584C6.76658 14.3384 6.89709 14.8371 7.15808 15.8344L7.64653 17.701C7.9005 18.6715 8.02749 19.1568 8.36008 19.223C8.69267 19.2891 8.98225 18.8867 9.5614 18.0819L11.4854 15.4083ZM11.4854 15.4083L11.1676 15.0771C10.8059 14.7001 10.6251 14.5117 10.6251 14.2775C10.6251 14.0433 10.8059 13.8548 11.1676 13.4778L14.7406 9.75409"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </>
                ) : (
                  <button
                    onClick={() => {
                      setStartRecord(true);
                      startRecording();
                    }}
                    className={spacesStyles.recordBtn}
                  >
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5 7V11C17.5 13.7614 15.2614 16 12.5 16C9.73858 16 7.5 13.7614 7.5 11V7C7.5 4.23858 9.73858 2 12.5 2C15.2614 2 17.5 4.23858 17.5 7Z"
                        stroke="#1C1C1E"
                        stroke-width="1.5"
                      />
                      <path
                        d="M17.5 7H14.5M17.5 11H14.5"
                        stroke="#1C1C1E"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                      <path
                        d="M20.5 11C20.5 15.4183 16.9183 19 12.5 19M12.5 19C8.08172 19 4.5 15.4183 4.5 11M12.5 19V22M12.5 22H15.5M12.5 22H9.5"
                        stroke="#1C1C1E"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                  </button>
                )}
              </>
            )}
          </div>
        )}
    </div>
  );
};

export default RoomChat;
