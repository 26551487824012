import profileStyles from "../../styles/pages/profile.module.scss";
import type { UploadProps } from "antd";
import { message, Modal, Upload } from "antd";

import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Avatar } from "antd";

import { Button, Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { DatePicker, Space } from "antd";

import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

import { CopyOutlined } from "@ant-design/icons";
import axios from "axios";
import { FetchUser, EditUser } from "../../services/profile";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { REACT_APP_API_BASE_URL, showOldLog } from "../../ConfigVar";

type FieldType = {
  first_name?: string;
  last_name?: string;
  password?: string;
  password_confirmation?: string;
  username?: string;
  phone?: number;
  name?: string;
  dateOfBirth?: string;
  number?: string;
  company?: string;
  notes?: string;
  image?: File;
  twoFA?: string;
  inputRef?: React.Ref<any>;
  userRole?: string;
  userID?: string;
};

enum userRole {
  owner = 1,
  su = 2,
  admin = 3,
  staff = 4,
  client = 5,
}
const Users: React.FC = () => {
  const history = useNavigate();
  const [form] = Form.useForm();

  const UserList = ["U", "Lucy", "Tom", "Edward"];
  const ColorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];
  const GapList = [4, 3, 2, 1];
  const [Image, setImage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [PasswordLoading, setPasswordLoading] = useState<boolean>(false);
  const [user, setUser] = useState(UserList[0]);
  const [color, setColor] = useState(ColorList[0]);
  const [gap, setGap] = useState(GapList[0]);

  const [phone, setPhone] = useState("");
  const inputRef = useRef<any>();

  const [UserLoc, setUserLoc] = useState<any>({});
  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUserLoc(t);
  }, [window.localStorage.getItem("user")]);


  const canUpdate = (role: any) => {
    switch (role) {
      case "client":
      case "staff":
        return false

      default:
        return true
    }
  }

  const [canUpdateProfile, setCanUpdateProfile] = useState(false)
  useEffect(() => {
    setCanUpdateProfile(canUpdate(UserLoc?.role))
  }, [UserLoc])

  console.log("UserLoc", UserLoc);


  const setLocalUser = (user: any) => {
    if (showOldLog) {
      console.log("setLocalUser", user);
    }
    window.localStorage.setItem("user", JSON.stringify(user));
  };

  const { data, status, isLoading, refetch } = useQuery(
    "Users",
    async () => FetchUser(),
    {
      cacheTime: 0,
      staleTime: 10000,
    }
  );

  const handleCopy = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand("copy");
    }
    if (showOldLog) {
      console.log("sds");
    }
  };

  const props: UploadProps = {
    name: "file",
    action: `${REACT_APP_API_BASE_URL}file`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: {
      type: "1",
    },
    maxCount: 1,
    onChange(info) {
      if (info.file.status !== "uploading") {
        if (showOldLog) {
          console.log(info.file, info.fileList);
        }
      }
      if (info.file.status === "done") {
        axios
          .post(
            `${REACT_APP_API_BASE_URL}user/update`,
            {
              image: info.file.response.json.hash,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            message.success(`file uploaded successfully`);
            if (res?.data?.json?.profile_image?.url) {
              setImage(res?.data?.json?.profile_image?.url);
              if (showOldLog) {
                console.log(Image, "here what u r looking for");
              }
            }
          });
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  const submitUser = (e: any) => {
    if (showOldLog) {
      console.log(e, "here");
    }
    setLoading(true);
    const submitData = {
      bio: e.notes ? e.notes : data?.json?.bio,
      company: e.company ? e.company : data?.json?.company,
      date_of_birth: e.dateOfBirth
        ? new Date(
          new Date(e.dateOfBirth).setDate(
            new Date(e.dateOfBirth).getDate() + 1
          )
        )
          .toISOString()
          .split("T")[0]
        : data?.json?.date_of_birth,
      first_name: e.first_name ? e.first_name : data?.json?.first_name,
      last_name: e.last_name ? e.last_name : data?.json?.last_name,
      phone: e.phone ? e.phone : data?.json?.phone,
      // role: e.userRole ? userRole[e.userRole] : userRole[data?.json?.role],
      totp: e.twoFA ? e.twoFA : data?.json?.totp,
      username: e.username ? e.username : data?.json?.username,
    };

    const submitDataNoPassword = {
      bio: e.notes ? e.notes : data?.json?.bio,
      company: e.company ? e.company : data?.json?.company,
      date_of_birth: e.dateOfBirth
        ? new Date(
          new Date(e.dateOfBirth).setDate(
            new Date(e.dateOfBirth).getDate() + 1
          )
        )
          .toISOString()
          .split("T")[0]
        : data?.json?.date_of_birth,
      first_name: e.first_name ? e.first_name : data?.json?.first_name,
      last_name: e.last_name ? e.last_name : data?.json?.last_name,
      phone: e.phone ? e.phone : data?.json?.phone,
      // role: e.userRole ? userRole[e.userRole] : userRole[data?.json?.role],
      totp: e.twoFA ? e.twoFA : data?.json?.totp,
      username: e.username ? e.username : data?.json?.username,
    };

    if (e.password || e.password_confirmation) {
      EditUser(submitData)
        .then((res: any) => {
          setLoading(false);
          setLocalUser(res.json);
          message.success(`data updated successfully`);
          history("/spaces");
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      EditUser(submitDataNoPassword)
        .then((res: any) => {
          setLoading(false);
          setLocalUser(res.json);
          message.success(`data updated successfully`);
          history("/spaces");
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (status === "success") {
      if (data?.json?.profile_image?.url) {
        localStorage.setItem("user", JSON.stringify(data?.json));
        setImage(data?.json?.profile_image?.url);
      }
    }

    if (showOldLog) {
      console.log(user, "image");
    }
  }, [status, data]);

  return (
    <>
      {status === "success" && (
        <div className={profileStyles.profile__form__container}>
          <Form
            onFinish={(e) => submitUser(e)}
            form={form}
            name="horizontal_login"
            layout="vertical"
            className={profileStyles.profile__form}
          >
            <div className={profileStyles.account__picture}>
              <div>
                {Image ? (
                  <Avatar
                    src={Image}
                    style={{ backgroundColor: color, verticalAlign: "middle" }}
                    gap={gap}
                    size={64}
                  >
                    {data?.json?.username.slice(0, 2)}
                  </Avatar>
                ) : (
                  <Avatar
                    style={{ backgroundColor: color, verticalAlign: "middle" }}
                    gap={gap}
                    size={64}
                  >
                    {data?.json?.username.slice(0, 2)}
                  </Avatar>
                )}

                <h3>Choose profile picture</h3>
              </div>

              <div>
                <Upload 
                disabled={!canUpdateProfile}
                accept="image/*" {...props}>
                  <Button
                    icon={
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.09502 10C3.03241 10.457 3 10.9245 3 11.4C3 16.7019 7.02944 21 12 21C16.9706 21 21 16.7019 21 11.4C21 10.9245 20.9676 10.457 20.905 10"
                          stroke="url(#paint0_linear_2735_8592)"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        />
                        <path
                          d="M12.0001 3V13M12.0001 3C11.2999 3 9.99159 4.9943 9.50006 5.5M12.0001 3C12.7003 3 14.0086 4.9943 14.5001 5.5"
                          stroke="url(#paint1_linear_2735_8592)"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_2735_8592"
                            x1="21.81"
                            y1="9.23"
                            x2="11.2783"
                            y2="27.7058"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#BF4034" />
                            <stop offset="1" stop-color="#9B1724" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_2735_8592"
                            x1="14.7251"
                            y1="2.3"
                            x2="5.45876"
                            y2="7.26707"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#BF4034" />
                            <stop offset="1" stop-color="#9B1724" />
                          </linearGradient>
                        </defs>
                      </svg>
                    }
                  >
                    Click to Upload
                  </Button>
                </Upload>
              </div>
            </div>

            <Form.Item<FieldType>
              name="username"
              label="User name"
              className={profileStyles.input__account}
            // rules={[{ required: true, message: "Please input your username!" }]}
            >
              <Input
                autoComplete="false"
                placeholder="Username"
                defaultValue={data?.json?.username}
                disabled
              />
            </Form.Item>

            <Form.Item<FieldType>
              name="first_name"
              label="First name"
              className={profileStyles.input__account}
              initialValue={data?.json?.first_name}
              rules={[
                { required: true, message: "Please input your first name!" },
              ]}
            >
              <Input
                            disabled={!canUpdateProfile}
              autoComplete="false" placeholder="First" />
            </Form.Item>

            <Form.Item<FieldType>
              name="last_name"
              label="Last name"
              className={profileStyles.input__account}
              // rules={[
              //   { required: true, message: "Please input your last name!" },
              // ]}
              initialValue={data?.json?.last_name}
            >
              <Input 
                            disabled={!canUpdateProfile}
              autoComplete="false" placeholder="Last" />
            </Form.Item>

            <Form.Item<FieldType>
              name="phone"
              label="Phone number"
              className={profileStyles.input__phone}
            // rules={[
            //   { required: true, message: "Please input your last name!" },
            // ]}
            >
              <div>
                <PhoneInput
                  defaultCountry="ae"
                  value={data?.json?.phone ?? undefined}
                  onChange={(phone) => setPhone(phone)}
                  disabled={!canUpdateProfile}

                />
              </div>
            </Form.Item>
            {UserLoc?.role === "owner" && (
              <Form.Item<FieldType>
                name="userID"
                label="User id"
                className={profileStyles.input__account}
              // rules={[{ required: true, message: "Please input your username!" }]}
              >
                <Input
                  defaultValue={data?.json?.id}
                  autoComplete="false"
                  disabled
                />
              </Form.Item>
            )}

            <Form.Item<FieldType>
              name="userRole"
              label="User role"
              className={profileStyles.input__account}
            // rules={[{ required: true, message: "Please input your username!" }]}
            >
              <Input
                placeholder="role"
                defaultValue={
                  data?.json?.role === "staff"
                    ? "internal"
                    : data?.json?.role === "client"
                      ? "external"
                      : data?.json?.role === "su"
                        ? "Super admin"
                        : data?.json?.role
                }
                autoComplete="false"
                disabled
              />
            </Form.Item>

            <Form.Item<FieldType>
              name="company"
              label="Company"
              className={profileStyles.input__account}
              // rules={[{ required: true, message: "Please input your username!" }]}
              initialValue={data?.json?.company}
            >
              <Input
                placeholder="Company"
                autoComplete="false"
                disabled={UserLoc?.role !== "owner"}
              />
            </Form.Item>
            {UserLoc?.role === "owner" && (
              <>
                <Form.Item<FieldType>
                  name="twoFA"
                  label="2FA"
                  className={profileStyles.input__account}
                >
                  <Input
                    disabled
                    autoComplete="false"
                    ref={inputRef}
                    prefix={
                      <Button
                        onClick={handleCopy}
                        icon={<CopyOutlined className="site-form-item-icon" />}
                      ></Button>
                    }
                    defaultValue={data?.json?.totp}
                  />
                </Form.Item>

                <Form.Item<FieldType>
                  name="notes"
                  label="Notes"
                  className={profileStyles.input__account}
                  initialValue={data?.json?.bio}
                >
                  <TextArea placeholder="Notes"
                  disabled={!canUpdateProfile}
                  autoComplete="false" />
                </Form.Item>
              </>
            )}

            <Form.Item<FieldType>
              name="dateOfBirth"
              label="Date of birth"
              // rules={[
              //   { required: true, message: "Please input your date of birth!" },
              // ]}
              initialValue={
                data?.json?.date_of_birth
                  ? dayjs(data?.json?.date_of_birth, "YYYY-MM-DD")
                  : ""
              }
            >
              <DatePicker
                className={profileStyles.input__date__account}
                autoComplete="false"
                placeholder="Date"
                disabled={!canUpdateProfile}

              />
            </Form.Item>

            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                gap: 10,
              }}
            >
              <Button
                type="primary"
                htmlType="button"
                className={`${profileStyles.reset__button} main__btn`}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenModal(true);
                }}
              >
                Reset Password
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                className={`${profileStyles.profile__button} main__btn`}
              >
                Continue
              </Button>
            </div>
          </Form>
        </div>
      )}
      <Modal
        className={profileStyles.chnagePasswordModal}
        open={openModal}
        // afterClose={() => setSearchMessage("")}
        onCancel={() => setOpenModal(false)}
        width={"40%"}
        footer={[]}
        centered
        destroyOnClose
      >
        <div style={{ padding: "7vh 0 5vh" }}>
          <Form
            layout="vertical"
            onFinish={(e) => {
              setPasswordLoading(true);
              EditUser(e)
                .then((res: any) => {
                  setPasswordLoading(false);
                  message.success(`Password updated successfully`);
                  setOpenModal(false);
                })
                .catch(() => {
                  setPasswordLoading(false);
                });
            }}
          >
            <Form.Item<FieldType>
              label="Password"
              name="password"
              // rules={[{ required: true, message: "Please input your password!" }]}
              className={profileStyles.input__account}
            >
              <Input.Password
                type="password"
                placeholder="***************"
                autoComplete="new-password"
              />
            </Form.Item>

            <Form.Item<FieldType>
              name="password_confirmation"
              label="Password confirmation"
              // rules={[{ required: true, message: "Please input your password!" }]}
              className={profileStyles.input__account}
            >
              <Input.Password
                type="password"
                placeholder="***************"
                autoComplete="new-password"
              />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={PasswordLoading}
              className={`${profileStyles.profile__button} main__btn`}
              style={{ marginTop: 20, marginLeft: "auto" }}
            >
              Submit
            </Button>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default Users;
