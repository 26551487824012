import { Checkbox, Divider, Modal, Steps, notification } from "antd";
import profileStyles from "../../styles/pages/profile.module.scss";

import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { message, Upload } from "antd";

import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Avatar } from "antd";

import { UserOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import type { DatePickerProps } from "antd";
import { DatePicker, Space } from "antd";

import type { InputNumberProps } from "antd";

import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

import { Flex, Radio } from "antd";

import { CopyOutlined } from "@ant-design/icons";
import axios from "axios";
import { FetchUser, EditUser } from "../../services/profile";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import { PostUser } from "../../services/users";

// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import type { StepsProps } from "antd";
import { List } from "antd";

import type { RadioChangeEvent } from "antd";
import { FetchContacts } from "../../services/contacts";
import PermissionsList from "./permissionsList";
import usersStyles from "../../styles/pages/profile.module.scss";
import { REACT_APP_API_BASE_URL, showOldLog } from "../../ConfigVar";

type FieldType = {
  first_name?: string;
  last_name?: string;
  password?: string;
  password_confirmation?: string;
  username?: string;
  phone?: number;
  name?: string;
  dateOfBirth?: string;
  number?: string;
  company?: string;
  notes?: string;
  image?: File;
  twoFA?: string;
  inputRef?: React.Ref<any>;
  userRole?: string;
  userID?: string;
  search?: string;
};
const CreateNewUser = ({ onCancel, refetchMain }: any) => {
  const [value, setValue] = useState(1);
  const [CheckList, setCheckList] = useState([]);
  const [CanEdit, setCanEdit] = useState(0);
  // const [CheckList, setCheckList] = useState(
  //   checkedUsers.map(function (obj: any) {
  //     return obj?.contact.username;
  //   })
  // );

  const [form] = Form.useForm();

  const UserList = ["U", "Lucy", "Tom", "Edward"];
  const ColorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];
  const GapList = [4, 3, 2, 1];
  const [Image, setImage] = useState<string>("");
  const [ImageHash, setImageHash] = useState<string>("");

  const [user, setUser] = useState(UserList[0]);
  const [NewUser, setNewUser] = useState<any>({});
  const [color, setColor] = useState(ColorList[0]);
  const [gap, setGap] = useState(GapList[0]);
  const [Search, setSearch] = useState("");
  const [PreviewImage, setPreviewImage] = useState("");

  const [phone, setPhone] = useState("");
  const inputRef = useRef<any>();

  //   In order to make the steps work

  const [current, setCurrent] = useState(0);

  const [loading, setLoading] = useState<boolean>(false);
  const [ImageUrl, setImageUrl] = useState<any>();

  // In order to make the radio button works

  const onChangeRadio = (e: RadioChangeEvent) => {
    if (showOldLog) {
      console.log("radio checked", e.target.value);
    }
    setValue(e.target.value);
  };

  const onChange = (value: number) => {
    if (showOldLog) {
      console.log("onChange:", value);
    }
    setCurrent(value);
  };

  const [UserLoc, setUserLoc] = useState<any>({});
  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUserLoc(t);
  }, [window.localStorage.getItem("user")]);

  const handleCopy = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand("copy");
    }
    if (showOldLog) {
      console.log("sds");
    }
  };
  const getBase64 = (img: any, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };
  const props: UploadProps = {
    name: "file",
    action: `${REACT_APP_API_BASE_URL}file`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: {
      type: "1",
    },
    maxCount: 1,
    onChange(info) {
      if (info.file.status !== "uploading") {
        if (showOldLog) {
          console.log(info.file, info.fileList);
        }
      }
      if (info.file.status === "done") {
        setImageHash(info.file.response.json.hash);
        getBase64(info.file.originFileObj as any, (url) => {
          setImageUrl(url);
        });
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };
  const swiperRef = useRef<any>(null);

  const submitUser = (e: any) => {
    if (showOldLog) {
      console.log(e, "here");
    }
    setLoading(true);
    const submitData = {
      bio: e.notes,
      company: e.company,
      date_of_birth:
        e.dateOfBirth &&
        new Date(
          new Date(e.dateOfBirth).setDate(new Date(e.dateOfBirth).getDate() + 1)
        )
          .toISOString()
          .split("T")[0],
      first_name: e.first_name,
      last_name: e.last_name,
      phone: e.phone,
      role: e.userRole,
      username: e.username,
      password: e.password,
      password_confirmation: e.password_confirmation,
      image: ImageHash && ImageHash,
    };

    PostUser(submitData)
      .then((res: any) => {
        setLoading(false);
        // message.success(`data updated successfully`);
        if (showOldLog) {
          console.log("add user", res?.json);
        }
        setNewUser(res?.json);
        if (res?.json?.profile_image?.url) {
          setImage(res?.json?.profile_image?.url);
        } else {
          setImage("");
        }
        setImageHash("");
        refetchMain();
        swiperRef?.current?.slideNext();
        window.scrollTo(0, 0);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {}, [Image, setImage]);
  const { data, status, isLoading, refetch } = useQuery(
    ["Contacts"],
    async () => FetchContacts(0),
    {
      cacheTime: 0,
      staleTime: 10000,
    }
  );

  const UserAddted = () => {
    const createModal = Modal.info({
      className: "addUserSuccesfuly",
      title: "",
      width: "40%",
      centered: false,
      content: (
        <div className="userAddedCon">
          <img src="/images/userAdd.svg" alt="" />
          <h3>New user created successfully</h3>
          <p>Security Token</p>
          <h1>{NewUser?.totp}</h1>
          <button
            onClick={() => {
              navigator.clipboard.writeText(NewUser?.totp);
              notification.open({
                message: "text copied to clipboard successfully",
              });
            }}
          >
            Copy token <img src="/images/copy-01.svg" alt="" />
          </button>
        </div>
      ),
      footer: [],
      icon: <></>,
      autoFocusButton: null,
      closeIcon: (
        <>
          <CloseOutlined color="#fff" />
        </>
      ),
      maskClosable: true,
      closable: true,
      afterClose() {
        onCancel();
      },
    });
  };

  const OpenPermissionPopUp = (user: any) => {
    if (CanEdit === 0) {
      FetchContacts(user?.contact?.id)
        .then((currentRes) => {
          FetchContacts(NewUser?.id)
            .then((newRes) => {
              setCanEdit(user?.contact?.username);
              let otherUser = currentRes.json?.find(
                (o: any) => o.contact.username === user.contact.username
              )
                ? currentRes.json?.find(
                    (o: any) => o.contact.username === user.contact.username
                  )
                : user;
              let newobjectUser = newRes?.json?.find(
                (n: any) => n?.contact?.username === NewUser.username
              )
                ? newRes?.json?.find(
                    (n: any) => n?.contact?.username === NewUser.username
                  )
                : { contact: NewUser };
              if (showOldLog) {
                console.log(NewUser);
              }
              Modal.info({
                className: "MyContactList",
                autoFocusButton: null,
                title: (
                  <div className="modalheader">
                    <div className="modalTitle">
                      {otherUser?.contact?.full_name}'s permissions
                    </div>
                  </div>
                ),
                width: "40%",
                content: (
                  <PermissionsList
                    refetch={() => refetch()}
                    currentUser={newobjectUser}
                    otherUser={otherUser}
                  />
                ),
                centered: false,
                closeIcon: (
                  <>
                    <CloseOutlined color="#fff" />
                  </>
                ),
                maskClosable: true,
                closable: true,
                footer: [],
              });
              setCanEdit(0);
            })
            .catch(() => {
              setCanEdit(0);
            });
        })
        .catch(() => {
          setCanEdit(0);
        });
    }
  };

  return (
    <>
      <div className={usersStyles.pageHeader}>
        <button onClick={() => onCancel()}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 5L5 19M5 5L19 19"
              stroke="#0D0F11"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        Create new user
      </div>
      <Swiper
        onSwiper={(swiper: any) => {
          swiperRef.current = swiper;
        }}
        pagination={{
          enabled: false,
        }}
        navigation={{
          enabled: false,
        }}
        allowTouchMove={false}
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        className="mySwiper"
        style={{
          overlay: "unset",
          height: "unset",
        }}
      >
        <SwiperSlide>
          <div className={profileStyles.profile__form__container}>
            <Form
              onFinish={(e) => submitUser(e)}
              form={form}
              name="horizontal_login"
              layout="vertical"
              scrollToFirstError={{ behavior: "smooth" }}
              className={profileStyles.profile__form}
            >
              <div className={profileStyles.steps__container}>
                <Steps
                  current={current}
                  onChange={onChange}
                  className={profileStyles.steps}
                  items={[
                    {
                      title: "User info",
                      description: "Configuring info for the new user.",
                    },
                    {
                      title: "Whitelist",
                      description:
                        "Select their whitelist and set permissions.",
                    },
                  ]}
                />
              </div>
              <div className={profileStyles.account__picture}>
                <div>
                  {ImageUrl ? (
                    <Avatar
                      src={ImageUrl}
                      style={{ verticalAlign: "middle" }}
                      gap={gap}
                      size={64}
                    >
                      
                    </Avatar>
                  ) : (
                    <Avatar
                      style={{
                        verticalAlign: "middle",
                      }}
                      gap={gap}
                      size={64}
                    >
                      IMG
                    </Avatar>
                  )}

                  <h3>Choose profile picture</h3>
                </div>

                <div>
                  <Upload accept="image/*" {...props}>
                    <Button
                      icon={
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.09502 10C3.03241 10.457 3 10.9245 3 11.4C3 16.7019 7.02944 21 12 21C16.9706 21 21 16.7019 21 11.4C21 10.9245 20.9676 10.457 20.905 10"
                            stroke="url(#paint0_linear_2735_8592)"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M12.0001 3V13M12.0001 3C11.2999 3 9.99159 4.9943 9.50006 5.5M12.0001 3C12.7003 3 14.0086 4.9943 14.5001 5.5"
                            stroke="url(#paint1_linear_2735_8592)"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_2735_8592"
                              x1="21.81"
                              y1="9.23"
                              x2="11.2783"
                              y2="27.7058"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#BF4034" />
                              <stop offset="1" stop-color="#9B1724" />
                            </linearGradient>
                            <linearGradient
                              id="paint1_linear_2735_8592"
                              x1="14.7251"
                              y1="2.3"
                              x2="5.45876"
                              y2="7.26707"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#BF4034" />
                              <stop offset="1" stop-color="#9B1724" />
                            </linearGradient>
                          </defs>
                        </svg>
                      }
                    >
                      Click to Upload
                    </Button>
                  </Upload>
                </div>
              </div>
              <Form.Item
                rules={[
                  { required: true, message: "Please input the user role!" },
                ]}
                name="userRole"
                label="User role"
              >
                <Radio.Group className={profileStyles.select__container}>
                  {UserLoc?.role === "owner" && (
                    <Radio.Button value="3">Super admin</Radio.Button>
                  )}
                  {(UserLoc?.role === "su" || UserLoc?.role === "owner") && (
                    <Radio.Button value="4">Admin</Radio.Button>
                  )}
                  <Radio.Button value="5">Internal</Radio.Button>
                  <Radio.Button value="6">External</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item<FieldType>
                name="username"
                label="Username"
                className={profileStyles.input__account}
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <Input autoComplete="false" placeholder="Username" />
              </Form.Item>
              <Form.Item<FieldType>
                name="first_name"
                label="First name"
                className={profileStyles.input__account}
                rules={[
                  { required: true, message: "Please input your first name!" },
                ]}
              >
                <Input autoComplete="false" placeholder="First" />
              </Form.Item>
              <Form.Item<FieldType>
                name="last_name"
                label="Last"
                className={profileStyles.input__account}
                // rules={[
                //   { required: true, message: "Please input your last name!" },
                // ]}
              >
                <Input autoComplete="false" placeholder="Last" />
              </Form.Item>
              <Form.Item<FieldType>
                name="phone"
                label="Phone number"
                className={profileStyles.input__phone}
                // rules={[
                //   { required: true, message: "Please input your last name!" },
                // ]}
              >
                <div>
                  <PhoneInput
                    defaultCountry="ae"
                    onChange={(phone) => setPhone(phone)}
                  />
                </div>
              </Form.Item>

              <Form.Item<FieldType>
                name="company"
                label="Company"
                className={profileStyles.input__account}
                // rules={[{ required: true, message: "Please input your username!" }]}
              >
                <Input
                  placeholder="Company"
                  autoComplete="false"
                  disabled={UserLoc?.role !== "owner"}
                />
              </Form.Item>

              <Form.Item<FieldType>
                name="notes"
                label="Notes"
                className={profileStyles.input__account}
              >
                <TextArea placeholder="Notes" autoComplete="false" />
              </Form.Item>
              <Form.Item<FieldType>
                name="dateOfBirth"
                label="Date of birth"
                // rules={[
                //   { required: true, message: "Please input your date of birth!" },
                // ]}
              >
                <DatePicker
                  className={profileStyles.input__date__account}
                  autoComplete="false"
                  placeholder="Date"
                />
              </Form.Item>
              <Form.Item<FieldType>
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
                className={profileStyles.input__account}
              >
                <Input.Password
                  type="password"
                  placeholder="***************"
                  autoComplete="new-password"
                />
              </Form.Item>
              <Form.Item<FieldType>
                name="password_confirmation"
                label="Password confirmation"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
                className={profileStyles.input__account}
              >
                <Input.Password
                  type="password"
                  placeholder="***************"
                  autoComplete="new-password"
                />
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                className={`${profileStyles.profile__button} main__btn`}
              >
                Continue
              </Button>
            </Form>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={profileStyles.profile__form__container}>
            <Form
              onFinish={(e) => submitUser(e)}
              form={form}
              name="horizontal_login"
              layout="vertical"
              className={profileStyles.profile__form}
            >
              <div className={profileStyles.steps__container}>
                <Steps
                  current={1}
                  onChange={onChange}
                  className={profileStyles.steps}
                  items={[
                    {
                      title: "User info",
                      description: "Configuring info for the new user.",
                    },
                    {
                      title: "Whitelist",
                      description:
                        "Select their whitelist and set permissions.",
                    },
                  ]}
                />
              </div>

              <div className={profileStyles.contact__container}>
                <Form.Item<FieldType>
                  name="search"
                  className={profileStyles.search__step__two}
                >
                  <Input
                    onChange={(e) => setSearch(e.target.value)}
                    prefix={
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.7422 17.5L22.2422 22"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M20.2422 11C20.2422 6.02944 16.2128 2 11.2422 2C6.27163 2 2.24219 6.02944 2.24219 11C2.24219 15.9706 6.27163 20 11.2422 20C16.2128 20 20.2422 15.9706 20.2422 11Z"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linejoin="round"
                        />
                      </svg>
                    }
                    placeholder="Search"
                  />
                </Form.Item>

                <div>
                  <Form.Item>
                    <Checkbox.Group
                      onChange={(e: any) => setCheckList(e)}
                      defaultValue={CheckList}
                      style={{ width: "90%", margin: "0 auto" }}
                    >
                      {data?.json
                        ?.filter((contactUser: any) =>
                          contactUser?.contact?.username
                            .toLowerCase()
                            .includes(Search.toLowerCase())
                        )
                        .map((user: any) => (
                          <div className={profileStyles.perm__container}>
                            <div className={profileStyles.part__1}>
                              <Avatar
                                src={user?.contact?.profile_image?.url}
                                size={44}
                              ></Avatar>
                              <div className={profileStyles.name}>
                                {user?.contact?.full_name}
                              </div>
                            </div>

                            <div className={profileStyles.part__2}>
                              {user?.contact?.username &&
                                CheckList.includes(
                                  user?.contact?.username as never
                                ) && (
                                  <Button
                                    onClick={(e) => OpenPermissionPopUp(user)}
                                    className="editButton"
                                    loading={
                                      CanEdit === user?.contact?.username
                                    }
                                  >
                                    set permissions
                                    <svg
                                      width="21"
                                      height="22"
                                      viewBox="0 0 21 22"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.99707 16.0334C9.51839 14.3944 12.3316 14.3173 13.8693 16.0334M12.5611 11C12.5611 11.9267 11.8298 12.6778 10.9276 12.6778C10.0255 12.6778 9.29416 11.9267 9.29416 11C9.29416 10.0734 10.0255 9.32227 10.9276 9.32227C11.8298 9.32227 12.5611 10.0734 12.5611 11Z"
                                        stroke="#3A3A3C"
                                        stroke-width="1.25833"
                                        stroke-linecap="round"
                                      />
                                      <path
                                        d="M8.83606 4.29102C6.61771 4.29929 5.43102 4.37685 4.6627 5.10474C3.80273 5.91944 3.80273 7.23069 3.80273 9.85322V13.8269C3.80273 16.4494 3.80273 17.7606 4.6627 18.5753C5.52266 19.39 6.90676 19.39 9.67495 19.39H12.1916C14.9598 19.39 16.3439 19.39 17.2039 18.5753C18.0638 17.7606 18.0638 16.4494 18.0638 13.8269V9.85322C18.0638 7.23069 18.0638 5.91944 17.2039 5.10474C16.4355 4.37685 15.2489 4.29929 13.0305 4.29102"
                                        stroke="#3A3A3C"
                                        stroke-width="1.25833"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M9.06425 3.98008C9.14473 3.6321 9.18498 3.45812 9.26226 3.31603C9.44237 2.98502 9.77448 2.74176 10.1709 2.6505C10.3412 2.61133 10.5385 2.61133 10.9332 2.61133C11.3278 2.61133 11.5252 2.61133 11.6954 2.6505C12.0918 2.74176 12.4239 2.98501 12.6041 3.31603C12.6813 3.45812 12.7216 3.6321 12.802 3.98008L12.8719 4.28219C13.0147 4.89934 13.0861 5.20792 12.9784 5.44529C12.9087 5.59903 12.7898 5.73066 12.6369 5.82352C12.4009 5.96688 12.0509 5.96688 11.351 5.96688H10.5153C9.81542 5.96688 9.46544 5.96688 9.22938 5.82352C9.0765 5.73066 8.95766 5.59903 8.88792 5.44529C8.78024 5.20792 8.85161 4.89934 8.99437 4.28219L9.06425 3.98008Z"
                                        stroke="#3A3A3C"
                                        stroke-width="1.25833"
                                      />
                                    </svg>
                                  </Button>
                                )}

                              <Checkbox
                                value={user?.contact?.username}
                              ></Checkbox>
                            </div>
                          </div>
                        ))}
                    </Checkbox.Group>
                  </Form.Item>
                </div>
              </div>

              <Button
                type="primary"
                htmlType="button"
                onClick={(e) => {
                  e.preventDefault();
                  UserAddted();
                }}
                className={`${profileStyles.profile__button} main__btn`}
              >
                Continue
              </Button>
            </Form>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default CreateNewUser;
