import { Button, Input, Modal, notification } from "antd";
import React, { useState } from "react";

import AddSpace from "../addSpace";
import { CloseOutlined } from "@ant-design/icons";
import { DeleteSpace } from "../../../services/spaces";
import { FetchGroups } from "../../../services/groups";

const { Search } = Input;

const ManageSpaces = ({ Spaces, Groups, onFinish, setSpace }: any) => {
  const [SearchText, setSearch] = useState("");
  const [Loading, setLoading] = useState(false);
  const [OpenLoading, setOpenLoading] = useState(false);

  const OpenEditSpace = (space: any) => {
    if(!OpenLoading){
      setOpenLoading(true);
      FetchGroups(1, space.id, "").then((res)=>{
        const createModal = Modal.info({
          className: "addspaceModal",
          autoFocusButton: null,
          title: (
            <div
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "space-between",
              }}
            >
              {"Edit " + space.name + " space "}
            </div>
          ),
          width: "40%",
          centered: true,
          content: (
            <AddSpace
              space={space}
              selectedGroups={res?.json}
              Groups={Groups}
              onFinish={() => {
                console.log("FinishUpdate");
                
                onFinish();
                createModal.destroy();
              }}
              edit
            />
          ),
          icon: <></>,
          footer: [],
          closeIcon: (
            <>
              <CloseOutlined color="#fff" />
            </>
          ),
          maskClosable: true,
          closable: true,
        });
        setOpenLoading(false)
      })
    }
  };
  const DeleteSpaceHandle = (space: any) => {
    Modal.confirm({
      className: "addspaceModal",
      autoFocusButton: null,
      title: "Delete space",
      content: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img src="/images/deleteImg.png" alt="" />
          <div style={{ fontWeight: "bold" }}>
            Are you sure you want to delete your space
          </div>
          <div>Your group in this space will stay in unlisted Groups</div>
        </div>
      ),
      width: "30%",
      centered: true,
      closeIcon: (
        <>
          <CloseOutlined color="#fff" />
        </>
      ),
      closable: true,
      icon: <></>,
      okButtonProps: {
        loading: Loading,
        style: { width: "48%" },
        danger: true,
      },
      okText: "Delete space",
      cancelText: "No, back",
      cancelButtonProps: { style: { width: "48%" } },
      onOk() {
        setLoading(true);
        DeleteSpace(space.id)
          .then((res: any) => {
            notification.open({
              type: "success",
              message: "Space " + space.name + " Deleted successfully",
            });
            setSpace(0);
            Modal.destroyAll();
          })
          .catch(() => {
            setLoading(false);
          });
      },
    });
  };
  return (
    <div className="spaceCreateForm">
      <div className="chatUsers">
        <div className="searchCon">
          <Search
            placeholder="Search..."
            onChange={(e: any) => e.target.value === "" && setSearch("")}
            onSearch={(e: any) => setSearch(e)}
            prefix={
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.7422 17.5L22.2422 22"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M20.2422 11C20.2422 6.02944 16.2128 2 11.2422 2C6.27163 2 2.24219 6.02944 2.24219 11C2.24219 15.9706 6.27163 20 11.2422 20C16.2128 20 20.2422 15.9706 20.2422 11Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linejoin="round"
                />
              </svg>
            }
          />
        </div>
        {Spaces?.json
          ?.filter((space: any) =>
            space.name.toLowerCase().match(SearchText.toLowerCase())
          )
          ?.map((space: any) => (
            <div className={"userCard"}>
              <div className="icon">
                <img src={space.icon.url} alt="" height={30} />
              </div>
              <div className="info">
                <div className="name">{space.name}</div>
                <div className="count">{space.groups_count} Groups</div>
              </div>
              <div className="actions">
                <Button
                  className="editBtn"
                  onClick={(e) => OpenEditSpace(space)}
                >
                  Edit
                  <img src="/images/edit.svg" alt="" />
                </Button>
                <Button
                  className="delBtn"
                  danger
                  onClick={(e) => DeleteSpaceHandle(space)}
                >
                  Delete
                  <img src="/images/delete.svg" alt="" />
                </Button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ManageSpaces;
