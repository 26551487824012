import { AxiosRequestConfig } from "axios";
import ApiService from "../../utils/api/api-service";
import ApiResponse, { isError } from "../../utils/api/models/api-response";
import { ApiErrorNotification } from "../../utils/ui/notificationService";
import { REACT_APP_API_BASE_URL } from "../../ConfigVar";

class CallsService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: REACT_APP_API_BASE_URL,
      ...config,
    });
  }

  public async Fetch(
    page: number,
    userID: string,
    typeCall: string
  ): Promise<ApiResponse<any>> {
    return this.get(
      `/chat/call/all?perPage=300&page=${page}${
        userID !== "" ? "&user_ids[0]=" + userID : ""
      }${typeCall !== "" ? "&is_video=" + typeCall : ""}`
    );
  }

  public async Start(data: any): Promise<ApiResponse<any>> {
    return this.post(`/chat/call/make`, data);
  }

  public async Answer(data: any): Promise<ApiResponse<any>> {
    return this.post(`/chat/call/answer`, data);
  }

  public async Reject(id: any): Promise<ApiResponse<any>> {
    return this.delete(`/chat/call/reject?id=${id}`);
  }

  public async Info(id: any): Promise<ApiResponse<any>> {
    return this.get(`/chat/call/show?id=${id}`);
  }
}

const callsService = new CallsService();

export const FetchCalls = async (
  page: number,
  userID: string,
  typeCall: string
) => {
  const result: any = await callsService.Fetch(page, userID, typeCall);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
       window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};

export const FetchCallsInfo = async (callInfo: string | number) => {
  const result: any = await callsService.Info(callInfo);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
       window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};

export const StartCall = async (data: any) => {
  const result: any = await callsService.Start(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
       window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};

export const AnswerCall = async (data: any) => {
  const result: any = await callsService.Answer(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
       window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};

export const RejectCall = async (id: any) => {
  const result: any = await callsService.Reject(id);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
       window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
