import React, { useEffect, useRef, useState } from "react";
import profileStyles from "../../../styles/pages/profile.module.scss";
import {
  Avatar,
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Switch,
  Upload,
  UploadProps,
  message,
  notification,
} from "antd";
import { PhoneInput } from "react-international-phone";
import { CloseOutlined, CopyOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { PostUser, UpdateUser } from "../../../services/users";
import { REACT_APP_API_BASE_URL, showOldLog } from "../../../ConfigVar";

type FieldType = {
  first_name?: string;
  last_name?: string;
  password?: string;
  password_confirmation?: string;
  username?: string;
  phone?: number;
  name?: string;
  dateOfBirth?: string;
  number?: string;
  company?: string;
  notes?: string;
  image?: File;
  twoFA?: string;
  inputRef?: React.Ref<any>;
  userRole?: string;
  userID?: string;
  search?: string;
};
const { TextArea } = Input;

const EditUser = ({ user, refetch }: any) => {
  const [Image, setImage] = useState<string>("");
  const [Loading, setLoading] = useState<boolean>(false);
  const [SusLoading, setSusLoading] = useState<boolean>(false);
  const [SuspendSwitch, setSuspendSwitch] = useState<boolean>(false);
  const [ImageHash, setImageHash] = useState<string>(
    user?.contact?.profile_image?.url
  );
  const [phone, setPhone] = useState(user?.contact?.phone);
  const GapList = [4, 3, 2, 1];
  const [gap, setGap] = useState(GapList[0]);
  const inputRef = useRef<any>();
  const [ImageUrl, setImageUrl] = useState<any>();
  const [UserLoc, setUserLoc] = useState<any>({});
  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUserLoc(t);
  }, [window.localStorage.getItem("user")]);

  if (showOldLog) {
    console.log("user?.contact", user?.contact);
  }
  const getBase64 = (img: any, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };
  const handleCopy = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand("copy");
    }
    if (showOldLog) {
      console.log("sds");
    }
  };
  const props: UploadProps = {
    name: "file",
    action: `${REACT_APP_API_BASE_URL}file`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: {
      type: "1",
    },
    maxCount: 1,
    onChange(info) {
      if (info.file.status !== "uploading") {
        if (showOldLog) {
          console.log(info.file, info.fileList);
        }
      }
      if (info.file.status === "done") {
        setImageHash(info.file.response.json.hash);
        getBase64(info.file.originFileObj as any, (url) => {
          setImageUrl(url);
        });
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };
  const submitUser = (e: any) => {
    setLoading(true);
    if (showOldLog) {
      console.log(e, "here");
    }
    const submitData = {
      id: user?.contact?.id,
      bio: e.notes,
      company: e.company,
      date_of_birth:
        e.dateOfBirth &&
        new Date(
          new Date(e.dateOfBirth).setDate(new Date(e.dateOfBirth).getDate() + 1)
        )
          .toISOString()
          .split("T")[0],
      first_name: e.first_name,
      last_name: e.last_name,
      phone: e.phone,
      role: e.userRole,
      username: e.username,
      password: e.password,
      password_confirmation: e.password_confirmation,
      image: ImageHash.indexOf("http") == 0 ? undefined : ImageHash,
    };

    UpdateUser(submitData)
      .then((res: any) => {
        // message.success(`data updated successfully`);
        // setNewUser(res?.json);
        if (res?.json?.profile_image?.url) {
          setImage(res?.json?.profile_image?.url);
        } else {
          setImage("");
        }
        setImageHash("");
        setLoading(false);
        refetch();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const [form] = Form.useForm();
  if (showOldLog) {
    console.log("user", user);
  }
  useEffect(() => {
    if (user?.contact?.is_blocked) setSuspendSwitch(true);
    else setSuspendSwitch(false);
  }, [user?.contact]);
  const changeSuspendSwitch = (e: boolean) => {
    if (e) {
      Modal.confirm({
        className: "addspaceModal",
        autoFocusButton: null,
        title: "Suspend user",
        content: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {/* <img src="/images/deleteImg.png" alt="" /> */}
            <div style={{ fontWeight: "bold" }}>
              Are you sure you want to Suspend the user
            </div>
          </div>
        ),
        width: "30%",
        centered: true,
        closeIcon: (
          <>
            <CloseOutlined color="#fff" />
          </>
        ),
        closable: true,
        icon: <></>,
        okButtonProps: {
          loading: SusLoading,
          style: { width: "48%", height: "auto" },
          danger: true,
        },
        okText: "Suspend",
        cancelText: "No, back",
        cancelButtonProps: { style: { width: "48%" } },
        onOk() {
          setSusLoading(true);
          UpdateUser({ is_blocked: true,id: user?.contact?.id, })
            .then((res: any) => {
              setSuspendSwitch(true);
              notification.open({
                type: "success",
                message: "User Suspend successfully",
              });

              Modal.destroyAll();
            })
            .catch(() => {
              setSusLoading(false);
            });
        },
      });
    }else{
      Modal.confirm({
        className: "addspaceModal",
        autoFocusButton: null,
        title: "Unsuspend user",
        content: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {/* <img src="/images/deleteImg.png" alt="" /> */}
            <div style={{ fontWeight: "bold" }}>
              Are you sure you want to Unsuspend the user
            </div>
          </div>
        ),
        width: "30%",
        centered: true,
        closeIcon: (
          <>
            <CloseOutlined color="#fff" />
          </>
        ),
        closable: true,
        icon: <></>,
        okButtonProps: {
          loading: SusLoading,
          style: { width: "48%", height: "auto" },
          danger: true,
        },
        okText: "Unsuspend",
        cancelText: "No, back",
        cancelButtonProps: { style: { width: "48%" } },
        onOk() {
          setSusLoading(true);
          UpdateUser({ is_blocked: false,id: user?.contact?.id, })
            .then((res: any) => {
              setSuspendSwitch(false);
              notification.open({
                type: "success",
                message: "User Unsuspend successfully",
              });

              Modal.destroyAll();
            })
            .catch(() => {
              setSusLoading(true);
            });
        },
      });
    }
  };
  return (
    <div className={profileStyles.profile__form__container}>
      <Form
        onFinish={(e) => submitUser(e)}
        form={form}
        name="horizontal_login"
        layout="vertical"
        className={profileStyles.profile__form}
        initialValues={{ ...user?.contact }}
      >
        <div className={profileStyles.account__picture}>
          <div>
            {ImageUrl ? (
              <Avatar
                src={ImageUrl}
                style={{ verticalAlign: "middle" }}
                gap={gap}
                size={64}
              >
                u
              </Avatar>
            ) : Image ? (
              <Avatar
                src={Image}
                style={{ verticalAlign: "middle" }}
                gap={gap}
                size={64}
              >
                u
              </Avatar>
            ) : (
              <Avatar
                style={{
                  verticalAlign: "middle",
                }}
                src={ImageHash}
                gap={gap}
                size={64}
              >
                IMG
              </Avatar>
            )}

            <h3>Choose profile picture</h3>
          </div>

          <div>
            <Upload accept="image/*" {...props}>
              <Button
                icon={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.09502 10C3.03241 10.457 3 10.9245 3 11.4C3 16.7019 7.02944 21 12 21C16.9706 21 21 16.7019 21 11.4C21 10.9245 20.9676 10.457 20.905 10"
                      stroke="url(#paint0_linear_2735_8592)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M12.0001 3V13M12.0001 3C11.2999 3 9.99159 4.9943 9.50006 5.5M12.0001 3C12.7003 3 14.0086 4.9943 14.5001 5.5"
                      stroke="url(#paint1_linear_2735_8592)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2735_8592"
                        x1="21.81"
                        y1="9.23"
                        x2="11.2783"
                        y2="27.7058"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#BF4034" />
                        <stop offset="1" stop-color="#9B1724" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_2735_8592"
                        x1="14.7251"
                        y1="2.3"
                        x2="5.45876"
                        y2="7.26707"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#BF4034" />
                        <stop offset="1" stop-color="#9B1724" />
                      </linearGradient>
                    </defs>
                  </svg>
                }
              >
                Click to Upload
              </Button>
            </Upload>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <span
            style={{ marginInlineEnd: 15, fontWeight: 600, color: "#9b1724" }}
          >
            Suspend
          </span>
          <Switch
            value={SuspendSwitch}
            onChange={(e) => changeSuspendSwitch(e)}
          />
        </div>

        <Form.Item name="userRole" label="User role">
          <Radio.Group
            style={{ pointerEvents: "none" }}
            className={profileStyles.select__container}
          >
            <Radio.Button checked value="3">
              {user?.contact?.role === "staff"
                ? "internal"
                : user?.contact?.role === "client"
                ? "external"
                : user?.contact?.role === "su"
                ? "Super admin"
                : user?.contact?.role === "op"
                ? "Owner"
                : user?.contact?.role}
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item<FieldType>
          name="username"
          label="User name"
          className={profileStyles.input__account}
          // rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input autoComplete="false" placeholder="username" />
        </Form.Item>
        <Form.Item<FieldType>
          name="first_name"
          label="First name"
          className={profileStyles.input__account}
          // rules={[
          //   { required: true, message: "Please input your first name!" },
          // ]}
        >
          <Input autoComplete="false" placeholder="first" />
        </Form.Item>
        <Form.Item<FieldType>
          name="last_name"
          label="Last name"
          className={profileStyles.input__account}
          // rules={[
          //   { required: true, message: "Please input your last name!" },
          // ]}
        >
          <Input autoComplete="false" placeholder="last" />
        </Form.Item>
        <Form.Item<FieldType>
          name="phone"
          label="Phone number"
          className={profileStyles.input__phone}
          // rules={[
          //   { required: true, message: "Please input your last name!" },
          // ]}
        >
          <div>
            <PhoneInput
              defaultCountry="ae"
              value={user?.contact?.phone ?? undefined}
              onChange={(phone) => setPhone(phone)}
            />
          </div>
        </Form.Item>

        {UserLoc?.role === "owner" && (
          <>
            <Form.Item<FieldType>
              name="userID"
              label="User id"
              className={profileStyles.input__account}
              // rules={[{ required: true, message: "Please input your username!" }]}
            >
              <Input
                defaultValue={user?.contact?.id}
                autoComplete="false"
                disabled
              />
            </Form.Item>
            <Form.Item<FieldType>
              name="twoFA"
              label="2FA"
              className={profileStyles.input__account}
            >
              <Input
                disabled
                autoComplete="false"
                ref={inputRef}
                prefix={
                  <Button
                    onClick={handleCopy}
                    icon={<CopyOutlined className="site-form-item-icon" />}
                  ></Button>
                }
                defaultValue={user?.contact?.totp}
              />
            </Form.Item>
          </>
        )}
        <Form.Item<FieldType>
          name="company"
          label="Company"
          className={profileStyles.input__account}
          // rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input
            placeholder="company"
            autoComplete="false"
            disabled={UserLoc?.role !== "owner"}
          />
        </Form.Item>
        {UserLoc?.role === "owner" && (
          <Form.Item<FieldType>
            name="notes"
            label="Notes"
            className={profileStyles.input__account}
            initialValue={user?.contact?.bio}
          >
            <TextArea placeholder="Notes" autoComplete="false" />
          </Form.Item>
        )}
        <Form.Item<FieldType>
          name="dateOfBirth"
          label="Date of birth"
          // rules={[
          //   { required: true, message: "Please input your date of birth!" },
          // ]}
          initialValue={
            user?.contact?.date_of_birth
              ? dayjs(user?.contact?.date_of_birth, "YYYY-MM-DD")
              : ""
          }
        >
          <DatePicker
            placeholder="Date"
            className={profileStyles.input__date__account}
            autoComplete="false"
          />
        </Form.Item>
        <Form.Item<FieldType>
          label="Password"
          name="password"
          // rules={[{ required: true, message: "Please input your password!" }]}
          className={profileStyles.input__account}
        >
          <Input.Password
            type="password"
            placeholder="***************"
            autoComplete="new-password"
          />
        </Form.Item>
        <Form.Item<FieldType>
          name="password_confirmation"
          label="Password confirmation"
          // rules={[{ required: true, message: "Please input your password!" }]}
          className={profileStyles.input__account}
        >
          <Input.Password
            type="password"
            placeholder="***************"
            autoComplete="new-password"
          />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className={`${profileStyles.profile__button} main__btn`}
          loading={Loading}
        >
          Continue
        </Button>
      </Form>
    </div>
  );
};

export default EditUser;
