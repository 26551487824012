import React, { useEffect, useState } from "react";
import callsStyles from "../../styles/pages/call.module.scss";
import { Skeleton } from "antd";
import ChatRooms from "../room/chatRooms";
import { useNavigate } from "react-router-dom";
import UserCompList from "./UserCompList";
const UsersCon = ({ data, Loading, setLoading, setUser }: any) => {
  const history = useNavigate();

  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);
  return (
    <div className={callsStyles.groups}>
      <div className={callsStyles.groupsHeader}>
        <h5 className={callsStyles.title}>Calls</h5>
      </div>
      {Loading ? (
        <div className={callsStyles.groupsSkeleton}>
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
        </div>
      ) : (
        <>
          {data?.length > 0 ? (
            <UserCompList
              groups
              data={data}
              setUser={(data: any) => {
                setUser(data);
              }}
            />
          ) : (
            data?.length === 0 && <div className={callsStyles.emptypage}></div>
          )}
        </>
      )}
    </div>
  );
};

export default UsersCon;
