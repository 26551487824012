import React, { useEffect, useState } from "react";
import spacesStyles from "../../../styles/components/forward.module.scss";
import {
  Button,
  Checkbox,
  Collapse,
  CollapseProps,
  Modal,
  Skeleton,
  Tabs,
  Tag,
} from "antd";
import forwardStyles from "./styles.module.scss";
import { ForwordMessage } from "../../../services/chats";

const ForwardUsers = ({
  message,
  userData,
  spaceData,
  onSubmit,
  groupsData,
  messagesArray
}: any) => {
  const [CheckList, setCheckList] = useState<{
    users: Array<{ name: string; id: string }>;
    spaces: { [key: string]: Array<{ name: string; id: string }> };
  }>({
    users: [],
    spaces: {},
  });
  useEffect(() => {
    console.log(CheckList);
  }, [CheckList]);
  const [space, setSpace] = useState<any>([]);
  const [items, setItems] = useState<CollapseProps["items"]>([]);
  const [Loading, setLoading] = useState<any>(false);
  const Users = () => (
    <Checkbox.Group
      style={{ width: "100%" }}
      value={CheckList.users?.map((s) => [s.id, s.name].join(","))}
      onChange={(e) => {
        setCheckList((prev) => ({
          ...prev,
          users: [
            ...e.map((value) => ({
              id: value.split(",")[0],
              name: value.split(",")[1],
            })),
          ],
        }));
      }}
    >
      {userData &&
        userData.map((user: any, index: number) => (
          <Checkbox
            value={`${[user?.id, user?.name].join(",")}`}
            style={{ width: "100%" }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                marginBlock: "5px",
              }}
            >
              <img
                src={user?.icon?.url}
                style={{
                  width: "40px",
                  height: "40px",
                  objectFit: "cover",
                  borderRadius: "50%",
                  marginInline: "10px",
                }}
                alt=""
              />
              <div className={spacesStyles.titleCon}>
                <h4>{user?.name}</h4>
              </div>
            </div>
          </Checkbox>
        ))}
    </Checkbox.Group>
  );

  useEffect(() => {
    if (spaceData && groupsData) {
      const newArr: CollapseProps["items"] = [
        {
          key: -1,
          label: (
            <div className={forwardStyles.label}>
              <svg
                width="44"
                height="44"
                viewBox="0 0 44 44"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="44" height="44" rx="22" fill="#7070E0" />
                <path
                  d="M21.1667 16.5833H29.5"
                  stroke="white"
                  stroke-width="1.25"
                  stroke-linecap="round"
                />
                <path
                  d="M16.5 26.0771C17.3889 26.6724 17.8333 26.9701 17.8333 27.4166C17.8333 27.8631 17.3889 28.1608 16.5 28.7561C15.6111 29.3514 15.1667 29.649 14.8333 29.4258C14.5 29.2026 14.5 28.6073 14.5 27.4166C14.5 26.2259 14.5 25.6306 14.8333 25.4073C15.1667 25.1842 15.6111 25.4818 16.5 26.0771Z"
                  stroke="white"
                  stroke-width="1.25"
                  stroke-linecap="round"
                />
                <path
                  d="M16.5 15.2439C17.3889 15.8392 17.8333 16.1368 17.8333 16.5833C17.8333 17.0298 17.3889 17.3275 16.5 17.9228C15.6111 18.5181 15.1667 18.8158 14.8333 18.5925C14.5 18.3693 14.5 17.774 14.5 16.5833C14.5 15.3927 14.5 14.7974 14.8333 14.5741C15.1667 14.3509 15.6111 14.6485 16.5 15.2439Z"
                  stroke="white"
                  stroke-width="1.25"
                  stroke-linecap="round"
                />
                <path
                  d="M21.1667 22H29.5"
                  stroke="white"
                  stroke-width="1.25"
                  stroke-linecap="round"
                />
                <path
                  d="M21.1667 27.4167H29.5"
                  stroke="white"
                  stroke-width="1.25"
                  stroke-linecap="round"
                />
              </svg>
              Unlisted Groups
            </div>
          ),
          children: (
            <div className={forwardStyles.children}>
              {groupsData
                ?.filter((group: any) => group?.space_id === null)
                ?.map((group: any) => (
                  <Checkbox
                    value={[group?.id, group?.name].join(",")}
                    style={{ display: "flex" }}
                  >
                    <div className={forwardStyles.label}>
                      <img src={group?.icon?.url} alt="" />
                      {group?.name}
                    </div>
                  </Checkbox>
                ))}
            </div>
          ),
        },
      ];
      spaceData.map((space: any) => {
        newArr.push({
          key: space?.id,
          label: (
            <div className={forwardStyles.label}>
              <img src={space?.icon?.url} alt="" />
              {space?.name}
            </div>
          ),
          children: (
            <div className={forwardStyles.children}>
              {groupsData
                ?.filter((group: any) => group?.space_id === space?.id)
                ?.map((group: any) => (
                  <Checkbox
                    value={[group?.id, group?.name].join(",")}
                    style={{ display: "flex" }}
                  >
                    <div className={forwardStyles.label}>
                      <img src={group?.icon?.url} alt="" />
                      {group?.name}
                    </div>
                  </Checkbox>
                ))}
            </div>
          ),
        });
      });
      setItems([...newArr]);
    }
  }, [spaceData, groupsData]);

  const Spaces = () => (
    <div className={spacesStyles.titleCon}>
      {items && items?.length > 0 ? (
        <Checkbox.Group
          style={{ width: "100%" }}
          value={CheckList.spaces[space]?.map((s) => [s.id, s.name].join(","))}
          onChange={(e) => {
            setCheckList((prev: any) => ({
              ...prev,
              spaces: {
                ...prev.spaces,
                [space]: e.map((value) => ({
                  id: value.split(",")[0],
                  name: value.split(",")[1],
                })),
              },
            }));
          }}
        >
          <Collapse
            items={items}
            className={forwardStyles.collapse}
            activeKey={space}
            onChange={(key) => setSpace(key)}
            style={{ flexGrow: "1" }}
            accordion
            expandIconPosition="end"
            bordered={false}
            expandIcon={({ isActive }) =>
              isActive ? (
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 9.50005C18 9.50005 13.5811 15.5 12 15.5C10.4188 15.5 6 9.5 6 9.5"
                    stroke="#1C1C1E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.00005 6.5C9.00005 6.5 15 10.9189 15 12.5C15 14.0812 9 18.5 9 18.5"
                    stroke="#1C1C1E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )
            }
          />
        </Checkbox.Group>
      ) : (
        <>
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
        </>
      )}
    </div>
  );
  const forwardSumbit = () => {
    const forwardIds: any = [...CheckList.users.map((u) => u.id)];
    for (let id in CheckList.spaces) {
      forwardIds.push(...CheckList.spaces[id].map((s) => s.id));
    }
    setLoading(true);
    if (forwardIds.length !== 0) {
      const formData: any = {};
      formData["message_ids"] = message?.id ? [message?.id] : messagesArray ;
      formData["chat_ids"] = forwardIds;
      ForwordMessage(formData)
        .then(() => {
          setLoading(false);
          onSubmit();
          Modal.destroyAll();
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  return (
    <div className={spacesStyles.forward}>
      <Tabs
        className={forwardStyles.tabs}
        defaultActiveKey="1"
        items={[
          {
            key: "1",
            label: "Spaces",
            children: <Spaces />,
          },
          {
            key: "2",
            label: "Private contacts",
            children: <Users />,
          },
        ]}
      />
      <div style={{ textAlign: "end" }}>
        <div style={{ marginBlockEnd: "10px" }}>
          {Object.keys(CheckList.spaces).map((key) =>
            CheckList.spaces[key].map((group) => <Tag>{group?.name}</Tag>)
          )}
          {CheckList.users.map((user) => (
            <Tag>{user?.name}</Tag>
          ))}
        </div>
        <Button
          type="primary"
          htmlType="submit"
          loading={Loading}
          onClick={forwardSumbit}
          className="createbtn"
        >
          Send
        </Button>
      </div>
    </div>
  );
};

export default ForwardUsers;
