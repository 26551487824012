import React, { useEffect, useState } from "react";
import profileStyles from "../../../styles/pages/profile.module.scss";
import { Avatar, Button, Checkbox, Form, Input, Modal, Steps } from "antd";
import { FetchContacts } from "../../../services/contacts";
import PermissionsList from "../permissionsList";
import { CloseOutlined } from "@ant-design/icons";
import { showOldLog } from "../../../ConfigVar";

const EditUserPermissions = ({ currentUser, setUser, data, refetch, setSearch }: any) => {
  const [CheckList, setCheckList] = useState([]);
  const [CanEdit, setCanEdit] = useState(0);

  useEffect(() => {
    if (currentUser?.id) {
      FetchContacts(currentUser?.contact?.id).then((res) => {
        setCheckList(
          res.json.map(function (obj: any) {
            return obj?.contact.username;
          })
        );
      });
    }
  }, [currentUser]);
  const OpenPermissionPopUp = (user: any) => {
    if (CanEdit === 0) {
      FetchContacts(user?.contact?.id)
        .then((currentRes) => {
          FetchContacts(currentUser?.contact?.id)
            .then((otherRes) => {
              setCanEdit(user?.contact?.username);
              let otherUser = otherRes.json?.find(
                (o: any) => o?.contact?.username === user?.contact?.username
              )
                ? otherRes.json?.find(
                    (o: any) => o?.contact?.username === user?.contact?.username
                  )
                : user;
              let currentUserNew = currentRes.json?.find(
                (o: any) => o.contact.username === currentUser.contact.username
              )
                ? currentRes.json?.find(
                    (o: any) =>
                      o.contact.username === currentUser.contact.username
                  )
                : currentUser;
              Modal.info({
                className: "MyContactList",
                autoFocusButton: null,
                title: (
                  <div className="modalheader">
                    <div className="modalTitle">
                      {otherUser?.contact?.full_name}'s permissions
                    </div>
                  </div>
                ),
                width: "40%",
                content: (
                  <PermissionsList
                    refetch={() => refetch()}
                    currentUser={currentUserNew}
                    otherUser={otherUser}
                  />
                ),
                centered: false,
                closeIcon: (
                  <>
                    <CloseOutlined color="#fff" />
                  </>
                ),
                maskClosable: true,
                closable: true,
                footer: [],
              });
              setCanEdit(0);
            })
            .catch(() => {
              setCanEdit(0);
            });
        })
        .catch(() => {
          setCanEdit(0);
        });
    }
  };
  if (showOldLog) {
    console.log("data", data);
  }
  return (
    <div className={profileStyles.editUser}>
      <div className={profileStyles.pageHeader}>
        <button onClick={() => setUser({})}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 5L5 19M5 5L19 19"
              stroke="#0D0F11"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        Edit {currentUser?.contact?.full_name}'s permissions
      </div>
      <div
        style={{ marginTop: "5vh" }}
        className={profileStyles.profile__form__container}
      >
        <Form
          //   onFinish={(e) => submitUser(e)}
          //   form={form}
          name="horizontal_login"
          layout="vertical"
          className={profileStyles.profile__form}
        >
          <div className={profileStyles.contact__container}>
            <Form.Item
              name="search"
              className={profileStyles.search__step__two}
            >
              <Input
                onChange={(e) => setSearch(e.target.value as any)}
                prefix={<img src="/images/search.png" alt="" />}
                placeholder="Search"
              />
            </Form.Item>

            <div>
              <Form.Item>
                <Checkbox.Group
                  onChange={(e: any) => setCheckList(e)}
                  value={CheckList}
                  style={{ width: "90%", margin: "0 auto" }}
                >
                  {data?.map(
                    (contactUser: any) =>
                      contactUser?.contact?.id !== currentUser?.contact?.id && (
                        <div className={profileStyles.perm__container}>
                          <div className={profileStyles.part__1}>
                            <Avatar
                              src={contactUser?.contact?.profile_image?.url}
                              size={44}
                            ></Avatar>
                            <div className={profileStyles.name}>
                              {contactUser?.contact?.full_name}
                            </div>
                          </div>

                          <div className={profileStyles.part__2}>
                            {contactUser?.contact?.username &&
                              CheckList.includes(
                                contactUser?.contact?.username as never
                              ) && (
                                <Button
                                  onClick={(e) =>
                                    OpenPermissionPopUp(contactUser)
                                  }
                                  className="editButton"
                                  loading={
                                    CanEdit === contactUser?.contact?.username
                                  }
                                >
                                  set permissions
                                  <svg
                                    width="21"
                                    height="22"
                                    viewBox="0 0 21 22"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.99707 16.0334C9.51839 14.3944 12.3316 14.3173 13.8693 16.0334M12.5611 11C12.5611 11.9267 11.8298 12.6778 10.9276 12.6778C10.0255 12.6778 9.29416 11.9267 9.29416 11C9.29416 10.0734 10.0255 9.32227 10.9276 9.32227C11.8298 9.32227 12.5611 10.0734 12.5611 11Z"
                                      stroke="#3A3A3C"
                                      stroke-width="1.25833"
                                      stroke-linecap="round"
                                    />
                                    <path
                                      d="M8.83606 4.29102C6.61771 4.29929 5.43102 4.37685 4.6627 5.10474C3.80273 5.91944 3.80273 7.23069 3.80273 9.85322V13.8269C3.80273 16.4494 3.80273 17.7606 4.6627 18.5753C5.52266 19.39 6.90676 19.39 9.67495 19.39H12.1916C14.9598 19.39 16.3439 19.39 17.2039 18.5753C18.0638 17.7606 18.0638 16.4494 18.0638 13.8269V9.85322C18.0638 7.23069 18.0638 5.91944 17.2039 5.10474C16.4355 4.37685 15.2489 4.29929 13.0305 4.29102"
                                      stroke="#3A3A3C"
                                      stroke-width="1.25833"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M9.06425 3.98008C9.14473 3.6321 9.18498 3.45812 9.26226 3.31603C9.44237 2.98502 9.77448 2.74176 10.1709 2.6505C10.3412 2.61133 10.5385 2.61133 10.9332 2.61133C11.3278 2.61133 11.5252 2.61133 11.6954 2.6505C12.0918 2.74176 12.4239 2.98501 12.6041 3.31603C12.6813 3.45812 12.7216 3.6321 12.802 3.98008L12.8719 4.28219C13.0147 4.89934 13.0861 5.20792 12.9784 5.44529C12.9087 5.59903 12.7898 5.73066 12.6369 5.82352C12.4009 5.96688 12.0509 5.96688 11.351 5.96688H10.5153C9.81542 5.96688 9.46544 5.96688 9.22938 5.82352C9.0765 5.73066 8.95766 5.59903 8.88792 5.44529C8.78024 5.20792 8.85161 4.89934 8.99437 4.28219L9.06425 3.98008Z"
                                      stroke="#3A3A3C"
                                      stroke-width="1.25833"
                                    />
                                  </svg>
                                </Button>
                              )}

                            <Checkbox
                              value={contactUser?.contact?.username}
                            ></Checkbox>
                          </div>
                        </div>
                      )
                  )}
                </Checkbox.Group>
              </Form.Item>
            </div>
          </div>
          {/* 
          <Button
            type="primary"
            htmlType="button"
            onClick={(e) => e.preventDefault()}
            className={`${profileStyles.profile__button} main__btn`}
          >
            Continue
          </Button> */}
        </Form>
      </div>
    </div>
  );
};

export default EditUserPermissions;
