import { AxiosRequestConfig } from "axios";
import ApiService from "../../utils/api/api-service";
import ApiResponse, { isError } from "../../utils/api/models/api-response";
import { ApiErrorNotification } from "../../utils/ui/notificationService";
import { REACT_APP_API_BASE_URL } from "../../ConfigVar";

class ChatsMessagesService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: REACT_APP_API_BASE_URL,
      ...config,
    });
  }

  public async Fetch(
    roomId: number,
    page: number,
    group: any,
    search: string
  ): Promise<ApiResponse<any>> {
    return this.get(
      `/chat/message/all?perPage=20&chat_id=${roomId}&page=${page}${
        search !== "" ? "&criteria=" + search : ""
      }`
    );
  }
  public async FetchMember(roomId: number): Promise<ApiResponse<any>> {
    return this.get(`/chat/group/show?id=${roomId}`);
  }
  public async Seen(roomId: number): Promise<ApiResponse<any>> {
    return this.post(`/chat/seen`, { chat_ids: [roomId] });
  }
  public async FetchMedia(roomId: number): Promise<ApiResponse<any>> {
    return this.get(`/chat/media?type[]=4&type[]=5&id=${roomId}`);
  }
  public async FetchFiles(roomId: number): Promise<ApiResponse<any>> {
    return this.get(`/chat/media?type[]=6&id=${roomId}`);
  }
  public async FetchAudio(roomId: number): Promise<ApiResponse<any>> {
    return this.get(`/chat/media?type[]=3&id=${roomId}`);
  }
  public async FetchUsers(
    page: number,
    search: string
  ): Promise<ApiResponse<any>> {
    return this.get(
      `/chat/all?chat_type=1&page=${page}${
        search !== "" ? "&criteria=" + search : ""
      }`
    );
  }
  public async PostMessage(data: any): Promise<ApiResponse<any>> {
    return this.post(`/chat/message/send`, data);
  }
  public async EditMessage(data: any): Promise<ApiResponse<any>> {
    return this.post(`/chat/message/edit`, data);
  }
  public async PinMessage(data: any): Promise<ApiResponse<any>> {
    return this.post(`/chats/messages/pin`, data);
  }
  public async ForwordMessage(data: any): Promise<ApiResponse<any>> {
    return this.post(`/chat/message/forward`, data);
  }
  public async DeleteMessage(id: any): Promise<ApiResponse<any>> {
    return this.delete(`/chat/message/delete?ids[]=${id}`);
  }
  public async GetReactions(): Promise<ApiResponse<any>> {
    return this.get(`/chat/reaction/all`);
  }
  public async AddReaction(data: any): Promise<ApiResponse<any>> {
    return this.post(`/chat/message/react`, data);
  }
  public async AddChat(data: any): Promise<ApiResponse<any>> {
    return this.post(`/chat/store`, data);
  }
}

const chatsMessagesService = new ChatsMessagesService();

const handleChatNotExistError = (result: any) => {
  if (result?.errors?.chat_id || result?.errors?.['chat_ids.0']) {
    // console.error(result.errors.chat_id?.[0] || result.errors['chat_ids.0']?.[0]);
  }
};

const handleError = (error:any) => {
  console.error("Error:", error);

};


// export const FetchMessages = async (
//   roomId: number,
//   page: number,
//   group: any,
//   search: string
// ) => {
//   const result: any = await chatsMessagesService.Fetch(
//     roomId,
//     page,
//     group,
//     search
//   );
//   if (isError(result)) {
//     // ApiErrorNotification(result);
//     if (result?.errorType === "Unauthorized") {
//       localStorage.removeItem("token");
//        window.location.href = "/login";
//     }
//     throw result;
//   } else {
//     return result;
//   }
// };
export const FetchUsersMessages = async (page: number, search: string) => {
  const result: any = await chatsMessagesService.FetchUsers(page, search);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result?.errorType === "Unauthorized") {
      localStorage.removeItem("token");
       window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
// export const FetchMember = async (RoomId: number) => {
//   const result: any = await chatsMessagesService.FetchMember(RoomId);
//   if (isError(result)) {
//     ApiErrorNotification(result);
//     if (result?.errorType === "Unauthorized") {
//       localStorage.removeItem("token");
//        window.location.href = "/login";
//     }
//     throw result;
//   } else {
//     return result;
//   }
// };


export const FetchMember = async (roomId: number) => {
  try {
    const result: any = await chatsMessagesService.FetchMember(roomId);
    if (isError(result)) {
      // ApiErrorNotification(result);

      if (result?.errorType === "Unauthorized") {
        localStorage.removeItem("token");
        window.location.href = "/login";
      } else {
        handleChatNotExistError(result);
      }

      throw result;
    } else {
      return result;
    }
  } catch (error) {
    handleError(error); // Use the updated handleError function
  }
};

export const FetchMessages = async (
  roomId: number,
  page: number,
  group: any,
  search: string
) => {
  try {
    const result: any = await chatsMessagesService.Fetch(
      roomId,
      page,
      group,
      search
    );
    if (isError(result)) {
      // ApiErrorNotification(result);

      if (result?.errorType === "Unauthorized") {
        localStorage.removeItem("token");
        window.location.href = "/login";
      } else {
        handleChatNotExistError(result); // Handle "chat does not exist" error
      }

      throw result;
    } else {
      return result;
    }
  } catch (error) {
    handleError(error);
  }
};

export const MakeSeen = async (id: number) => {
  if (id !== 0 && id !== null && id !== undefined && !isNaN(id)) {
    try {
      const result: any = await chatsMessagesService.Seen(id);
      if (isError(result)) {
        // ApiErrorNotification(result);
        if (result.errorType === "Unauthorized") {
          localStorage.removeItem("token");
          window.location.href = "/login";
        } else {
          handleChatNotExistError(result); // Handle "chat does not exist" error
        }
        
        throw result;
      } else {
        return result;
      }
    } catch (error) {
      handleError(error);
    }
  }
};


export const FetchMedia = async (RoomId: number) => {
  const result: any = await chatsMessagesService.FetchMedia(RoomId);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
       window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
export const FetchFiles = async (RoomId: number) => {
  const result: any = await chatsMessagesService.FetchFiles(RoomId);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
       window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
export const FetchAudio = async (RoomId: number) => {
  const result: any = await chatsMessagesService.FetchAudio(RoomId);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
       window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
export const SendMessages = async (data: any) => {
  const result: any = await chatsMessagesService.PostMessage(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
       window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
export const EditMessages = async (data: any) => {
  const result: any = await chatsMessagesService.EditMessage(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
       window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
export const PinMessage = async (data: any) => {
  const result: any = await chatsMessagesService.PinMessage(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
       window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
export const ForwordMessage = async (data: any) => {
  const result: any = await chatsMessagesService.ForwordMessage(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
       window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
export const AddReaction = async (data: any) => {
  const result: any = await chatsMessagesService.AddReaction(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
       window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};

export const DeleteMessage = async (id: any) => {
  if (id !== undefined && id !== null) {
    const result: any = await chatsMessagesService.DeleteMessage(id);
    if (isError(result)) {
      ApiErrorNotification(result);
      if (result.errorType === "Unauthorized") {
        localStorage.removeItem("token");
         window.location.href = "/login";
      }
      throw result;
    } else {
      return result;
    }
  }
};
export const GetReactions = async () => {
  const result: any = await chatsMessagesService.GetReactions();
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
       window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
export const AddChat = async (data: any) => {
  const result: any = await chatsMessagesService.AddChat(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
       window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
// export const MakeSeen = async (id: number) => {
//   if (id !== 0 && id !== null && id !== undefined &&!isNaN(id)) {
//     const result: any = await chatsMessagesService.Seen(id);
//     if (isError(result)) {
//       // ApiErrorNotification(result);
//       if (result.errorType === "Unauthorized") {
//         localStorage.removeItem("token");
//          window.location.href = "/login";
//       }
//       throw result;
//     } else {
//       return result;
//     }
//   }
// };
