import { AxiosRequestConfig } from "axios";
import ApiService from "../../utils/api/api-service";
import ApiResponse, { isError } from "../../utils/api/models/api-response";
import { ApiErrorNotification } from "../../utils/ui/notificationService";
import { REACT_APP_API_BASE_URL } from "../../ConfigVar";

class ProfileService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: REACT_APP_API_BASE_URL,
      ...config,
    });
  }

  public async Fetch(): Promise<ApiResponse<any>> {
    return this.get(`/user/profile`);
  }
  async add(data: any): Promise<ApiResponse<any>> {
    return this.post(`/space/store`, data);
  }
  async Edit(data: any): Promise<ApiResponse<any>> {
    return this.post(`/user/update`, data);
  }
}

const profileService = new ProfileService();

export const EditUser = async (data: any) => {
  const result = await profileService.Edit(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
      // window.location.href = "/auth/login";
    }
    throw result;
  } else {
    return result;
  }
};

export const FetchUser = async () => {
  const result: any = await profileService.Fetch();
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
      // window.location.href = "/auth/login";
    }
    throw result;
  } else {
    return result;
  }
};
