import React, { useEffect, useState } from "react";
import usersStyles from "../../styles/pages/call.module.scss";
import { Avatar, Button, Tooltip } from "antd";
import { AntDesignOutlined, UserOutlined } from "@ant-design/icons";
import VideoRoom from "./VideoRoom";
import { StartCall } from "../../services/calls";
import { showOldLog } from "../../ConfigVar";
const UserCompList = ({ data, groups, setUser, user }: any) => {
  // In order to start the call

  const [open, setOpen] = useState<boolean>(false);
  const [TOKEN, setTOKEN] = useState<string>("");
  const [CHANNEL, setCHANNEL] = useState<string>("");
  const [ID, setID] = useState<string>("");
  const [Loading, setLoading] = useState<boolean>(false);
  const [callType, setCallType] = useState<string | number>(0);

  const handleChangeOpen = (open: boolean) => {
    setOpen(open);
  };
  if (showOldLog) {
    console.log(data, "here is the data of the call ");
  }

  const [UserLoc, setUserLoc] = useState<any>({});
  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUserLoc(t);
    if (showOldLog) {
      console.log(t);
    }


  }, [window.localStorage.getItem("user")]);

  // In order to make the date works
  function formatCustomDate(inputDate: string) {
    const date = new Date(inputDate);
    const padZero = (num: any) => (num < 10 ? "0" + num : num);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const formattedDate = `${months[date.getMonth()]
      } ${date.getDate()}, ${date.getFullYear()} at ${padZero(
        date.getHours()
      )}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? "pm" : "am"}`;
    return formattedDate;
  }

  function calculateDuration(startDateString: any, endDateString: any) {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);
    const durationMs = endDate.getTime() - startDate.getTime();
    if (durationMs < 0) return "Invalid duration"; // Add this line to handle negative durations
    const hours = Math.floor(durationMs / (1000 * 60 * 60));
    const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));
    if (hours === 0 && minutes === 0) {
      return "0m";
    } else if (hours && minutes) {
      return `${hours}h ${minutes}m`;
    } else if (hours) {
      return `${hours}h`;
    } else if (minutes) {
      return `${minutes}m`;
    }
  }

  return (
    <div className={usersStyles.chatRooms}>
      {data &&
        data.map((user: any, index: number) =>
          // Handle case 1: user is the caller and the receiver is one person
          user?.caller ? (
            user?.caller?.id ===
              JSON.parse(window.localStorage.getItem("user") as string).id &&
              user?.receivers.length === 1 ? (
              <div
                className={user === user.contact?.id ? usersStyles.active : ""}
                onClick={() => {
                  setUser(user);
                }}
              >
                {user?.receivers[0]?.profile_image?.url ? (
                  <Avatar
                    size={44}
                    style={{ marginInlineEnd: 15 }}
                    src={user?.receivers[0]?.profile_image?.url}
                  ></Avatar>
                ) : (
                  <Avatar size={44} style={{ marginInlineEnd: 15 }}>
                    {user?.receivers[0]?.full_name?.slice(0, 2)}
                  </Avatar>
                )}

                <div className={usersStyles.receivers__container}>
                  <h4>{user?.receivers[0]?.full_name}</h4>

                  <div className={usersStyles.receivers__container__details}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.51842 7.96128C1.88641 6.85921 1.58124 5.95933 1.39724 5.04715C1.12509 3.69806 1.74788 2.38022 2.7796 1.53932C3.21564 1.18393 3.7155 1.30536 3.97334 1.76794L4.55546 2.81228C5.01687 3.64006 5.24757 4.05394 5.20181 4.49274C5.15606 4.93154 4.84492 5.28892 4.22266 6.00368L2.51842 7.96128ZM2.51842 7.96128C3.79768 10.1919 5.80524 12.2005 8.03841 13.4813M8.03841 13.4813C9.14048 14.1133 10.0403 14.4185 10.9525 14.6025C12.3016 14.8746 13.6195 14.2518 14.4603 13.2201C14.8157 12.7841 14.6943 12.2842 14.2317 12.0263L13.1874 11.4442C12.3596 10.9828 11.9457 10.7521 11.5069 10.7979C11.0681 10.8436 10.7107 11.1547 9.99601 11.777L8.03841 13.4813Z"
                        stroke="#34C759"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.81794 7.18239L13.3333 2.66699M8.81794 7.18239C9.15481 7.51926 10.7436 7.18926 11.2234 7.18239M8.81794 7.18239C8.48108 6.84553 8.81108 5.2567 8.81794 4.77694"
                        stroke="#34C759"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    {formatCustomDate(user?.created_at)} •{" "}
                    {calculateDuration(user?.created_at, user?.closed_at)}
                  </div>
                </div>
                <Button
                  disabled={Loading}
                  className={usersStyles.edit__user__btn}
                  onClick={() => {
                    if ([user?.receivers[0]?.id]) {
                      setLoading(true);
                      setCallType(`${user.is_video}`);
                      const formData = new FormData();

                      formData.append("is_video", `${user.is_video}`);
                      formData.append(
                        "user_ids[]",
                        `${[user?.receivers[0]?.id]}`
                      );
                      // formData.append(
                      //   "user_ids[]",
                      //   `${
                      //     JSON.parse(
                      //       window.localStorage.getItem("user") as string
                      //     ).id
                      //   }`
                      // );

                      StartCall(formData)
                        .then((res: any) => {
                          setLoading(false);
                          setTOKEN(res?.json.agora_access_token);
                          setCHANNEL(res?.json.agora_channel_name);
                          setID(res?.json.id);
                          handleChangeOpen(true);
                        })
                        .catch((err) => {
                          setLoading(false);
                        });
                    }
                  }}
                >
                  {user.is_video ? (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 11C2 7.70017 2 6.05025 3.02513 5.02513C4.05025 4 5.70017 4 9 4H10C13.2998 4 14.9497 4 15.9749 5.02513C17 6.05025 17 7.70017 17 11V13C17 16.2998 17 17.9497 15.9749 18.9749C14.9497 20 13.2998 20 10 20H9C5.70017 20 4.05025 20 3.02513 18.9749C2 17.9497 2 16.2998 2 13V11Z"
                        stroke="#3A3A3C"
                        stroke-width="1.5"
                      />
                      <path
                        d="M11 8H13"
                        stroke="#3A3A3C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                      <path
                        d="M17 8.90585L17.1259 8.80196C19.2417 7.05623 20.2996 6.18336 21.1498 6.60482C22 7.02628 22 8.42355 22 11.2181V12.7819C22 15.5765 22 16.9737 21.1498 17.3952C20.2996 17.8166 19.2417 16.9438 17.1259 15.198L17 15.0941"
                        stroke="#3A3A3C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.77762 11.9424C2.8296 10.2893 2.37185 8.93948 2.09584 7.57121C1.68762 5.54758 2.62181 3.57081 4.16938 2.30947C4.82345 1.77638 5.57323 1.95852 5.96 2.6524L6.83318 4.21891C7.52529 5.46057 7.87134 6.08139 7.8027 6.73959C7.73407 7.39779 7.26737 7.93386 6.33397 9.00601L3.77762 11.9424ZM3.77762 11.9424C5.69651 15.2883 8.70784 18.3013 12.0576 20.2224M12.0576 20.2224C13.7107 21.1704 15.0605 21.6282 16.4288 21.9042C18.4524 22.3124 20.4292 21.3782 21.6905 19.8306C22.2236 19.1766 22.0415 18.4268 21.3476 18.04L19.7811 17.1668C18.5394 16.4747 17.9186 16.1287 17.2604 16.1973C16.6022 16.2659 16.0661 16.7326 14.994 17.666L12.0576 20.2224Z"
                        stroke="#3A3A3C"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14 6.83185C15.4232 7.43624 16.5638 8.57677 17.1682 10M14.654 2C18.1912 3.02076 20.9791 5.80852 22 9.34563"
                        stroke="#3A3A3C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                  )}
                </Button>
              </div>
            ) : user?.caller?.id !==
              JSON.parse(window.localStorage.getItem("user") as string).id &&
              user?.receivers.length === 1 ? (
              <div
                className={user === user.contact?.id ? usersStyles.active : ""}
                onClick={() => {
                  setUser(user);
                }}
              >
                {user?.caller?.profile_image?.url ? (
                  <Avatar
                    size={44}
                    style={{ marginInlineEnd: 15 }}
                    src={user?.caller?.profile_image?.url}
                  >
                    {user?.caller?.full_name?.slice(0, 2)}
                  </Avatar>
                ) : (
                  <Avatar size={44} style={{ marginInlineEnd: 15 }}>
                    {user?.caller?.full_name?.slice(0, 2)}
                  </Avatar>
                )}

                <div className={usersStyles.receivers__container}>
                  <h4>{user?.caller?.full_name}</h4>

                  <div className={usersStyles.receivers__container__details}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.51842 7.96128C1.88641 6.85921 1.58124 5.95933 1.39724 5.04715C1.12509 3.69806 1.74788 2.38022 2.7796 1.53932C3.21564 1.18393 3.7155 1.30536 3.97334 1.76794L4.55546 2.81228C5.01687 3.64006 5.24757 4.05394 5.20181 4.49274C5.15606 4.93154 4.84492 5.28892 4.22266 6.00368L2.51842 7.96128ZM2.51842 7.96128C3.79768 10.1919 5.80524 12.2005 8.03841 13.4813M8.03841 13.4813C9.14048 14.1133 10.0403 14.4185 10.9525 14.6025C12.3016 14.8746 13.6195 14.2518 14.4603 13.2201C14.8157 12.7841 14.6943 12.2842 14.2317 12.0263L13.1874 11.4442C12.3596 10.9828 11.9457 10.7521 11.5069 10.7979C11.0681 10.8436 10.7107 11.1547 9.99601 11.777L8.03841 13.4813Z"
                        stroke="#FF3B30"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.33335 3.00956L9.91915 4.63929C10.5858 5.32443 10.9192 5.66699 11.3334 5.66699C11.7476 5.66699 12.0809 5.32443 12.7476 4.63929L14.6667 2.66699M10.3334 2.80503C9.89549 2.79881 8.44549 2.49759 8.13809 2.80503C7.83062 3.11247 8.13182 4.56249 8.13809 5.00033"
                        stroke="#FF3B30"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    {formatCustomDate(user?.created_at)} •{" "}
                    {calculateDuration(user?.created_at, user?.closed_at)}
                  </div>
                </div>
                <Button
                  className={usersStyles.edit__user__btn}
                  disabled={Loading}
                  onClick={() => {
                    if (showOldLog) {
                      console.log([user?.caller?.id], "here is hello");

                    }

                    if ([user?.caller?.id]) {
                      setLoading(true);
                      setCallType(`${user.is_video}`);

                      const formData = new FormData();
                      formData.append("is_video", `${user.is_video}`);
                      formData.append("user_ids[]", `${[user?.caller?.id]}`);
                      // formData.append(
                      //   "user_ids[]",
                      //   `${
                      //     JSON.parse(
                      //       window.localStorage.getItem("user") as string
                      //     ).id
                      //   }`
                      // );
                      StartCall(formData)
                        .then((res: any) => {
                          setTOKEN(res?.json.agora_access_token);
                          setCHANNEL(res?.json.agora_channel_name);
                          setID(res?.json.id);
                          setLoading(false);
                          handleChangeOpen(false);
                        })
                        .catch((err) => {
                          setLoading(false);
                        });
                    }
                  }}
                >
                  {user.is_video ? (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 11C2 7.70017 2 6.05025 3.02513 5.02513C4.05025 4 5.70017 4 9 4H10C13.2998 4 14.9497 4 15.9749 5.02513C17 6.05025 17 7.70017 17 11V13C17 16.2998 17 17.9497 15.9749 18.9749C14.9497 20 13.2998 20 10 20H9C5.70017 20 4.05025 20 3.02513 18.9749C2 17.9497 2 16.2998 2 13V11Z"
                        stroke="#3A3A3C"
                        stroke-width="1.5"
                      />
                      <path
                        d="M11 8H13"
                        stroke="#3A3A3C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                      <path
                        d="M17 8.90585L17.1259 8.80196C19.2417 7.05623 20.2996 6.18336 21.1498 6.60482C22 7.02628 22 8.42355 22 11.2181V12.7819C22 15.5765 22 16.9737 21.1498 17.3952C20.2996 17.8166 19.2417 16.9438 17.1259 15.198L17 15.0941"
                        stroke="#3A3A3C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.77762 11.9424C2.8296 10.2893 2.37185 8.93948 2.09584 7.57121C1.68762 5.54758 2.62181 3.57081 4.16938 2.30947C4.82345 1.77638 5.57323 1.95852 5.96 2.6524L6.83318 4.21891C7.52529 5.46057 7.87134 6.08139 7.8027 6.73959C7.73407 7.39779 7.26737 7.93386 6.33397 9.00601L3.77762 11.9424ZM3.77762 11.9424C5.69651 15.2883 8.70784 18.3013 12.0576 20.2224M12.0576 20.2224C13.7107 21.1704 15.0605 21.6282 16.4288 21.9042C18.4524 22.3124 20.4292 21.3782 21.6905 19.8306C22.2236 19.1766 22.0415 18.4268 21.3476 18.04L19.7811 17.1668C18.5394 16.4747 17.9186 16.1287 17.2604 16.1973C16.6022 16.2659 16.0661 16.7326 14.994 17.666L12.0576 20.2224Z"
                        stroke="#3A3A3C"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14 6.83185C15.4232 7.43624 16.5638 8.57677 17.1682 10M14.654 2C18.1912 3.02076 20.9791 5.80852 22 9.34563"
                        stroke="#3A3A3C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                  )}
                </Button>
              </div>
            ) : user?.caller?.id ===
              JSON.parse(window.localStorage.getItem("user") as string).id &&
              user?.receivers.length > 1 ? (
              <div
                className={user === user.contact?.id ? usersStyles.active : ""}
                onClick={() => {
                  setUser(user);
                }}
              >
                <Avatar.Group maxCount={2}>
                  {user?.receivers.map((user: any, index: any) =>
                    user?.profile_image?.url ? (
                      <Avatar src={user?.profile_image?.url}></Avatar>
                    ) : (
                      <Avatar>{user?.full_name?.slice(0, 2)}</Avatar>
                    )
                  )}
                </Avatar.Group>

                <div className={usersStyles.receivers__container}>
                  <h4>
                    {" "}
                    {user?.receivers
                      .slice(0, 3)
                      .map((user: any, index: any) => `${user?.full_name},`)}
                    ...
                  </h4>

                  <div className={usersStyles.receivers__container__details}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.51842 7.96128C1.88641 6.85921 1.58124 5.95933 1.39724 5.04715C1.12509 3.69806 1.74788 2.38022 2.7796 1.53932C3.21564 1.18393 3.7155 1.30536 3.97334 1.76794L4.55546 2.81228C5.01687 3.64006 5.24757 4.05394 5.20181 4.49274C5.15606 4.93154 4.84492 5.28892 4.22266 6.00368L2.51842 7.96128ZM2.51842 7.96128C3.79768 10.1919 5.80524 12.2005 8.03841 13.4813M8.03841 13.4813C9.14048 14.1133 10.0403 14.4185 10.9525 14.6025C12.3016 14.8746 13.6195 14.2518 14.4603 13.2201C14.8157 12.7841 14.6943 12.2842 14.2317 12.0263L13.1874 11.4442C12.3596 10.9828 11.9457 10.7521 11.5069 10.7979C11.0681 10.8436 10.7107 11.1547 9.99601 11.777L8.03841 13.4813Z"
                        stroke="#34C759"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.81794 7.18239L13.3333 2.66699M8.81794 7.18239C9.15481 7.51926 10.7436 7.18926 11.2234 7.18239M8.81794 7.18239C8.48108 6.84553 8.81108 5.2567 8.81794 4.77694"
                        stroke="#34C759"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    {formatCustomDate(user?.created_at)} •{" "}
                    {calculateDuration(user?.created_at, user?.closed_at)}
                  </div>
                </div>
                <Button
                  className={usersStyles.edit__user__btn}
                  disabled={Loading}
                  onClick={() => {
                    const userIds =
                      user?.receivers?.map((user: any) => user?.id) || [];

                    if (userIds) {
                      setLoading(true);
                      setCallType(`${user.is_video}`);

                      const formData = new FormData();
                      formData.append("is_video", `${user.is_video}`);
                      // Now, assuming you want to append each user ID to formData
                      userIds.forEach((userId: any) => {
                        formData.append("user_ids[]", userId);
                      });
                      // formData.append(
                      //   "user_ids[]",
                      //   `${
                      //     JSON.parse(
                      //       window.localStorage.getItem("user") as string
                      //     ).id
                      //   }`
                      // );

                      StartCall(formData)
                        .then((res: any) => {
                          setTOKEN(res?.json.agora_access_token);
                          setCHANNEL(res?.json.agora_channel_name);
                          setID(res?.json.id);
                          setLoading(false);

                          handleChangeOpen(true);
                        })
                        .catch((err) => {
                          setLoading(false);
                        });
                    }
                  }}
                >
                  {user.is_video ? (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 11C2 7.70017 2 6.05025 3.02513 5.02513C4.05025 4 5.70017 4 9 4H10C13.2998 4 14.9497 4 15.9749 5.02513C17 6.05025 17 7.70017 17 11V13C17 16.2998 17 17.9497 15.9749 18.9749C14.9497 20 13.2998 20 10 20H9C5.70017 20 4.05025 20 3.02513 18.9749C2 17.9497 2 16.2998 2 13V11Z"
                        stroke="#3A3A3C"
                        stroke-width="1.5"
                      />
                      <path
                        d="M11 8H13"
                        stroke="#3A3A3C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                      <path
                        d="M17 8.90585L17.1259 8.80196C19.2417 7.05623 20.2996 6.18336 21.1498 6.60482C22 7.02628 22 8.42355 22 11.2181V12.7819C22 15.5765 22 16.9737 21.1498 17.3952C20.2996 17.8166 19.2417 16.9438 17.1259 15.198L17 15.0941"
                        stroke="#3A3A3C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.77762 11.9424C2.8296 10.2893 2.37185 8.93948 2.09584 7.57121C1.68762 5.54758 2.62181 3.57081 4.16938 2.30947C4.82345 1.77638 5.57323 1.95852 5.96 2.6524L6.83318 4.21891C7.52529 5.46057 7.87134 6.08139 7.8027 6.73959C7.73407 7.39779 7.26737 7.93386 6.33397 9.00601L3.77762 11.9424ZM3.77762 11.9424C5.69651 15.2883 8.70784 18.3013 12.0576 20.2224M12.0576 20.2224C13.7107 21.1704 15.0605 21.6282 16.4288 21.9042C18.4524 22.3124 20.4292 21.3782 21.6905 19.8306C22.2236 19.1766 22.0415 18.4268 21.3476 18.04L19.7811 17.1668C18.5394 16.4747 17.9186 16.1287 17.2604 16.1973C16.6022 16.2659 16.0661 16.7326 14.994 17.666L12.0576 20.2224Z"
                        stroke="#3A3A3C"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14 6.83185C15.4232 7.43624 16.5638 8.57677 17.1682 10M14.654 2C18.1912 3.02076 20.9791 5.80852 22 9.34563"
                        stroke="#3A3A3C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                  )}
                </Button>
              </div>
            ) : user?.caller?.id !==
              JSON.parse(window.localStorage.getItem("user") as string).id &&
              user?.receivers.length > 1 ? (
              <div
                className={user === user.contact?.id ? usersStyles.active : ""}
                onClick={() => {
                  setUser(user);
                }}
              >
                <Avatar.Group maxCount={2}>
                  {user?.receivers.map((user: any, index: any) =>
                    user?.profile_image?.url ? (
                      <Avatar src={user?.profile_image?.url}></Avatar>
                    ) : (
                      <Avatar>{user?.full_name?.slice(0, 2)}</Avatar>
                    )
                  )}
                </Avatar.Group>

                <div className={usersStyles.receivers__container}>
                  <h4>
                    {" "}
                    {user?.receivers
                      .slice(0, 3)
                      .map((user: any, index: any) => `${user?.full_name},`)}
                    ...
                  </h4>

                  <div className={usersStyles.receivers__container__details}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.51842 7.96128C1.88641 6.85921 1.58124 5.95933 1.39724 5.04715C1.12509 3.69806 1.74788 2.38022 2.7796 1.53932C3.21564 1.18393 3.7155 1.30536 3.97334 1.76794L4.55546 2.81228C5.01687 3.64006 5.24757 4.05394 5.20181 4.49274C5.15606 4.93154 4.84492 5.28892 4.22266 6.00368L2.51842 7.96128ZM2.51842 7.96128C3.79768 10.1919 5.80524 12.2005 8.03841 13.4813M8.03841 13.4813C9.14048 14.1133 10.0403 14.4185 10.9525 14.6025C12.3016 14.8746 13.6195 14.2518 14.4603 13.2201C14.8157 12.7841 14.6943 12.2842 14.2317 12.0263L13.1874 11.4442C12.3596 10.9828 11.9457 10.7521 11.5069 10.7979C11.0681 10.8436 10.7107 11.1547 9.99601 11.777L8.03841 13.4813Z"
                        stroke="#FF3B30"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.33335 3.00956L9.91915 4.63929C10.5858 5.32443 10.9192 5.66699 11.3334 5.66699C11.7476 5.66699 12.0809 5.32443 12.7476 4.63929L14.6667 2.66699M10.3334 2.80503C9.89549 2.79881 8.44549 2.49759 8.13809 2.80503C7.83062 3.11247 8.13182 4.56249 8.13809 5.00033"
                        stroke="#FF3B30"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    {formatCustomDate(user?.created_at)} •{" "}
                    {calculateDuration(user?.created_at, user?.closed_at)}
                  </div>
                </div>
                <Button
                  className={usersStyles.edit__user__btn}
                  disabled={Loading}
                  onClick={() => {
                    const userIds = ([] =
                      user?.receivers?.map((user: any) => user?.id) || []);

                    if (userIds) {
                      setLoading(true);
                      setCallType(`${user.is_video}`);

                      const formData = new FormData();
                      formData.append("is_video", `${user.is_video}`);
                      userIds.forEach((userId: any) => {
                        formData.append("user_ids[]", userId);
                      });
                      // formData.append(
                      //   "user_ids[]",
                      //   `${
                      //     JSON.parse(
                      //       window.localStorage.getItem("user") as string
                      //     ).id
                      //   }`
                      // );

                      StartCall(formData)
                        .then((res: any) => {
                          setTOKEN(res?.json.agora_access_token);
                          setCHANNEL(res?.json.agora_channel_name);
                          setID(res?.json.id);
                          setLoading(false);

                          handleChangeOpen(true);
                        })
                        .catch((err) => {
                          setLoading(false);
                        });
                    }
                  }}
                >
                  {user.is_video ? (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 11C2 7.70017 2 6.05025 3.02513 5.02513C4.05025 4 5.70017 4 9 4H10C13.2998 4 14.9497 4 15.9749 5.02513C17 6.05025 17 7.70017 17 11V13C17 16.2998 17 17.9497 15.9749 18.9749C14.9497 20 13.2998 20 10 20H9C5.70017 20 4.05025 20 3.02513 18.9749C2 17.9497 2 16.2998 2 13V11Z"
                        stroke="#3A3A3C"
                        stroke-width="1.5"
                      />
                      <path
                        d="M11 8H13"
                        stroke="#3A3A3C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                      <path
                        d="M17 8.90585L17.1259 8.80196C19.2417 7.05623 20.2996 6.18336 21.1498 6.60482C22 7.02628 22 8.42355 22 11.2181V12.7819C22 15.5765 22 16.9737 21.1498 17.3952C20.2996 17.8166 19.2417 16.9438 17.1259 15.198L17 15.0941"
                        stroke="#3A3A3C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.77762 11.9424C2.8296 10.2893 2.37185 8.93948 2.09584 7.57121C1.68762 5.54758 2.62181 3.57081 4.16938 2.30947C4.82345 1.77638 5.57323 1.95852 5.96 2.6524L6.83318 4.21891C7.52529 5.46057 7.87134 6.08139 7.8027 6.73959C7.73407 7.39779 7.26737 7.93386 6.33397 9.00601L3.77762 11.9424ZM3.77762 11.9424C5.69651 15.2883 8.70784 18.3013 12.0576 20.2224M12.0576 20.2224C13.7107 21.1704 15.0605 21.6282 16.4288 21.9042C18.4524 22.3124 20.4292 21.3782 21.6905 19.8306C22.2236 19.1766 22.0415 18.4268 21.3476 18.04L19.7811 17.1668C18.5394 16.4747 17.9186 16.1287 17.2604 16.1973C16.6022 16.2659 16.0661 16.7326 14.994 17.666L12.0576 20.2224Z"
                        stroke="#3A3A3C"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14 6.83185C15.4232 7.43624 16.5638 8.57677 17.1682 10M14.654 2C18.1912 3.02076 20.9791 5.80852 22 9.34563"
                        stroke="#3A3A3C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                  )}
                </Button>
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )
        )}

      {open ? (
        <>
          <VideoRoom
            TOKEN={TOKEN}
            CHANNEL={CHANNEL}
            IncomingCallInfo={ID}
            isModalOpen={open}
            handleChangeOpen={handleChangeOpen}
            callType={callType}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default UserCompList;
