import { Button, Modal } from "antd";
import React, { useState } from "react";
// import {
//   DragDropContext,
//   Droppable,
//   Draggable,
//   DropResult,
//   DraggableProvided,
//   DroppableProvided,
// } from "react-beautiful-dnd";
import { DragDropContext as BeautifulDND, Droppable, Draggable, DropResult, DraggableProvided, DroppableProvided } from "@hello-pangea/dnd";

function SortingManually({ spaces, onSubmit }: any) {
  const [sortedItems, setSortedItems] = useState(spaces);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const copiedItems = Array.from(sortedItems);
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);

    setSortedItems(copiedItems);
  };

  return (
    <div className="spaceCreateForm">
      {/* <DragDropContext onDragEnd={handleDragEnd}> */}
      <BeautifulDND onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided: DroppableProvided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="chatUsers"
            >
              {sortedItems?.map((space: any, index: number) => (
                <Draggable
                  key={space.id}
                  draggableId={space.id.toString()}
                  index={index}
                >
                  {(provided: DraggableProvided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className={"userCard"}>
                        <div className="icon">
                          <img src={space.icon.url} alt="" height={30} />
                        </div>
                        <div className="info">
                          <div className="name">{space.name}</div>
                          <div className="count">
                            {space.groups_count} Groups
                          </div>
                        </div>
                        <div className="actions">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.001 8V8.00635M12.001 8V8.00635M6.00098 8V8.00635M18.001 15.9937V16M12.001 15.9937V16M6.00098 15.9937V16"
                              stroke="#48484A"
                              stroke-width="2.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </BeautifulDND>
      <div style={{ textAlign: "end" }}>
        <Button
          className="createbtn"
          type="primary"
          onClick={() => onSubmit(sortedItems.map((v: any) => v?.id))}
        >
          Save changes
        </Button>
      </div>
    </div>
  );
}

export default SortingManually;
