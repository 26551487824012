export const isJSON = (data) => {
    try {
      JSON.parse(data);
      return true;
    } catch (error) {
      return false;
    }
  };
  
  let timeoutId;
  export function debounce(callback, delay) {
    return function () {
      const args = arguments;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        callback.apply(null, args);
      }, delay);
    };
  }