import {
  handleAccessToken,
  handleFCMToken,
  handleDeviceId
} from "./AuthReducer";
import { useDispatch, useSelector } from 'react-redux'
import { store } from "./store";

const ApiStorage = {
  getAccessToken: async () => {
    const { accessToken } = await store.getState().AuthReducer;
    return accessToken;
  },
  setAccessToken: async (accessToken) => {
    await store.dispatch(handleAccessToken(accessToken));
  },

  getFCMToken: async () => {
    const { fcmToken } = await store.getState().AuthReducer;
    return fcmToken;
  },
  setFCMToken: async (fcmToken) => {
    await store.dispatch(handleFCMToken(fcmToken));
  },
  getDeviceId: async () => {
    const { deviceId } = await store.getState().AuthReducer;
    return deviceId;
  },
  setDeviceId: async (deviceId) => {
    await store.dispatch(handleDeviceId(deviceId));
  },
}
export default ApiStorage

export const useAccessToken = () => {
  const dispatch = useDispatch()
  const accessToken = useSelector(state => state.AuthReducer.accessToken)
  const setAccessToken = value => {
    dispatch(handleAccessToken(value))
  }
  return [accessToken, setAccessToken]
}


export const useFcmToken = () => {
  const dispatch = useDispatch()
  const fcmToken = useSelector(state => state.AuthReducer.fcmToken)
  const setFCMToken = value => {
    dispatch(handleFCMToken(value))
  }
  return [fcmToken, setFCMToken]
}
export const useDeviceId = () => {
  const dispatch = useDispatch()
  const deviceId = useSelector(state => state.AuthReducer.deviceId)
  const setDeviceId = value => {
    dispatch(handleDeviceId(value))
  }
  return [deviceId, setDeviceId]
}